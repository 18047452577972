import React, { FunctionComponent } from 'react';
import { Box, Paper } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Link, useRouteMatch } from 'react-router-dom';
import NavButton from 'app/common/NavButton';
import { KPIGroup } from 'app/services/api';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    navButtonLink: {
      textDecoration: 'none',
    },
    navButtonLabel: {
      fontWeight: 400,
      fontSize: theme.typography.pxToRem(16),
    },
  })
);

interface NavKPIGroupsProps {
  kpiGroups: KPIGroup[] | null;
}

const NavKPIGroups: FunctionComponent<NavKPIGroupsProps> = ({
  kpiGroups,
}: NavKPIGroupsProps) => {
  const classes = useStyles();
  const { url } = useRouteMatch();

  if (!kpiGroups) {
    return null;
  }

  return (
    <Box component={Paper} height="60px" px={2}>
      {kpiGroups.map((kpiGroup) => (
        <Link
          key={kpiGroup.key}
          to={`${url}/${kpiGroup.path}`}
          className={classes.navButtonLink}
        >
          <NavButton
            classes={{ label: classes.navButtonLabel }}
            active={location.pathname === `${url}/${kpiGroup.path}`}
          >
            {kpiGroup.name}
          </NavButton>
        </Link>
      ))}
    </Box>
  );
};

export default NavKPIGroups;
