import { KPI, KPIValue, KPIGroup, ThreatSurface, ThreatLevel } from 'app/services/api';
import { GroupKeys } from 'app/kpi/types/KPIGroupDisplay';

import { arrayHasValues } from './helpers';

export const getBudgetAverage = (totalBudget: KPI[] | null): number => {
  if (!totalBudget) {
    return 0;
  }

  const getKpiValue = (kpi: KPI) => {
    if (!kpi.kpiValue || !kpi.kpiValue.length) {
      return 0;
    }

    let result = kpi.kpiValue[0].value;
    result = Number(kpi.kpiValue[0].value);

    if (Number.isNaN(result)) {
      return 0;
    }

    return result;
  };

  const totalBudgetMin = getKpiValue(totalBudget[0]);
  const totalBudgetMax = getKpiValue(totalBudget[1]);

  return (totalBudgetMin + totalBudgetMax) / 2;
};

export const getLevelEstimatedPeopleQuantity = (
  level: ThreatLevel,
  budgetEstimator: KPI[],
): number => {
  const budgetEstimatorPeopleData = budgetEstimator.filter(ele => ele.key === GroupKeys.BudgetEstimatorPeople);
  const levelEstimatedPeople = budgetEstimatorPeopleData.map(budgetKpi => {
    let budget;

    if (budgetKpi.kpiValue.length > 0) {
      budget = budgetKpi.kpiValue.filter(budgetKpiValue => budgetKpiValue.levelId === level.id);
    }
   
    if (!budget || budget.length === 0) {
      return 0;
    }

    return Number(budget[0].value);
  });

  return levelEstimatedPeople[0]; // why do we return only the first threat level value?
};

export const getLevelEstimatedBudgetSum = (
  level: ThreatLevel,
  budgetEstimator: KPI[],
): number => {
  const levelEstimatedBudgetValues = budgetEstimator.map(budgetEstKpi => {
    let budget;

    if (budgetEstKpi.kpiValue.length > 0) {
      budget = budgetEstKpi.kpiValue.filter(budgetEstValue => budgetEstValue.levelId === level.id);
    }

    if (!budget || budget.length === 0) {
      return 0;
    }

    return Number(budget[0].value);
  });

  const sum = levelEstimatedBudgetValues.reduce((prev: number, next: number) => prev + next, 0);

  return sum;
};

export const getSurfaceTargetBudget = (
  surface: ThreatSurface,
  targetBudget: KPI,
): number => {
  const targetSurfaceBudget = targetBudget.kpiValue.find(targetBudgetValue => targetBudgetValue.surfaceId === surface.id);
  let budget = 0;

  if (targetSurfaceBudget) {
    budget = Number(targetSurfaceBudget.value);
  }

  return budget;
};

export const getSurfaceBudget = (
  totalBudget: KPI[] | null,
  surfaceBudgetAllocation: KPIValue | undefined
): number => {
  const budgetAverage = getBudgetAverage(totalBudget);
  let budgetAllocation = surfaceBudgetAllocation
    ? surfaceBudgetAllocation.value
    : 0;
  if (!budgetAllocation) {
    budgetAllocation = 0;
  }
  budgetAllocation = Number(budgetAllocation);
  if (Number.isNaN(budgetAllocation)) {
    budgetAllocation = 0;
  }

  return budgetAverage * (budgetAllocation / 100);
};

export const getKpiSubGroupProductivity = (kpis: KPI[] | null): number => {
  if (!kpis || !kpis.length) {
    return 0;
  }

  return Math.round(
    kpis.reduce(
      (result, nextKpi) =>
        result +
        (nextKpi.weight / 100) *
          (arrayHasValues(nextKpi.kpiValue)
            ? Number(nextKpi.kpiValue[0].value) * 20
            : 0),
      0
    )
  );
};

export const getKpiGroupProductivity = (
  subGroupValues: KPI[][] | null,
  kpiGroup: KPIGroup | undefined
): number => {
  if (!subGroupValues || !subGroupValues.length || !kpiGroup) {
    return 0;
  }

  return Math.round(
    subGroupValues.reduce((result, nextSubGroupKpis, index) => {
      const subGroupWt =
        Array.isArray(kpiGroup.subGroups) && kpiGroup.subGroups.length
          ? kpiGroup.subGroups[index].weight
          : 0;

      const subGroupSum = getKpiSubGroupProductivity(nextSubGroupKpis);

      return result + (subGroupSum * subGroupWt) / 100;
    }, 0)
  );
};

export const getSecOpsProductivity = (
  management: KPI[][] | null,
  resources: KPI[][] | null,
  integration: KPI[][] | null,
  kpiGroups: KPIGroup[]
): number => {
  const managementKpiGroup = kpiGroups.find((group) =>
    group.key.includes('management')
  );
  const resourcesKpiGroup = kpiGroups.find((group) =>
    group.key.includes('resources')
  );
  const integrationKpiGroup = kpiGroups.find((group) =>
    group.key.includes('integration')
  );

  if (
    !management ||
    !resources ||
    !integration ||
    !managementKpiGroup ||
    !resourcesKpiGroup ||
    !integrationKpiGroup
  ) {
    return 0;
  }

  const managementProductivity = getKpiGroupProductivity(
    management,
    managementKpiGroup
  );
  const resourcesProductivity = getKpiGroupProductivity(
    resources,
    resourcesKpiGroup
  );
  const integrationProductivity = getKpiGroupProductivity(
    integration,
    integrationKpiGroup
  );

  return (
    (managementProductivity / 100) * managementKpiGroup.weight +
    (resourcesProductivity / 100) * resourcesKpiGroup.weight +
    (integrationProductivity / 100) * integrationKpiGroup.weight
  );
};

export const getSurfaceHighProductivityBudget = (
  totalBudget: KPI[] | null,
  surfaceBudgetAllocation: KPIValue | undefined,
  management: KPI[][] | null,
  resources: KPI[][] | null,
  integration: KPI[][] | null,
  kpiGroups: KPIGroup[]
): number => {
  return (
    (getSurfaceBudget(totalBudget, surfaceBudgetAllocation) *
      getSecOpsProductivity(management, resources, integration, kpiGroups)) /
    100
  );
};

export const getSurfaceLowProductivityBudget = (
  totalBudget: KPI[] | null,
  surfaceBudgetAllocation: KPIValue | undefined,
  management: KPI[][] | null,
  resources: KPI[][] | null,
  integration: KPI[][] | null,
  kpiGroups: KPIGroup[]
): number =>
  getSurfaceBudget(totalBudget, surfaceBudgetAllocation) -
  getSurfaceHighProductivityBudget(
    totalBudget,
    surfaceBudgetAllocation,
    management,
    resources,
    integration,
    kpiGroups
  );

const getSurfaceLevelBudget = (
  surface: ThreatSurface,
  level: ThreatLevel,
  totalBudget: KPI[],
  surfaceBudgetAllocation: KPIValue,
  budgetSplit: KPI,
): number => {
  const currentSurfaceBudget = getSurfaceBudget(totalBudget, surfaceBudgetAllocation);
  const surfaceLevelBudgetSplit = budgetSplit.kpiValue.find(
    (kpiValue: KPIValue) => kpiValue.surfaceId === surface.id && kpiValue.levelId === level.id
  );
  const surfaceLevelBudgetSplitValue: number = surfaceLevelBudgetSplit && surfaceLevelBudgetSplit.value
    ? Number(surfaceLevelBudgetSplit.value)
    : 0;

  return currentSurfaceBudget * surfaceLevelBudgetSplitValue / 100;
};

export const getLevelHighProductivityBudget = (
  surfaces: ThreatSurface[],
  level: ThreatLevel,
  totalBudget: KPI[],
  currentBudget: KPI,
  budgetSplit: KPI,
  secOpsProd: number,
): number => surfaces.reduce(
  (result, surface) => result + (getSurfaceLevelBudget(
    surface,
    level,
    totalBudget,
    currentBudget.kpiValue.find((kpiValue: KPIValue) => kpiValue.surfaceId === surface.id) || {} as KPIValue,
    budgetSplit,
  ) * (secOpsProd / 100)),
  0,
);

export const getLevelLowProductivityBudget = (
  surfaces: ThreatSurface[],
  level: ThreatLevel,
  totalBudget: KPI[],
  currentBudget: KPI,
  budgetSplit: KPI,
  secOpsProd: number,
): number => surfaces.reduce(
  (result, surface) => result + (getSurfaceLevelBudget(
    surface,
    level,
    totalBudget,
    currentBudget.kpiValue.find((kpiValue: KPIValue) => kpiValue.surfaceId === surface.id) || {} as KPIValue,
    budgetSplit,
  ) * ((100 - secOpsProd) / 100)),
  0,
);
