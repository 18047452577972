import {
  KPIGroup as KpiGroupType,
  getKPIsAndValues,
  KPI,
} from 'app/services/api';
import Loader from 'app/common/Loader';
import BarGraph, {
  green,
  orange,
  dollarFormatting,
  getHighLowColours,
  findMaxProductivityValue,
} from 'app/common/BarGraph';
import { getBudgetAverage } from 'app/utils/graph-math';
import Section from 'app/common/Section';
import StripedTable from 'app/common/StripedTable';

const featureName =
  'dashboard/control-dashboards/investment/budget-productivity-by-resource';

type BudgetProductivityByResourceType = {
  secOpsProductivity: number;
  budgetAllocGroup: KpiGroupType | undefined;
  controlId: number;
  totalBudget: KPI[];
};

const BudgetProductivityByResource = ({
  secOpsProductivity,
  budgetAllocGroup,
  controlId,
  totalBudget,
}: BudgetProductivityByResourceType) => {
  const [resourceKpiData, setResourceKpiData] = React.useState<KPI[] | null>(
    null
  );
  const [loading, setLoading] = React.useState<boolean>(true);

  React.useEffect(() => {
    if (!budgetAllocGroup) {
      return;
    }

    (async () => {
      const data = await getKPIsAndValues({
        groupId: budgetAllocGroup.id,
        controlId,
      });

      setResourceKpiData(data);
      setLoading(false);
    })();
  }, []);

  if (loading) {
    return <Loader />;
  }

  if (!budgetAllocGroup || !resourceKpiData) {
    return <div>No Resource Group</div>;
  }

  const budgetAverage = getBudgetAverage(totalBudget);
  const barData = resourceKpiData.map((resource) => {
    let resourceValue =
      Array.isArray(resource.kpiValue) && resource.kpiValue.length
        ? resource.kpiValue[0].value
        : 0;
    resourceValue = Number(resourceValue);
    if (Number.isNaN(resourceValue)) {
      resourceValue = 0;
    }
    const resourceBudget = budgetAverage * (resourceValue / 100);

    const highProductivity = Math.round(
      resourceBudget * (secOpsProductivity / 100)
    );
    const lowProductivity = Math.round(resourceBudget - highProductivity);

    return {
      id: resource.id,
      label: resource.name,
      highProductivity,
      highProductivityColour: green,
      lowProductivity,
      lowProductivityColour: orange,
    };
  });
  const dataKeys = ['highProductivity', 'lowProductivity'];

  return (
    <Section>
      <StripedTable>
        <StripedTable.Head>
          <StripedTable.Heading colSpan={2}>
            Budget Productivity by Resource Type
          </StripedTable.Heading>
          <StripedTable.SubHeading colSpan={2}>
            Resource Type
          </StripedTable.SubHeading>
        </StripedTable.Head>

        <StripedTable.Body>
          {barData.map((rowData, index) => {
            const isLast = index === barData.length - 1;

            return (
              <StripedTable.Row key={`${featureName}/${rowData.id}`}>
                <StripedTable.GraphLabel
                  style={{ width: '20%' }}
                  isLast={isLast}
                >
                  {rowData.label}
                </StripedTable.GraphLabel>
                <StripedTable.GraphCell isLast={isLast}>
                  <BarGraph
                    data={[rowData]}
                    keys={dataKeys}
                    colors={getHighLowColours}
                    axisBottom={isLast ? { format: dollarFormatting } : null}
                    maxValue={findMaxProductivityValue(barData)}
                    labelFormat={dollarFormatting}
                  />
                </StripedTable.GraphCell>
              </StripedTable.Row>
            );
          })}
        </StripedTable.Body>
      </StripedTable>
    </Section>
  );
};

export default BudgetProductivityByResource;
