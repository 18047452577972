import { 
  KPIGroup as KpiGroupType,
  KPI,
} from 'app/services/api';
import Loader from 'common/Loader';
import Paper from '@material-ui/core/Paper';
import {
  tableContainerWithMarginStyles,
  headerStyles,
  getColWidth,
  firstColStyles,
  lastColStyles
} from 'app/kpi/components/tables';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { getSurfaceBudget } from 'utils/graph-math';
import { green, orange } from 'common/BarGraph';
import MuiLightBlue from '@material-ui/core/colors/lightBlue';
import { getCurrentUser } from 'app/services/auth';

import { surfaceTypes } from './index';

type BudgetProductivityBySurfaceMatrixType = surfaceTypes & {
  kpiGroups: KpiGroupType[];
  secOpsProductivity: number;
  controlId: number;
  loading: boolean;
  totalBudget: KPI[] | null;
  currentBudget: KPI | null;
};

const CurrentBudgetProductivityMatrix = ({
  kpiGroups,
  threatSurfaces,
  controlId,
  currentBudget,
  loading,
  secOpsProductivity,
  totalBudget,
}: BudgetProductivityBySurfaceMatrixType) => {

  if (loading) {
    return (
      <div style={{ minHeight: '500px' }}>
        <Loader />
      </div>
    );
  }

  const locale = getCurrentUser()?.currency.locale;
  const currency = getCurrentUser()?.currency;

  const lowProductivityData = Array.isArray(threatSurfaces.payload) && threatSurfaces.payload.map((tSurface) => {
    const surfaceBudget = getSurfaceBudget(
      totalBudget,
      currentBudget?.kpiValue.find(
        (kpiValue: any) => kpiValue.surfaceId === tSurface.id
      )
    );

    const highProductivity = Math.round(
      surfaceBudget * (secOpsProductivity / 100)
    );
    const lowProductivity = Math.round(surfaceBudget - highProductivity);

    return lowProductivity;
  });

  const calculateSum = (arr: number[] | false) => {
    if (Array.isArray(arr)) {
      const sum = arr.reduce((acc, curr) => {
        return acc + curr;
      }, 0);
      return sum.toLocaleString(locale, currency)
    }
  };

  const highProductivityData = Array.isArray(threatSurfaces.payload) && threatSurfaces.payload.map((tSurface) => {
    const surfaceBudget = getSurfaceBudget(
      totalBudget,
      currentBudget?.kpiValue.find(
        (kpiValue: any) => kpiValue.surfaceId === tSurface.id
      )
    );

    const highProductivity = Math.round(
      surfaceBudget * (secOpsProductivity / 100)
    );

    return highProductivity;
  });
  
  const highProdStyle = {
    backgroundColor: green,
  };

  const lowProdStyle = {
    backgroundColor: orange,
  };

  const rowSumStyle = {
    backgroundColor: MuiLightBlue[900],
    color: 'white'
  }

  const numberOfThreatSurfaces = threatSurfaces.payload.length;
  const colWidth = getColWidth(numberOfThreatSurfaces);

  return (
    <Paper elevation={3} style={{ padding: '1.5rem', marginBottom: '2rem' }}>
      <div style={tableContainerWithMarginStyles}>
        <Table size="small">
          <TableHead>
            <TableRow style={headerStyles}>
              <TableCell colSpan={threatSurfaces.payload.length + 2}>
                <Typography variant="h6">Current Budget Productivity</Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell key='budget-productivity' style={firstColStyles}>Key Performance Indicator (KPI)</TableCell>
              {Array.isArray(threatSurfaces.payload) && threatSurfaces.payload.map((surface) => {
                return (<TableCell style={{ width: colWidth }} align="center" key={surface.name}>{surface.name}</TableCell>);
              })
              }
              <TableCell style={lastColStyles} key='sum' align="center">Row Sum</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell key='high-productivity'>High Productivity</TableCell>
              {Array.isArray(highProductivityData) && highProductivityData.map((ele: number, idx: number) => 
                <TableCell align='center' style={highProdStyle} key={`high-${idx}`}>{ele.toLocaleString(locale, currency)}</TableCell>
              )}
              <TableCell align='center' style={rowSumStyle} key='high-productivity-sum'>{calculateSum(highProductivityData)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell key='low-productivity'>Low Productivity</TableCell>
              {Array.isArray(lowProductivityData) && lowProductivityData.map((ele: number, idx: number) => 
                <TableCell align='center' style={lowProdStyle} key={`low-${idx}`}>{ele.toLocaleString(locale, currency)}</TableCell>
              )}
              <TableCell align='center' style={rowSumStyle} key='low-productivity-sum'>{calculateSum(lowProductivityData)}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    </Paper>
  );
};

export default CurrentBudgetProductivityMatrix;
