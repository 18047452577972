import React, { FunctionComponent, useEffect, useState } from 'react';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { StripedTableRow } from 'app/common/StripedTable';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import {
  Control,
  ControlCapability,
  getControlCapabilities,
  getKPIsAndValues,
  getThreatLevels,
  getThreatSurfaces,
  KPIGroup,
  ThreatLevel,
  ThreatSurface,
} from 'app/services/api';
import Loader from 'app/common/Loader';
import NavThreatSurfaces from 'app/kpi/components/NavThreatSurfaces';
import {
  tableContainerStyles,
  headerStyles,
  subHeaderStyles,
  InputItem,
} from 'app/kpi/components/tables';
import ValidatedInput from 'app/kpi/components/tables/ValidatedInput';
import { getCurrentUser } from 'app/services/auth';
import { InputColumn } from 'app/kpi/components/tables/ValueInput';
import { KPIData } from 'app/kpi/types';
import { KPIGroupDisplay } from 'app/kpi/types/KPIGroupDisplay';
import { connect } from 'react-redux';
import { kpiGroupsState } from 'app/common/actions/kpi-groups';
import { buildValueInputTableConfig } from 'app/kpi/components/tables/index';

interface DisplayData {
  capability: ControlCapability;
  kpiGroup: KPIGroupDisplay;
}

interface KPISecurityCoverageProps {
  kpiGroups: kpiGroupsState;
  selectedControl: Control;
}

interface TableData {
  columns: InputColumn[];
  items: InputItem[];
}

type mapStateType = {
  app: {
    control: Control;
  };
  kpiGroups: kpiGroupsState;
};

const KPISecurityCoverage: FunctionComponent<KPISecurityCoverageProps> = ({
  kpiGroups,
  selectedControl,
}: KPISecurityCoverageProps) => {
  const currentUser = getCurrentUser();
  const kpiGroupPayload = kpiGroups.payload as KPIGroup[];
  const kpiGroup = kpiGroupPayload.find(({ key }) =>
    key.includes('security-coverage')
  );
  const [activeSurface, setActiveSurface] = useState<ThreatSurface | null>(
    null
  );
  const [loading, setLoading] = useState<boolean>(true);
  const [surfaces, setSurfaces] = useState<ThreatSurface[] | null>(null);
  const [levels, setLevels] = useState<ThreatLevel[] | null>(null);
  const [displayData, setDisplayData] = useState<Map<
    number,
    DisplayData
  > | null>(null);
  const [errors, setErrors] = useState<KPIData<any>[]>([]);

  const getData = async (surfaceId: number) => {
    if (!kpiGroup || !selectedControl) {
      return;
    }

    const capabilities = await getControlCapabilities(
      selectedControl.id,
      surfaceId
    );

    const kpiData = await Promise.all(
      capabilities.map((capability) =>
        getKPIsAndValues({
          groupId: kpiGroup.id,
          controlId: selectedControl.id,
          levelId: capability.levelId,
          surfaceId,
        })
      )
    );

    const displayData = capabilities.map((capability, index) => [
      capability.levelId,
      {
        capability,
        kpiGroup: new KPIGroupDisplay(kpiGroup, kpiData[index]),
      },
    ]);

    setDisplayData(
      new Map<number, DisplayData>(
        displayData as Iterable<[number, DisplayData]>
      )
    );
  };

  useEffect(() => {
    if (!kpiGroup || !selectedControl) {
      return;
    }

    (async () => {
      const [surfaces, levels] = await Promise.all([
        getThreatSurfaces(),
        getThreatLevels(),
      ]);
      setSurfaces(surfaces as ThreatSurface[]);
      setLevels(levels as ThreatLevel[]);
      setActiveSurface(surfaces ? surfaces[0] : null);

      await getData(surfaces[0].id);

      setLoading(false);
    })();
  }, []);

  const handleSurfaceSelection = async (
    surface: ThreatSurface
  ): Promise<void> => {
    setActiveSurface(surface);
    setDisplayData(null);
    await getData(surface.id);
  };

  const getDisplayData = (levelId: number): DisplayData | undefined => {
    if (!displayData) {
      return;
    }
    return displayData.get(levelId);
  };

  if (!kpiGroup || !selectedControl || !surfaces) {
    return null;
  }

  if (loading) {
    return <Loader />;
  }

  let tableData;

  if (displayData) {
    tableData = (levels || []).map((level, i) => {
      const levelDisplayData = getDisplayData(level.id);
      if (!levelDisplayData) {
        return null;
      }

      const group = levelDisplayData.kpiGroup;
      const control = selectedControl;
      const capability = levelDisplayData.capability;

      const { columns, items } = buildValueInputTableConfig(
        group,
        control,
        [capability],
        [activeSurface as ThreatSurface],
        null,
        (kpi, i) => ({
          text: kpi.text,
          helpText: '',
        })
      );

      return { level, columns, items };
    });
  }

  return (
    <Box display="flex" mt={2}>
      <Box>
        <Box component={Paper}>
          <NavThreatSurfaces
            activeSurface={activeSurface}
            surfaces={surfaces}
            handleSurfaceSelection={handleSurfaceSelection}
          />
        </Box>
      </Box>
      <Box ml={2} flex={1}>
        {displayData ? (
          <Paper
            elevation={3}
            style={{
              padding: '1.5rem',
              marginTop: '0.5rem',
              marginBottom: '2rem',
            }}
          >
            <div style={tableContainerStyles}>
              <Table>
                <TableHead>
                  <TableRow style={headerStyles}>
                    <TableCell colSpan={5}>
                      <Typography variant="h6">
                        {activeSurface?.name}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow style={subHeaderStyles}>
                    <TableCell style={{ width: '20%' }}>Threat Level</TableCell>
                    <TableCell>Capability</TableCell>
                    {Array.isArray(tableData) &&
                      tableData[0] &&
                      tableData[0].columns.map((col, i) => (
                        <TableCell key={i} align="center">
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                          >
                            <Box
                              mr={1 / 2}
                              whiteSpace={col.wrap ? 'normal' : 'nowrap'}
                            >
                              {col.text}
                            </Box>
                            {col.helpText && (
                              <HelpOutlineIcon fontSize="small" />
                            )}
                          </Box>
                        </TableCell>
                      ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Array.isArray(tableData) &&
                    tableData.map((row, i) => (
                      <StripedTableRow
                        key={row?.level?.name}
                        style={{ whiteSpace: 'pre-line' }}
                      >
                        <TableCell>
                          <Typography variant="body2">
                            {row?.level?.severity}. {row?.level?.name}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body2">
                            {row?.items[0].text}
                          </Typography>
                        </TableCell>
                        {row?.items[0].kpis.map((kpi, idx) => {
                          return (
                            <TableCell align="center" key={idx}>
                              <ValidatedInput
                                currentUser={currentUser}
                                column={row?.columns[idx]}
                                error={errors.includes(kpi)}
                                kpi={kpi}
                              />
                            </TableCell>
                          );
                        })}
                      </StripedTableRow>
                    ))}
                </TableBody>
              </Table>
            </div>
          </Paper>
        ) : (
          <Loader />
        )}
      </Box>
    </Box>
  );
};

const mapState = ({ app: { control }, kpiGroups }: mapStateType) => ({
  selectedControl: control,
  kpiGroups,
});

export default connect(mapState)(KPISecurityCoverage);
