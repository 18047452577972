/*
  This is the common component for Hint 1 KPI Groups,
  aka. Management, Resources, and Integration Dashboards
*/

import React from 'react';
import { connect } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import {
  KPIGroup as KpiGroupType,
  KPI as KpiType,
  getKPIsAndValues,
  Control,
} from 'app/services/api';
import Loader from 'app/common/Loader';

import KPIGroupSummary from './KPIGroupSummary';
import KPISubGroupSummary from './KPISubGroupSummary';
import KPISummary from './KPISummary';

const Hint1KpiGroup = ({
  kpiGroups,
  title,
  subGroupTitle,
  selectedControl,
}: {
  kpiGroups: KpiGroupType[];
  title: string;
  subGroupTitle: string;
  selectedControl: Control;
}): JSX.Element => {
  const { url } = useRouteMatch();
  const controlId = selectedControl?.id;
  const [kpiValues, setKPIValues] = React.useState<KpiType[][] | null>(null);
  const groupName = url.split('/').pop();
  const kpiGroup = kpiGroups.find(
    (grp: KpiGroupType) => grp.path === groupName
  );
  const subGroups: KpiGroupType[] =
    kpiGroup && kpiGroup.subGroups ? kpiGroup.subGroups : [];

  React.useEffect(() => {
    if (!kpiGroup) {
      return;
    }

    (async () => {
      const data = await Promise.all(
        subGroups.map((group) =>
          getKPIsAndValues({ groupId: group.id, controlId })
        )
      );

      setKPIValues(data);
    })();
  }, []);

  if (!kpiValues) {
    return <Loader />;
  }

  if (
    !kpiGroup ||
    !subGroups ||
    (Array.isArray(kpiValues) && !kpiValues.length)
  ) {
    return <div>No KPIs found</div>;
  }

  return (
    <div>
      <KPIGroupSummary
        title={title}
        kpiGroup={kpiGroup}
        kpiValues={kpiValues}
      />

      <KPISubGroupSummary
        title={subGroupTitle}
        subGroups={subGroups}
        kpiValues={kpiValues}
      />

      <KPISummary subGroups={subGroups} kpiValues={kpiValues} />
    </div>
  );
};

const mapState = ({
  kpiGroups: { payload },
  app: { control },
}: {
  kpiGroups: { payload: KpiGroupType[] };
  app: { control: Control };
}) => ({ kpiGroups: payload, selectedControl: control });

export default connect(mapState)(Hint1KpiGroup);
