import React, { FunctionComponent } from 'react';
import {
  Paper,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { StripedTableRow } from 'app/common/StripedTable';
import {
  kpiHeaderTitle,
  tableContainerStyles,
  headerStyles,
  subHeaderStyles,
} from 'app/kpi/components/tables';
import { KPILikert, LikertValue } from 'app/kpi/types/KPIData';

interface Likert {
  label: string;
  value: LikertValue;
}

const likerts: Likert[] = [
  {
    label: 'Agree Strongly',
    value: 5,
  },
  {
    label: 'Agree Moderately',
    value: 4,
  },
  {
    label: 'Agree Slightly',
    value: 3,
  },
  {
    label: 'Disagree Slightly',
    value: 2,
  },
  {
    label: 'Disagree Moderately',
    value: 1,
  },
  {
    label: 'Disagree Strongly',
    value: 0,
  },
];

interface KPILikertTableProps {
  className?: string;
  name: string;
  kpis: KPILikert[];
  onKPIChange: (kpi: KPILikert, value: LikertValue) => void;
}

export const KPILikertTable: FunctionComponent<KPILikertTableProps> = ({
  className,
  name,
  kpis,
  onKPIChange,
}: KPILikertTableProps) => (
  <Paper
    elevation={3}
    style={{ padding: '1.5rem', marginTop: '2rem', marginBottom: '2rem' }}
  >
    <div style={tableContainerStyles}>
      <Table className={className}>
        <TableHead>
          <TableRow style={headerStyles}>
            <TableCell colSpan={likerts.length + 1}>
              <Typography variant="h6">{name}</Typography>
            </TableCell>
          </TableRow>
          <TableRow style={subHeaderStyles}>
            <TableCell>{kpiHeaderTitle}</TableCell>
            {likerts.map(({ label }, index) => (
              <TableCell key={index} align="center">
                {label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {kpis.map((kpi) => (
            <StripedTableRow key={kpi.text}>
              <TableCell>
                <Typography variant="body2">{kpi.text}</Typography>
              </TableCell>
              {likerts.map(({ value }, index) => (
                <TableCell key={index} align="center">
                  <Radio
                    color="primary"
                    checked={kpi.value === value}
                    value={value}
                    onChange={() => onKPIChange(kpi, value)}
                  />
                </TableCell>
              ))}
            </StripedTableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  </Paper>
);

export default KPILikertTable;
