import { KPIGroup as KpiGroupType, KPI } from 'app/services/api';
import Loader from 'app/common/Loader';
import { kpiHeaderTitle } from 'app/kpi/components/tables';
import BarGraph, { getBarColorsForValue } from 'app/common/BarGraph';
import Section from 'app/common/Section';
import StripedTable from 'app/common/StripedTable';

import SecOpsKpiGroupProductivity from './SecOpsKpiGroupProductivity';

type SecOpsProductivityType = {
  controlId: number;
  kpiGroups: KpiGroupType[];
  loading: boolean;
  secOpsProductivity: number;
  management: KPI[][] | null;
  resources: KPI[][] | null;
  integration: KPI[][] | null;
};

const SecOpsProductivity = ({
  controlId,
  loading,
  secOpsProductivity,
  kpiGroups,
  management,
  resources,
  integration,
}: SecOpsProductivityType) => {
  if (loading) {
    return (
      <div style={{ minHeight: '500px' }}>
        <Loader />
      </div>
    );
  }

  const barData = [
    {
      id: controlId,
      label: 'SecOps Productivity',
      value: Math.round(secOpsProductivity),
    },
  ];

  let barColors = getBarColorsForValue(secOpsProductivity);

  return (
    <>
      <Section>
        <StripedTable>
          <StripedTable.Head>
            <StripedTable.Heading colSpan={2}>
              {barData[0].label}
            </StripedTable.Heading>
            <StripedTable.SubHeading colSpan={2} style={{ width: '15%' }}>
              {kpiHeaderTitle}
            </StripedTable.SubHeading>
          </StripedTable.Head>

          <StripedTable.Body>
            <StripedTable.Row>
              <StripedTable.GraphLabel isLast style={{ width: '15%' }}>
                {barData[0].label}
              </StripedTable.GraphLabel>
              <StripedTable.GraphCell isLast>
                <BarGraph 
                  data={barData}
                  colors={barColors.background} 
                  labelTextColor={barColors.textColor}
                />
              </StripedTable.GraphCell>
            </StripedTable.Row>
          </StripedTable.Body>
        </StripedTable>
      </Section>

      <SecOpsKpiGroupProductivity
        kpiGroups={kpiGroups}
        management={management}
        resources={resources}
        integration={integration}
      />
    </>
  );
};

export default SecOpsProductivity;
