import { connect } from 'react-redux';
import {
  KPIGroup as KpiGroupType,
  KPI,
  Control,
  TagType,
} from 'app/services/api';
import {
  fetchThreatSurfaces,
  threatSurfaceState,
} from 'app/common/actions/threat-surfaces';
import {
  fetchThreatLevels,
  threatLevelState,
} from 'app/common/actions/threat-levels';
import { fetchControls, controlsState } from 'app/common/actions/controls';
import {
  findSubGroupByKey,
  getKPIsAndValuesForGroup,
  getKPIsAndValuesForSubgroups,
} from 'app/utils/helpers';
import { getSecOpsProductivity } from 'app/utils/graph-math';
import Loader from 'app/common/Loader';
import { GroupKeys } from 'app/kpi/types/KPIGroupDisplay';

import SecOpsProductivity from './SecOpsProductivity';
import SecOpsKpiGroupProductivity from './SecOpsKpiGroupProductivity';
import ControlProductivity from './ControlProductivity';
import ThreatPreparednessMatrix from './ThreatPreparednessMatrix';
import CurrentInvestmentMatrix from './CurrentInvestmentMatrix';
import EstimatedThreatPreparednessMatrix from './EstimatedThreatPreparednessMatrix';
import SecOpsProductivityMatrix from './SecOpsProductivityMatrix';
import PeopleSkillMatrix from './PeopleSkillMatrix';
import CurrentBudgetProductivityMatrix from './CurrentBudgetProductivityMatrix';

type mapStateType = {
  kpiGroups: { payload: KpiGroupType[] };
  threatSurfaces: threatSurfaceState;
  threatLevels: threatLevelState;
  app: {
    tag: TagType;
  };
  controls: controlsState;
};

export type ControlKpiGroupDataType = {
  control: Control;
  management: KPI[][];
  resources: KPI[][];
  integration: KPI[][];
  secOpsProductivity: number;
};

type SummaryDashboardType = {
  kpiGroups: KpiGroupType[];
  getThreatLevels: () => void;
  getThreatSurfaces: () => void;
  selectedTag: TagType;
  threatLevels: threatLevelState;
  threatSurfaces: threatSurfaceState;
  getControls: (forceFetch: boolean, tagKey?: string) => void;
  controls: controlsState;
};

type BudgetDataType = {
  totalBudgets: KPI[][];
  currentBudgets: KPI[];
  targetBudgets: KPI[];
  budgetSplits: KPI[][];
  budgetEstimators: KPI[][];
};

const SummaryDashboard = ({
  kpiGroups,
  getThreatSurfaces,
  getThreatLevels,
  threatSurfaces,
  threatLevels,
  selectedTag,
  getControls,
  controls,
}: SummaryDashboardType): JSX.Element => {
  const controlsPayload = controls.payload as Control[];
  const [loading, setLoading] = React.useState<boolean>(true);
  const [controlKpiGroupData, setControlKpiGroupData] = React.useState<
    ControlKpiGroupDataType[] | null
  >(null);
  const [budgetData, setBudgetData] = React.useState<BudgetDataType | null>(
    null
  );
  const [securityCoverageData, setSecurityCoverageData] = React.useState<
    KPI[][] | null
  >(null);

  const kpiGroup = kpiGroups.find(
    (group: KpiGroupType) => group.path === 'investment'
  );
  const totalBudgetGroup = findSubGroupByKey(
    kpiGroup as KpiGroupType,
    'total-budget'
  );
  const budgetAllocationToSurfaceGroup = findSubGroupByKey(
    kpiGroup as KpiGroupType,
    'budget-allocation-to-surfaces'
  );
  const securityKpiGroup = kpiGroups.find(
    (grp: KpiGroupType) => grp.path === 'security-coverage'
  );
  if (
    !kpiGroup ||
    !totalBudgetGroup ||
    !budgetAllocationToSurfaceGroup ||
    !securityKpiGroup
  ) {
    return <div>Invalid KPI Group</div>;
  }

  React.useEffect(() => {
    if (loading && !threatSurfaces.isLoading && !threatSurfaces.payload) {
      getThreatSurfaces();
    }

    if (loading && !threatLevels.isLoading && !threatLevels.payload) {
      getThreatLevels();
    }

    if (
      !controls.isLoading &&
      Array.isArray(controlsPayload) &&
      controlsPayload.length &&
      !controlKpiGroupData
    ) {
      setTimeout(() => {
        (async () => {
          const managementData = await getKPIsAndValuesForSubgroups(
            controlsPayload,
            kpiGroups,
            'management'
          );
          const resourcesData = await getKPIsAndValuesForSubgroups(
            controlsPayload,
            kpiGroups,
            'resources'
          );
          const integrationData = await getKPIsAndValuesForSubgroups(
            controlsPayload,
            kpiGroups,
            'integration'
          );

          const serializedData: ControlKpiGroupDataType[] = controlsPayload.map(
            (control, index) => {
              const management = managementData[index];
              const resources = resourcesData[index];
              const integration = integrationData[index];

              const secOpsProductivity = getSecOpsProductivity(
                management,
                resources,
                integration,
                kpiGroups
              );

              return {
                control,
                management,
                resources,
                integration,
                secOpsProductivity,
              };
            }
          );
          setControlKpiGroupData(serializedData);

          // Get security coverage
          const securityCoverage: KPI[][] = await getKPIsAndValuesForGroup(
            controlsPayload,
            securityKpiGroup.key
          );
          setSecurityCoverageData(securityCoverage);

          // Get budget data
          const totalBudgetData = await getKPIsAndValuesForGroup(
            controlsPayload,
            totalBudgetGroup.key
          );

          const budgetAllocationData = await getKPIsAndValuesForGroup(
            controlsPayload,
            budgetAllocationToSurfaceGroup.key
          );

          const budgetSplitData = securityCoverage.map((controlBudget) =>
            controlBudget.filter((budgetKpi) =>
              budgetKpi.key.includes('budget-split')
            )
          );

          const budgetEstimatorData = await getKPIsAndValuesForGroup(
            controlsPayload,
            GroupKeys.BudgetEstimator
          );

          const { currentBudgets, targetBudgets } = budgetAllocationData.reduce(
            (previousValue: any, currentValue: KPI[]) => ({
              ...previousValue,
              currentBudgets: previousValue.currentBudgets.concat(
                currentValue.find((kpi: KPI) =>
                  kpi.key.includes('budget-allocation-to-surfaces.current')
                )
              ),
              targetBudgets: previousValue.targetBudgets.concat(
                currentValue.find((kpi: KPI) =>
                  kpi.key.includes('budget-allocation-to-surfaces.target')
                )
              ),
            }),
            { currentBudgets: [], targetBudgets: [] }
          );

          const budgetData = {
            totalBudgets: totalBudgetData,
            currentBudgets,
            targetBudgets,
            budgetSplits: budgetSplitData,
            budgetEstimators: budgetEstimatorData,
          };
          setBudgetData(budgetData);

          setLoading(false);
        })();
      }, 0);
    }
  }, []);

  if (
    threatSurfaces.isLoading ||
    threatLevels.isLoading ||
    loading ||
    controls.isLoading
  ) {
    return <Loader />;
  }

  if (
    !controlKpiGroupData ||
    !controlsPayload ||
    !budgetData ||
    !securityCoverageData
  ) {
    return <div />;
  }

  return (
    <div>
      <ThreatPreparednessMatrix
        kpiGroups={kpiGroups}
        controlKpiGroupData={controlKpiGroupData}
        securityCoverageData={securityCoverageData}
        selectedTag={selectedTag}
        threatLevels={threatLevels}
        threatSurfaces={threatSurfaces}
      />
      <SecOpsProductivityMatrix
        controlKpiGroupData={controlKpiGroupData}
        threatSurfaces={threatSurfaces}
        kpiGroups={kpiGroups}
        selectedTag={selectedTag}
      />
      <CurrentBudgetProductivityMatrix
        selectedTag={selectedTag}
        threatSurfaces={threatSurfaces}
        totalBudgets={budgetData.totalBudgets}
        currentBudgets={budgetData.currentBudgets}
        controlKpiGroupData={controlKpiGroupData}
        kpiGroups={kpiGroups}
      />
      <CurrentInvestmentMatrix
        selectedTag={selectedTag}
        controlKpiGroupData={controlKpiGroupData}
        threatSurfaces={threatSurfaces}
        threatLevels={threatLevels}
        currentBudgets={budgetData.currentBudgets}
        totalBudgets={budgetData.totalBudgets}
        budgetSplits={budgetData.budgetSplits}
      />
      <EstimatedThreatPreparednessMatrix
        kpiGroups={kpiGroups}
        threatSurfaces={threatSurfaces}
        threatLevels={threatLevels}
        selectedTag={selectedTag}
        budgetEstimators={budgetData.budgetEstimators}
        targetBudgets={budgetData.targetBudgets}
        controlKpiGroupData={controlKpiGroupData}
      />

      <PeopleSkillMatrix
        threatSurfaces={threatSurfaces}
        threatLevels={threatLevels}
        selectedTag={selectedTag}
        budgetEstimators={budgetData.budgetEstimators}
        targetBudgets={budgetData.targetBudgets}
        controlKpiGroupData={controlKpiGroupData}
      />
      <SecOpsProductivity
        controlKpiGroupData={controlKpiGroupData}
        selectedTag={selectedTag}
      />
      <SecOpsKpiGroupProductivity
        controlKpiGroupData={controlKpiGroupData}
        kpiGroups={kpiGroups}
        selectedTag={selectedTag}
      />
      <ControlProductivity
        controlKpiGroupData={controlKpiGroupData}
        selectedTag={selectedTag}
      />
    </div>
  );
};

const mapState = ({
  kpiGroups: { payload },
  threatSurfaces,
  threatLevels,
  app: { tag },
  controls,
}: mapStateType) => ({
  kpiGroups: payload,
  threatLevels,
  threatSurfaces,
  selectedTag: tag,
  controls,
});

const mapDispatch = {
  getThreatLevels: fetchThreatLevels,
  getThreatSurfaces: fetchThreatSurfaces,
  getControls: fetchControls,
};

export default connect(mapState, mapDispatch)(SummaryDashboard);
