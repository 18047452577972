import React, {
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
  ComponentProps,
} from 'react';
import store from 'app/services/store';
import api, { Company, setUnauthorizedErrorHandler } from 'app/services/api';
import PATHS from 'app/utils/paths';
import fetchJson from 'fetch.json';
import { CurrencyType, getCurrencyFromLocale } from 'app/utils/currency';

interface Identity {
  user_id: string;
  provider: string;
  connection: string;
  isSocial: boolean;
}

interface UserInfo {
  name: string;
  nickname: string;
  email: string;
  email_verified?: boolean;
  identities?: Identity[];
  picture: string;
  user_id: string;
  blocked: boolean;
  last_ip?: string;
  last_login?: string;
  user_metadata?: any;
  app_metadata?: any;
  created_at?: string;
  updated_at?: string;
}

type IdTokenType = {
  nickname: string;
  name: string;
  picture: string;
  updated_at: string;
  email: string;
  email_verified: boolean;
  iss: string;
  sub: string;
  aud: string;
  iat: number;
  exp: number;
};

export type UserDataType = {
  id: number;
  companyId: number;
  createdTime: string;
  name: string;
  oauthId: string;
  company: Company;
  userInfo: UserInfo;
  currency: CurrencyType;
} | null;

interface CurrentUserChangeHandler {
  (currentUser: UserDataType): void | Dispatch<SetStateAction<UserDataType>>;
}

const LS_AUTH_TOKEN = 'auth-token';
const LS_AUTH_METADATA = 'auth-metadata';

let currentUser: UserDataType = null;
let authExpiryTimerId: number = -1;

const currentUserChangeHandlers: CurrentUserChangeHandler[] = [];

const notifyCurrentUserChange = () =>
  currentUserChangeHandlers.forEach((fn) => fn(currentUser));

export const addCurrentUserChangeListener = (fn: CurrentUserChangeHandler) => {
  currentUserChangeHandlers.push(fn);
  fn(currentUser);
};

export const removeCurrentUserChangeListener = (
  fn: CurrentUserChangeHandler
) => {
  const i = currentUserChangeHandlers.indexOf(fn);
  if (i >= 0) {
    currentUserChangeHandlers.splice(i, 1);
  }
};

const clearAuthExpiryTimer = () => {
  if (authExpiryTimerId > 0) {
    clearTimeout(authExpiryTimerId);
    authExpiryTimerId = -1;
  }
};

const setAuthExpiryTimer = (fn: () => any, timeout: number) => {
  clearAuthExpiryTimer();
  authExpiryTimerId = window.setTimeout(() => {
    fn();
    authExpiryTimerId = -1;
  }, timeout);
};
interface AuthToken {
  access_token: string;
  expires_in: number;
  id_token: string;
  scope: string;
  token_type: string;
  refresh_token: string;
}

interface AuthMetadata {
  tenant: string;
  refresh_token: {
    expiration_type: string;
    rotation_type: string;
    token_lifetime: number;
  };
  client_metadata?: {
    token_expiry: string;
  };
}

const getAuthorizationHeader = (token: AuthToken) => ({
  authorization: `${token.token_type} ${token.access_token}`,
});

const onStorageChange = (): void => {
  const authToken = store.get<AuthToken>(LS_AUTH_TOKEN);
  if (!authToken && !!currentUser) {
    // another tab logged out
    processDeauthentication();
  }
};

export const parseJwt = (token: string) => {
  if (!token) {
    return null;
  }

  const base64Data = token.split('.')[1].replace('-', '+').replace('_', '/');
  return JSON.parse(window.atob(base64Data));
};

const getUserDataFromJwt = (token: string): IdTokenType => {
  return parseJwt(token);
};

const userInfoFromIdToken = (idToken: IdTokenType): UserInfo => {
  return {
    name: idToken.name,
    nickname: idToken.nickname,
    email: idToken.email,
    email_verified: idToken.email_verified,
    picture: idToken.picture,
    user_id: idToken.sub,
    blocked: false,
    updated_at: idToken.updated_at,
  };
};

const userInfoChanged = (userData: any): boolean => {
  const userInfo = userData.userInfo;
  if (userInfo && userData.name !== userInfo.name) {
    return true;
  }

  const companyKey = userData.company?.key;
  const userOrg = userInfo?.app_metadata?.org;
  if (
    companyKey &&
    userOrg &&
    companyKey.toLowerCase() !== userOrg.toLowerCase()
  ) {
    return true;
  }
  return false;
};

const tokenExpired = (token: AuthToken): boolean => {
  return tokenExpiration(token) < 0;
};

const tokenExpiration = (token: AuthToken): number => {
  const tokenExpiry = parseJwt(token.access_token).exp;
  const timeNow = new Date().getTime();
  return tokenExpiry * 1000 - timeNow;
};

const onTokenExpired = (...args: any[]): void => {
  const metadata = store.get<AuthMetadata>(LS_AUTH_METADATA);
  if (metadata && metadata.client_metadata) {
    const expiryMode = metadata.client_metadata.token_expiry;
    if (expiryMode === 'keep_alive') {
      const authToken = store.get<AuthToken>(LS_AUTH_TOKEN);
      if (authToken && tokenExpired(authToken)) {
        loadUserWithAuthToken(authToken);
      }
    } else if (expiryMode === 'end_session') {
      processDeauthentication();
    }
  }
};

const setAuthExpiryHandler = (fn: () => any) => {
  const authToken = store.get<AuthToken>(LS_AUTH_TOKEN);
  const metadata = store.get<AuthMetadata>(LS_AUTH_METADATA);
  if (!authToken || !metadata) {
    return;
  }

  if (metadata.client_metadata) {
    const expiryMode = metadata.client_metadata?.token_expiry;
    const tokenExpiry = authToken.expires_in * 1000;
    let expiryTimer = 0;
    if (expiryMode === 'keep_alive') {
      expiryTimer = tokenExpiry;
    } else if (expiryMode === 'end_session') {
      const elapsedTime = tokenExpiry - tokenExpiration(authToken);
      expiryTimer = metadata.refresh_token.token_lifetime * 1000 - elapsedTime;
    }
    if (expiryTimer) {
      setAuthExpiryTimer(fn, expiryTimer + 5000);
    }
  }
};

const setAuthToken = (token: AuthToken, metadata?: AuthMetadata) => {
  store.set<AuthToken>(LS_AUTH_TOKEN, token);
  if (metadata) {
    store.set<AuthMetadata>(LS_AUTH_METADATA, metadata);
  }
  store.addEventListener(onStorageChange);
  fetchJson.headers(getAuthorizationHeader(token));
  setAuthExpiryHandler(onTokenExpired);
};

const removeAuthToken = () => {
  clearAuthExpiryTimer();
  store.remove(LS_AUTH_TOKEN);
  store.remove(LS_AUTH_METADATA);
  store.removeEventListener(onStorageChange);
};

const loadUserWithAuthToken = async (
  authToken: AuthToken
): Promise<UserDataType> => {
  let dbData;

  const authHeader = getAuthorizationHeader(authToken);
  let status = 200;
  try {
    // force authorization with Auth0
    dbData = await api.getCurrentUser({
      headers: authHeader,
      params: { include: 'company,userInfo' },
    });
  } catch (error) {
    status = error.status;
    if (status !== 404) {
      processDeauthentication();
      throw error;
    }
  }

  if (status === 404) {
    try {
      dbData = await api.provisionCurrentUser({
        headers: authHeader,
      });
    } catch (error) {
      processDeauthentication();
      throw error;
    }
  } else if (userInfoChanged(dbData)) {
    try {
      dbData = await api.updateCurrentUser({
        headers: authHeader,
      });
    } catch (error) {
      processDeauthentication();
      throw error;
    }
  }

  const result: any = {
    ...dbData,
  };

  if (!('userInfo' in result)) {
    const idToken = getUserDataFromJwt(authToken.id_token);
    result.userInfo = userInfoFromIdToken(idToken);
  }

  let company: any;
  if ('company' in result) {
    company = result.company;
  } else {
    try {
      company = await api.getCompany(result.companyId, {
        headers: authHeader,
      });
      result.company = company;
    } catch (error) {
      processDeauthentication();
      throw error;
    }
  }

  result.currency = getCurrencyFromLocale(company.locale, company.currency);

  return result;
};

const loadCurrentUser = async (): Promise<UserDataType> => {
  if (!currentUser) {
    let authToken = store.get<AuthToken>(LS_AUTH_TOKEN);
    if (authToken) {
      currentUser = await loadUserWithAuthToken(authToken);
      if (currentUser) {
        if (authExpiryTimerId < 0) {
          // app reloaded
          setAuthToken(authToken);
        }
        notifyCurrentUserChange();
      }
    }
  }
  return currentUser;
};

const loadNewUser = async (result: any): Promise<UserDataType> => {
  setAuthToken(result.token, result.metadata);
  currentUser = await loadUserWithAuthToken(result.token);
  if (currentUser) {
    notifyCurrentUserChange();
  }
  return currentUser;
};

export const oauthLogin = (): Promise<void> =>
  api.login().then(({ url }: { url: string }) => {
    window.location.href = url;
  });

export const oauthLogout = () =>
  api.logout().then(({ url }: { url: string }) => {
    processDeauthentication(url);
  });

export const oauthChangePassword = () =>
  api.changePassword().then(({ url }: { url: string }) => {
    window.location.href = url;
  });

export const processAuthentication = (
  authorizationCode: string
): Promise<UserDataType> =>
  api
    .getAuthToken(authorizationCode)
    .then((result: any) => loadNewUser(result));

setUnauthorizedErrorHandler((response, config) => {
  const authToken = store.get<AuthToken>(LS_AUTH_TOKEN);
  return (authToken
    ? api.refreshAuthToken(authToken.refresh_token)
    : Promise.reject(response)
  ).then(
    (result: any) => {
      setAuthToken(result.token, result.metadata);
      if (config.options?.headers?.authorization) {
        delete config.options.headers.authorization;
      }
    },
    (error: Error | Response) => {
      processDeauthentication();
      throw error;
    }
  );
});

export const processDeauthentication = async (logoutUrl?: string | null) => {
  removeAuthToken();

  currentUser = null;
  notifyCurrentUserChange();

  window.location.replace(logoutUrl || PATHS.SIGNIN);
};

export const init = loadCurrentUser;
export const isAuthenticated = () => !!currentUser;
export const getCurrentUser = () => currentUser || null;

export const withAuth = (WrappedComponent: React.ComponentType<any>) => (
  props: ComponentProps<any>
) => {
  const [currentUser, setCurrentUser] = useState(getCurrentUser());
  useEffect(() => {
    addCurrentUserChangeListener(setCurrentUser);
    return () => removeCurrentUserChangeListener(setCurrentUser);
  }, []);
  return (
    <WrappedComponent
      {...props}
      currentUser={currentUser}
      loggedIn={!!currentUser}
    />
  );
};
