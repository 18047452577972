import React from 'react';
import { connect } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import {
  KPIGroup as KpiGroupType,
  ThreatLevel as ThreatLevelType,
  ThreatSurface as ThreatSurfaceType,
  ControlCapability as CapabilityType,
  KPIValue as KpiValueType,
  getKPIValues,
  getControlCapabilities,
  Control,
} from 'app/services/api';
import { fetchThreatLevels } from 'app/common/actions/threat-levels';
import { fetchThreatSurfaces } from 'app/common/actions/threat-surfaces';
import Loader from 'app/common/Loader';
import BarGraph, { getBarColorsForValue } from 'app/common/BarGraph';
import Section from 'app/common/Section';
import StripedTable from 'app/common/StripedTable';

type threatLevelsState = {
  isLoading: boolean;
  hasError: Error;
  payload: ThreatLevelType[];
};

type threatSurfacesState = {
  isLoading: boolean;
  hasError: Error;
  payload: ThreatSurfaceType[];
};

const featureName = 'dashboard/security-coverage';

const CustomTooltip = ({
  data: { label, value },
}: {
  data: { label: string; value: number };
}): JSX.Element => (
  <strong>
    Capability: {label}
    <br />
    Value: {value}
  </strong>
);

const SecurityDashboard = ({
  kpiGroups,
  getThreatLevels,
  threatLevels,
  getThreatSurfaces,
  threatSurfaces,
  selectedControl,
}: {
  kpiGroups: KpiGroupType[];
  getThreatLevels: () => void;
  threatLevels: threatLevelsState;
  getThreatSurfaces: () => void;
  threatSurfaces: threatSurfacesState;
  selectedControl: Control;
}): JSX.Element => {
  const { url } = useRouteMatch();
  const controlId = selectedControl?.id;
  const [capabilities, setCapabilities] = React.useState<CapabilityType[]>([]);
  const [kpiValues, setKPIValues] = React.useState<KpiValueType[]>([]);
  const groupName = url.split('/').pop();
  const kpiGroup = kpiGroups.find(
    (grp: KpiGroupType) => grp.path === groupName
  );

  React.useEffect(() => {
    if (!kpiGroup || !controlId) {
      return;
    }

    getThreatLevels();
    getThreatSurfaces();

    (async () => {
      const controlCaps: CapabilityType[] = await getControlCapabilities(
        controlId
      );

      setCapabilities(controlCaps);

      const data: KpiValueType[] = await getKPIValues({
        groupId: kpiGroup.id,
        controlId,
        include: 'kpis,unique',
      });

      setKPIValues(data);
    })();
  }, []);

  if (
    threatLevels.isLoading ||
    threatSurfaces.isLoading ||
    !capabilities ||
    !kpiValues
  ) {
    return <Loader />;
  }

  const error = threatLevels.hasError || threatSurfaces.hasError;
  if (error) {
    return <div>Error while fetching data: {error.message}</div>;
  }

  let barColors: any;

  if (
    threatLevels.payload &&
    threatSurfaces.payload &&
    Array.isArray(capabilities) &&
    capabilities.length
  ) {
    return (
      <div>
        {threatSurfaces.payload.map((surface: ThreatSurfaceType) => (
          <Section padding key={`${featureName}-${surface.name}`}>
            <StripedTable>
              <StripedTable.Head>
                <StripedTable.Heading colSpan={3}>
                  {surface.name}
                </StripedTable.Heading>
                <StripedTable.SubHeading colSpan={3}>
                  Threat Level
                </StripedTable.SubHeading>
              </StripedTable.Head>

              <StripedTable.Body>
                {threatLevels.payload.map(
                  (level: ThreatLevelType, index: number) => {
                    const capability = capabilities.find(
                      (cap: CapabilityType) =>
                        cap.levelId === level.id &&
                        cap.surfaceTypeId === surface.surfaceTypeId
                    );

                    if (!capability) {
                      return <div>No Capabilities</div>;
                    }

                    const scopeKpi = kpiValues.find(
                      (kpiValue: any) =>
                        kpiValue.levelId === capability.levelId &&
                        kpiValue.surfaceId === surface.id &&
                        kpiValue.kpi.key.split('.').pop().includes('scope')
                    );
                    const coverageKpi = kpiValues.find(
                      (kpiValue: any) =>
                        kpiValue.levelId === capability.levelId &&
                        kpiValue.surfaceId === surface.id &&
                        kpiValue.kpi.key.split('.').pop().includes('coverage')
                    );

                    const scopeValue = scopeKpi ? Number(scopeKpi.value) : 0;
                    const coverageValue = coverageKpi
                      ? Number(coverageKpi.value)
                      : 0;

                    const barGraphData = [
                      {
                        id: capability.name,
                        label: capability.name,
                        value: Math.round((scopeValue * coverageValue) / 100),
                      },
                    ];

                    if (
                      Array.isArray(barGraphData) &&
                      barGraphData.length > 0
                    ) {
                      barColors = getBarColorsForValue(barGraphData[0].value);
                    }

                    const isLast = index === threatLevels.payload.length - 1;

                    return (
                      <StripedTable.Row
                        key={`${featureName}-${surface.name}-${level.name}`}
                      >
                        <StripedTable.GraphLabel
                          style={{ width: '15%' }}
                          isLast={isLast}
                        >
                          {level.severity}. {level.name}
                        </StripedTable.GraphLabel>
                        <StripedTable.GraphCell isLast={isLast}>
                          <BarGraph
                            data={barGraphData}
                            colors={barColors.background}
                            labelTextColor={barColors.textColor}
                            axisBottom={isLast ? {} : null}
                            tooltip={CustomTooltip}
                          />
                        </StripedTable.GraphCell>
                      </StripedTable.Row>
                    );
                  }
                )}
              </StripedTable.Body>
            </StripedTable>
          </Section>
        ))}
      </div>
    );
  }

  return <div />;
};

const mapState = ({
  app: { control },
  kpiGroups: { payload },
  threatLevels,
  threatSurfaces,
}: {
  app: { control: Control };
  kpiGroups: { payload: KpiGroupType[] };
  threatLevels: threatLevelsState;
  threatSurfaces: threatSurfacesState;
}) => ({
  kpiGroups: payload,
  threatLevels,
  threatSurfaces,
  selectedControl: control,
});

const mapDispatch = {
  getThreatLevels: fetchThreatLevels,
  getThreatSurfaces: fetchThreatSurfaces,
};

export default connect(mapState, mapDispatch)(SecurityDashboard);
