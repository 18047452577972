import { TagType } from 'app/services/api';
import BarGraph, {
  green,
  orange,
  dollarFormatting,
  findMaxProductivityValue,
  getHighLowColours,
} from 'app/common/BarGraph';
import { getBudgetAverage } from 'app/utils/graph-math';
import { zip as _zip } from 'lodash';
import Section from 'app/common/Section';
import StripedTable from 'app/common/StripedTable';

import { InvestmentDataType } from '.';

const featureName =
  'dashboard/tag-dashboards/investment/budget-productivity-by-resource';

type BudgetProductivityByResourceType = {
  selectedTag: TagType;
  investmentData: InvestmentDataType[];
};

type resourceType = {
  label: string;
  highProductivity: number;
  highProductivityColour: string;
  lowProductivity: number;
  lowProductivityColour: string;
};

const BudgetProductivityByResource = ({
  selectedTag,
  investmentData,
}: BudgetProductivityByResourceType) => {
  const resourceSubGroupProductivityData = investmentData.map((controlData) => {
    const budgetAverage = getBudgetAverage(controlData.totalBudget);
    const secOpsProductivity = controlData.secOpsProductivity;

    return controlData.budgetAllocation.map((resource) => {
      let resourceValue = 0;

      if (Array.isArray(resource.kpiValue) && resource.kpiValue.length) {
        resourceValue = Number(resource.kpiValue[0].value);

        if (Number.isNaN(resourceValue)) {
          resourceValue = 0;
        }
      }

      const resourceBudget = budgetAverage * (resourceValue / 100);

      const highProductivity = Math.round(
        resourceBudget * (secOpsProductivity / 100)
      );
      const lowProductivity = Math.round(resourceBudget - highProductivity);

      return {
        label: resource.name,
        lowProductivity,
        lowProductivityColour: orange,
        highProductivity,
        highProductivityColour: green,
      };
    });
  });

  // Transpose array and calculate the sum of high/low productivty budget
  const barData = _zip(...resourceSubGroupProductivityData).map((resource) => {
    return resource.reduce(
      (acc: resourceType, curr) => {
        const newResult: resourceType = {
          ...acc,
        };

        if (curr) {
          if (newResult.label.length === 0) {
            newResult.label = curr.label;
          }

          newResult.highProductivity =
            newResult.highProductivity + curr.highProductivity;
          newResult.lowProductivity =
            newResult.lowProductivity + curr.lowProductivity;
        }

        return newResult;
      },
      {
        label: '',
        highProductivity: 0,
        highProductivityColour: green,
        lowProductivity: 0,
        lowProductivityColour: orange,
      }
    );
  });
  const dataKeys = ['highProductivity', 'lowProductivity'];

  return (
    <Section>
      <StripedTable>
        <StripedTable.Head>
          <StripedTable.Heading colSpan={2}>
            {selectedTag.name} Budget Productivity by Resource Type
          </StripedTable.Heading>
          <StripedTable.SubHeading colSpan={2}>
            Resource Type
          </StripedTable.SubHeading>
        </StripedTable.Head>

        <StripedTable.Body>
          {barData.map((rowData, index) => {
            const isLast = index === barData.length - 1;

            return (
              <StripedTable.Row key={`${featureName}/${rowData.label}`}>
                <StripedTable.GraphLabel
                  style={{ width: '15%' }}
                  isLast={isLast}
                >
                  {rowData.label}
                </StripedTable.GraphLabel>
                <StripedTable.GraphCell isLast={isLast}>
                  <BarGraph
                    data={[rowData]}
                    keys={dataKeys}
                    colors={getHighLowColours}
                    axisBottom={isLast ? { format: dollarFormatting } : null}
                    maxValue={findMaxProductivityValue(barData)}
                    labelFormat={dollarFormatting}
                  />
                </StripedTable.GraphCell>
              </StripedTable.Row>
            );
          })}
        </StripedTable.Body>
      </StripedTable>
    </Section>
  );
};

export default BudgetProductivityByResource;
