import React, { FunctionComponent, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  buildValueInputTableConfig,
  KPIValueInputTable,
  ValueInputTableConfig,
} from 'app/kpi/components/tables';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { KPIData } from 'app/kpi/types';
import {
  getControlCapabilities,
  getKPIsAndValues,
  getThreatLevels,
  getThreatSurfaces,
  ThreatLevel,
  KPIGroup,
  Control,
} from 'app/services/api';
import Loader from 'app/common/Loader';
import { KPIGroupDisplay, GroupKeys } from 'app/kpi/types/KPIGroupDisplay';
import { KPIDisplay } from 'app/kpi/types/KPIDisplay';
import { kpiGroupsState } from 'app/common/actions/kpi-groups';

const getColumnConfigs: Record<string, any> = {
  [GroupKeys.BudgetEstimator](kpi: KPIDisplay) {
    return {
      text: kpi.text,
    };
  },
  [GroupKeys.TimeAllocation](kpi: KPIDisplay) {
    return {
      text: kpi.text,
    };
  },
  [GroupKeys.BudgetAllocationToSurfaces](kpi: KPIDisplay) {
    return {
      text: kpi.text,
    };
  },
};

type mapStateType = {
  app: {
    control: Control;
  };
  kpiGroups: kpiGroupsState;
};

interface KPIInvestmentProps {
  selectedControl: Control;
  kpiGroups: kpiGroupsState;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableContainer: {
      marginTop: theme.spacing(2),
    },
  })
);

const KPIInvestment: FunctionComponent<KPIInvestmentProps> = ({
  selectedControl,
  kpiGroups,
}: KPIInvestmentProps) => {
  const classes = useStyles();
  const kpiGroupPayload = kpiGroups.payload as KPIGroup[];
  const kpiGroup = kpiGroupPayload.find(({ key }) =>
    key.includes('investment')
  );
  const [levels, setLevels] = useState<ThreatLevel[] | null>(null);
  const [tableConfigs, setTableConfigs] = useState<
    ValueInputTableConfig[] | null
  >(null);
  const [errors, setErrors] = useState<KPIData<any>[]>([]);

  useEffect(() => {
    if (!kpiGroup || !selectedControl) {
      return;
    }
    (async () => {
      try {
        const [surfaces, levels] = await Promise.all([
          getThreatSurfaces(),
          getThreatLevels(),
        ]);
        setLevels(levels);
        const capabilities = await getControlCapabilities(
          selectedControl.id,
          surfaces[0].id
        );

        const tableConfigs = await Promise.all(
          (kpiGroup.subGroups || []).map(async (group) => {
            let kpis;
            let displayGroup;

            if (group.key.includes('time-allocation')) {
              const subGroupKPIs = await Promise.all(
                (group.subGroups || []).map((grp) =>
                  getKPIsAndValues({
                    groupId: grp.id,
                    controlId: selectedControl.id,
                  })
                )
              );

              kpis = subGroupKPIs.reduce((prev, next) => prev.concat(next), []);
              displayGroup = new KPIGroupDisplay(group, kpis);
            } else {
              kpis = await getKPIsAndValues({
                groupId: group.id,
                controlId: selectedControl.id,
              });
              displayGroup = new KPIGroupDisplay(group, kpis);
            }

            return buildValueInputTableConfig(
              displayGroup,
              selectedControl,
              capabilities,
              surfaces,
              levels,
              getColumnConfigs[displayGroup.key],
              null
            );
          })
        );

        setTableConfigs(tableConfigs);
      } catch (error) {}
    })();
  }, []);

  if (!kpiGroup || !selectedControl) {
    return null;
  }

  if (!tableConfigs) {
    return <Loader />;
  }

  return (
    <>
      {tableConfigs.map(({ text, columns, items }) => {
        return (
          <KPIValueInputTable
            key={text}
            text={text}
            columns={columns}
            items={items}
            errors={errors}
          />
        );
      })}
    </>
  );
};

const mapState = ({ app: { control }, kpiGroups }: mapStateType) => ({
  selectedControl: control,
  kpiGroups,
});

export default connect(mapState)(KPIInvestment);
