import { combineReducers } from 'redux';
import { controlReducer, controlsReducer } from 'common/reducers/controls';
import kpiGroupsReducer from 'common/reducers/kpi-groups';
import threatLevelsReducer from 'common/reducers/threat-levels';
import threatSurfacesReducer from 'common/reducers/threat-surfaces';
import { tagsReducer, tagReducer } from 'common/reducers/tags';
import appReducer from 'common/reducers';

export default combineReducers({
  app: appReducer,
  control: controlReducer,
  controls: controlsReducer,
  kpiGroups: kpiGroupsReducer,
  threatLevels: threatLevelsReducer,
  threatSurfaces: threatSurfacesReducer,
  tag: tagReducer,
  tags: tagsReducer,
});
