import {
  KPIGroup as KpiGroupType,
  getKPIsAndValues,
  KPI,
  KPIValue,
  ThreatSurface,
} from 'app/services/api';
import Loader from 'app/common/Loader';
import { getBarColorsForValue } from 'app/common/BarGraph';
import {
  tableContainerWithMarginStyles,
  headerStyles,
  getColWidth,
  firstColStyles,
  lastColStyles,
} from 'app/kpi/components/tables';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { threatLevelTypes, surfaceTypes } from './index';
import MuiLightBlue from '@material-ui/core/colors/lightBlue';

type ThreatPreparednessMatrixType = threatLevelTypes &
  surfaceTypes & {
    kpiGroups: KpiGroupType[];
    controlId: number;
  };

const featureName =
  'dashboard/control-dashboards/summary/threat-preparedness-matrix';

const ThreatPreparednessMatrix = ({
  kpiGroups,
  controlId,
  threatSurfaces,
  threatLevels,
}: ThreatPreparednessMatrixType) => {
  const [loading, setLoading] = React.useState<boolean>(true);
  const [securityGroupValues, setSecurityGroupValues] = React.useState<
    KPI[] | null
  >(null);

  React.useEffect(() => {
    if (!kpiGroups.length || !controlId) {
      return;
    }

    const securityKpiGroup = kpiGroups.find(
      (grp: KpiGroupType) => grp.path === 'security-coverage'
    );

    (async () => {
      if (!securityKpiGroup) {
        return;
      }
      const securityGroupData: KPI[] = await getKPIsAndValues({
        controlId,
        groupKey: securityKpiGroup.key,
      });
      setSecurityGroupValues(securityGroupData);

      setLoading(false);
    })();
  }, []);

  if (loading || !Array.isArray(threatLevels.payload)) {
    return (
      <div style={{ minHeight: '500px' }}>
        <Loader />
      </div>
    );
  }

  const tSurfacePayload = threatSurfaces.payload as ThreatSurface[];
  if (!tSurfacePayload) {
    return <div />;
  }

  const securityCoverage = securityGroupValues?.filter(
    (group) => group.key === 'com.pharossecurity.security-coverage.coverage'
  )[0];
  const securityScope = securityGroupValues?.filter(
    (group) => group.key === 'com.pharossecurity.security-coverage.scope'
  )[0];
  const numberOfThreatSurfaces = tSurfacePayload.length;

  // Starting from the first threat level
  const data =
    Array.isArray(threatLevels.payload) &&
    threatLevels.payload.map((tLevel) => {
      const rowData: any = [];
      const scope =
        securityScope?.kpiValue.filter(
          (kpi: any) =>
            kpi?.levelId === tLevel.id &&
            kpi?.kpi.key === 'com.pharossecurity.security-coverage.scope'
        ) || [];
      const coverage =
        securityCoverage?.kpiValue.filter(
          (kpi: any) =>
            kpi?.levelId === tLevel.id &&
            kpi?.kpi.key === 'com.pharossecurity.security-coverage.coverage'
        ) || [];

      // Add default scope value if the scope hasn't been set
      if (scope.length < numberOfThreatSurfaces) {
        tSurfacePayload.forEach((surface) => {
          const hasSurface = scope.find(
            (ele: any) => ele.surfaceId === surface.id
          );

          if (!hasSurface) {
            const defaultScope: KPIValue = {} as any;
            defaultScope.surfaceId = surface.id;
            defaultScope.value = '0';
            defaultScope.levelId = tLevel.id;
            scope.push(defaultScope);
          }
        });
      }

      scope.sort((a: any, b: any) => a?.surfaceId - b?.surfaceId);

      scope?.forEach((kpi: any) => {
        const surface = tSurfacePayload.find(
          (surface) => surface.id === kpi?.surfaceId
        );
        if (surface) {
          const coverageLevel: any = coverage?.find(
            (coverageKpi: any) => coverageKpi?.surfaceId === kpi?.surfaceId
          );
          const index = tSurfacePayload.indexOf(surface);
          const value =
            (Number(kpi?.value) * Number(coverageLevel?.value || 0)) / 100;
          rowData[index] = Math.round(value);
        }
      });

      const sum = rowData.reduce((acc: number, curr: number) => {
        return acc + curr;
      }, 0);

      const average = Math.round(sum / numberOfThreatSurfaces);

      rowData.push(average);

      // Insert threat level label to the beginning of the array
      rowData.splice(0, 0, `${tLevel.severity}. ${tLevel.name}`);
      return rowData;
    });

  const colWidth = getColWidth(numberOfThreatSurfaces);

  return (
    <Paper
      elevation={3}
      style={{ padding: '1.5rem', marginTop: '2rem', marginBottom: '2rem' }}
    >
      <div style={tableContainerWithMarginStyles}>
        <Table size="small">
          <TableHead>
            <TableRow style={headerStyles}>
              <TableCell colSpan={numberOfThreatSurfaces + 2}>
                <Typography variant="h6">Threat Preparedness</Typography>
              </TableCell>
            </TableRow>
            <TableRow key="header">
              <TableCell key="threat-preparedness" style={firstColStyles}>
                Threat Level
              </TableCell>
              {tSurfacePayload.map((surface, idx) => (
                <TableCell
                  style={{ width: colWidth }}
                  align="center"
                  key={`${featureName}/${surface.name}-${idx}`}
                >
                  {surface.name}
                </TableCell>
              ))}
              <TableCell style={lastColStyles} key="average" align="center">
                Row Average
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.isArray(data) &&
              data.map((row, rowIdx) => (
                <TableRow key={`${featureName}/tbody/row-${rowIdx}`}>
                  {row.map((col: any, cellIndex: number) => {
                    let style = {};
                    if (cellIndex === row.length - 1) {
                      style = {
                        backgroundColor: MuiLightBlue[900],
                        color: 'white',
                      };
                      // Center algin KPI values
                    } else if (cellIndex > 0) {
                      let barColors = getBarColorsForValue(col);
                      style = {
                        backgroundColor: barColors.background,
                        color: barColors.textColor,
                      };
                    } else if (cellIndex === 0) {
                      style = {
                        whiteSpace: 'nowrap',
                      };
                    }

                    return (
                      <TableCell
                        key={`${featureName}/tbody/row-${rowIdx}/col-${cellIndex}`}
                        style={style}
                        component="th"
                        scope="row"
                        align={cellIndex > 0 ? 'center' : 'left'}
                      >
                        {col}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </div>
    </Paper>
  );
};

export default ThreatPreparednessMatrix;
