import React, { FunctionComponent, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Loader from 'app/common/Loader';
import { KPILikertTable } from 'app/kpi/components/tables';
import { KPILikert, LikertValue } from 'app/kpi/types';
import { Control, getKPIsAndValues, KPI, KPIGroup } from 'app/services/api';
import { KPIGroupDisplay } from 'app/kpi/types/KPIGroupDisplay';
import { kpiGroupsState } from 'app/common/actions/kpi-groups';

interface KPIIntegrationProps {
  selectedControl: Control;
  kpiGroups: kpiGroupsState;
}

type mapStateType = {
  app: {
    control: Control;
  };
  kpiGroups: kpiGroupsState;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableContainer: {
      marginTop: theme.spacing(2),
    },
  })
);

const KPIIntegration: FunctionComponent<KPIIntegrationProps> = ({
  selectedControl,
  kpiGroups,
}: KPIIntegrationProps) => {
  const classes = useStyles();
  const kpiGroupPayload = kpiGroups.payload as KPIGroup[];
  const kpiGroup = kpiGroupPayload.find(({ key }) =>
    key.includes('integration')
  );
  const [kpiData, setKPIData] = useState<KPIGroupDisplay[] | null>(null);

  useEffect(() => {
    if (!kpiGroup || !selectedControl) {
      return;
    }
    (async () => {
      const kpiData = await Promise.all(
        (kpiGroup.subGroups || []).map(async (group) => {
          let kpis: KPI[];
          try {
            kpis = await getKPIsAndValues({
              groupId: group.id,
              controlId: selectedControl.id,
            });
          } catch (error) {
            kpis = [];
          }
          return new KPIGroupDisplay(group, kpis);
        })
      );

      setKPIData(kpiData);
    })();
  }, []);

  const onKPIChange = (kpi: KPILikert, value: LikertValue) => {
    kpi.value = value;
    const newData = kpiData ? kpiData.slice() : [];
    setKPIData(newData);
  };

  if (!kpiGroup || !selectedControl) {
    return null;
  }

  if (!kpiData) {
    return <Loader />;
  }

  const defaultValueProps = { controlId: selectedControl.id };

  return (
    <>
      {kpiData.map(({ name, kpis }) => (
        <KPILikertTable
          key={name}
          name={name}
          kpis={
            kpis.map((kpi) =>
              kpi.getValueAt(0, defaultValueProps)
            ) as KPILikert[]
          }
          onKPIChange={onKPIChange}
        />
      ))}
    </>
  );
};

const mapState = ({ app: { control }, kpiGroups }: mapStateType) => ({
  selectedControl: control,
  kpiGroups,
});

export default connect(mapState)(KPIIntegration);
