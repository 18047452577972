import { connect } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import {
  KPIGroup as KpiGroupType,
  KPI,
  Control,
  TagType,
  getKPIsAndValues,
} from 'app/services/api';
import {
  fetchThreatSurfaces,
  threatSurfaceState,
} from 'app/common/actions/threat-surfaces';
import {
  fetchThreatLevels,
  threatLevelState,
} from 'app/common/actions/threat-levels';
import { fetchControls, controlsState } from 'app/common/actions/controls';
import Loader from 'app/common/Loader';
import { getSecOpsProductivity } from 'app/utils/graph-math';
import { kpiGroupPromiseCreator, findSubGroupByKey } from 'app/utils/helpers';

import BudgetProductivity from './BudgetProductivity';
import BudgetProductivityBySurface from './BudgetProductivityByThreatSurface';
import BudgetProductivityByLevel from './BudgetProductivityByThreatLevel';
import BudgetProductivityByControl from './BudgetProductivityByControl';
import BudgetProductivityByResource from './BudgetProductivityByResource';

type mapStateType = {
  kpiGroups: { payload: KpiGroupType[] };
  threatSurfaces: threatSurfaceState;
  threatLevels: threatLevelState;
  app: { tag: TagType };
  controls: controlsState;
};

export type InvestmentDataType = {
  totalBudget: KPI[];
  budgetSplit: KPI[];
  budgetAllocation: KPI[];
  currentBudget: KPI;
  management: KPI[][];
  resources: KPI[][];
  integration: KPI[][];
  secOpsProductivity: number;
  control: Control;
};

type InvestmentDashboardType = {
  kpiGroups: KpiGroupType[];
  selectedControl: Control;
  getThreatSurfaces: () => void;
  threatSurfaces: threatSurfaceState;
  getThreatLevels: () => void;
  threatLevels: threatLevelState;
  selectedTag: TagType;
  getControls: (forceFetch: boolean, tagKey?: string) => void;
  controls: controlsState;
};

const InvestmentDashboard = ({
  kpiGroups,
  getThreatLevels,
  threatLevels,
  getThreatSurfaces,
  threatSurfaces,
  selectedTag,
  getControls,
  controls,
}: InvestmentDashboardType): JSX.Element => {
  const controlsPayload = controls.payload as Control[];
  const [loading, setLoading] = React.useState<boolean>(true);
  const [investmentData, setInvestmentData] = React.useState<
    InvestmentDataType[] | null
  >(null);
  const { url } = useRouteMatch();
  const groupPath = url.split('/').pop();
  const [kpiGroup] = kpiGroups.filter(
    (group: KpiGroupType) => group.path === groupPath
  );
  const totalBudgetGroup = findSubGroupByKey(kpiGroup, 'total-budget');
  const securityKpiGroup = kpiGroups.find(
    (grp: KpiGroupType) => grp.path === 'security-coverage'
  );
  const currentGroup = findSubGroupByKey(
    kpiGroup,
    'budget-allocation-to-surfaces'
  );

  const budgetAllocGroup = findSubGroupByKey(kpiGroup, 'budget-allocation');

  if (!kpiGroup) {
    return <div>Invalid KPI Group</div>;
  }

  React.useEffect(() => {
    if (!threatSurfaces.isLoading && !threatSurfaces.payload) {
      getThreatSurfaces();
    }

    if (!threatLevels.isLoading && !threatLevels.payload) {
      getThreatLevels();
    }

    if (!controls.isLoading && !Array.isArray(controlsPayload)) {
      const forceFetch = true;
      getControls(forceFetch, selectedTag.key);
    }

    if (
      !controls.isLoading &&
      Array.isArray(controlsPayload) &&
      controlsPayload.length &&
      !investmentData
    ) {
      setTimeout(() => {
        (async () => {
          const allData = await Promise.all(
            controlsPayload.map(async (control) => {
              // get total budget
              const totalBudget: KPI[] = await getKPIsAndValues({
                controlId: control.id,
                groupKey: totalBudgetGroup?.key,
              });

              // get budget split
              const budgetSplit: KPI[] = (
                await getKPIsAndValues({
                  groupId: securityKpiGroup?.id,
                  controlId: control.id,
                })
              ).filter((budgetData) => budgetData.key.includes('budget-split'));

              // get current budget
              const [currentBudget]: KPI[] = await getKPIsAndValues({
                controlId: control.id,
                groupKey: currentGroup?.key,
              });

              // get budget allocation
              const budgetAllocation: KPI[] = await getKPIsAndValues({
                controlId: control.id,
                groupId: budgetAllocGroup?.id,
              });

              // get sec ops productivity
              const management = await kpiGroupPromiseCreator(
                'management',
                kpiGroups,
                control.id
              );
              const resources = await kpiGroupPromiseCreator(
                'resources',
                kpiGroups,
                control.id
              );
              const integration = await kpiGroupPromiseCreator(
                'integration',
                kpiGroups,
                control.id
              );

              const secOpsProductivity = getSecOpsProductivity(
                management,
                resources,
                integration,
                kpiGroups
              );

              return {
                management,
                resources,
                integration,
                secOpsProductivity,
                totalBudget,
                budgetAllocation,
                budgetSplit,
                currentBudget,
                control,
              };
            })
          );

          setInvestmentData(allData);
          setLoading(false);
        })();
      }, 0);
    }
  });

  if (
    threatSurfaces.isLoading ||
    threatLevels.isLoading ||
    loading ||
    controls.isLoading
  ) {
    return <Loader />;
  }

  if (!investmentData || !controlsPayload) {
    return <div />;
  }

  return (
    <div>
      <BudgetProductivity
        selectedTag={selectedTag}
        investmentData={investmentData}
      />

      <BudgetProductivityBySurface
        selectedTag={selectedTag}
        investmentData={investmentData}
        threatSurfaces={threatSurfaces}
      />
      <BudgetProductivityByLevel
        selectedTag={selectedTag}
        investmentData={investmentData}
        threatSurfaces={threatSurfaces}
        threatLevels={threatLevels}
      />
      <BudgetProductivityByResource
        selectedTag={selectedTag}
        investmentData={investmentData}
      />

      <BudgetProductivityByControl
        selectedTag={selectedTag}
        investmentData={investmentData}
      />
    </div>
  );
};

const mapState = ({
  app: { tag },
  kpiGroups: { payload },
  threatSurfaces,
  threatLevels,
  controls,
}: mapStateType) => ({
  kpiGroups: payload,
  threatLevels,
  threatSurfaces,
  selectedTag: tag,
  controls,
});

const mapDispatch = {
  getThreatLevels: fetchThreatLevels,
  getThreatSurfaces: fetchThreatSurfaces,
  getControls: fetchControls,
};

export default connect(mapState, mapDispatch)(InvestmentDashboard);
