import { KPI, KPIValue } from 'app/services/api';
import Loader from 'common/Loader';
import BarGraph, {
  green,
  orange,
  dollarFormatting,
  findMaxProductivityValue,
  getHighLowColours,
} from 'common/BarGraph';
import { getSurfaceBudget } from 'utils/graph-math';
import { commonDashboardTypes, threatLevelTypes } from './index';
import Section from 'app/common/Section';
import StripedTable from 'app/common/StripedTable';

const featureName =
  'dashboard/control-dashboards/investment/budget-productivity-by-threat-level';

type BudgetProductivityByThreatLevelType = commonDashboardTypes &
  threatLevelTypes & {
    secOpsProductivity: number;
    budgetSplit: KPI[];
    budgetAverage: number;
    totalBudget: KPI[];
    currentBudget: KPI;
  };

const BudgetProductivityByThreatLevel = ({
  budgetSplit,
  budgetAverage,
  threatLevels,
  threatSurfaces,
  secOpsProductivity,
  totalBudget,
  currentBudget,
  controlId, // not used
}: BudgetProductivityByThreatLevelType) => {
  if (threatLevels.isLoading) {
    return <Loader />;
  }

  const surfaceBudgets = threatSurfaces.payload.map((tSurface) => {
    const surfaceBudget = getSurfaceBudget(
      totalBudget,
      currentBudget?.kpiValue.find(
        (kpiValue: KPIValue) => kpiValue.surfaceId === tSurface.id
      )
    );

    return {
      id: tSurface.id,
      label: tSurface.name,
      budget: surfaceBudget,
    };
  });

  // starting from threat level 1-6
  const barData = threatLevels.payload.map((tLevel) => {
    // surfaces level 1 > 4
    const budgetSplitSurfaces = budgetSplit[0].kpiValue.filter(
      (kpi) => kpi.levelId === tLevel.id
    );
    const threatLevelBudget = budgetSplitSurfaces.map((surf) => {
      const surface = surfaceBudgets.find(
        (surface) => surface.id === surf.surfaceId
      );
      if (surface) {
        return surface?.budget * (Number(surf.value) / 100);
      }
      return;
    });

    const lowProductivity = threatLevelBudget.reduce((acc = 0, curr) => {
      if (curr) {
        return Math.round(acc + curr * ((100 - secOpsProductivity) / 100));
      }
      return acc;
    }, 0);

    const highProductivity = threatLevelBudget.reduce((acc = 0, curr) => {
      if (curr) {
        return Math.round(acc + curr * (secOpsProductivity / 100));
      }
      return acc;
    }, 0);

    return {
      id: tLevel.id,
      label: `${tLevel.severity}. ${tLevel.name}`,
      lowProductivity,
      lowProductivityColour: orange,
      highProductivity,
      highProductivityColour: green,
    };
  });
  const dataKeys = ['highProductivity', 'lowProductivity'];

  return (
    <Section>
      <StripedTable>
        <StripedTable.Head>
          <StripedTable.Heading colSpan={2}>
            Budget Productivity by Threat Level
          </StripedTable.Heading>
          <StripedTable.SubHeading colSpan={2}>
            Threat Level
          </StripedTable.SubHeading>
        </StripedTable.Head>

        <StripedTable.Body>
          {barData.map((rowData, index) => {
            const isLast = index === barData.length - 1;

            return (
              <StripedTable.Row key={`${featureName}/${rowData.id}`}>
                <StripedTable.GraphLabel
                  style={{ width: '15%' }}
                  isLast={isLast}
                >
                  {rowData.label}
                </StripedTable.GraphLabel>
                <StripedTable.GraphCell isLast={isLast}>
                  <BarGraph
                    data={[rowData]}
                    keys={dataKeys}
                    colors={getHighLowColours}
                    axisBottom={isLast ? { format: dollarFormatting } : null}
                    maxValue={findMaxProductivityValue(barData)}
                    labelFormat={dollarFormatting}
                  />
                </StripedTable.GraphCell>
              </StripedTable.Row>
            );
          })}
        </StripedTable.Body>
      </StripedTable>
    </Section>
  );
};

export default BudgetProductivityByThreatLevel;
