import React from 'react';
import { InputAdornment } from '@material-ui/core';
import { KPIGroupDisplay } from 'app/kpi/types/KPIGroupDisplay';
import {
  Control,
  ControlCapability,
  KPIGroupHints,
  KPIValueTypes,
  ThreatLevel,
  ThreatSurface,
} from 'app/services/api';
import { InputColumn } from 'app/kpi/components/tables/ValueInput';
import { KPIData } from 'app/kpi/types';
import { KPIDisplay } from 'app/kpi/types/KPIDisplay';

import MuiGrey from '@material-ui/core/colors/grey';

export * from 'app/kpi/components/tables/Likert';
export * from 'app/kpi/components/tables/ThreatLevel';
export * from 'app/kpi/components/tables/ValueInput';

export interface ColumnConfig {
  text: string;
  helpText?: string;
  textAlign?: 'left' | 'right';
  inputProps?: {
    startAdornment?: JSX.Element;
    endAdornment?: JSX.Element;
    type?: string;
    inputProps?: {
      min?: number;
      max?: number;
    };
    currency?: string;
  };
  style?: {
    width?: string;
    fontSize?: string;
  };
}

export interface InputItem {
  text: string;
  context?: string;
  kpis: Array<KPIData<any>>;
}

export const kpiHeaderTitle = 'Key Performance Indicator (KPI)';

const kpiValueTypeToColumnConfig: Record<KPIValueTypes, ColumnConfig> = {
  [KPIValueTypes.Text]: {
    text: 'Text',
  },
  [KPIValueTypes.Decimal]: {
    text: 'Number',
    helpText: 'Number - can be fractional, ex. 1.25 people',
    inputProps: {
      type: 'text',
    },
    style: {
      width: '8rem',
    },
  },
  [KPIValueTypes.Integer]: {
    text: 'Number',
    inputProps: {
      type: 'number',
    },
  },
  [KPIValueTypes.Currency]: {
    text: 'Amount',
    helpText: 'From 0 to millions',
    // textAlign: 'left' as const,
    style: {
      width: '8rem',
    },
  },
  [KPIValueTypes.Percentage]: {
    text: 'Percentage',
    helpText: 'Sum to 100%',
    // textAlign: 'right' as const,
    inputProps: {
      endAdornment: <InputAdornment position="end">%</InputAdornment>,
    },
    style: {
      width: '6rem',
    },
  },
  [KPIValueTypes.Likert]: {
    text: 'Value',
  },
};

const getDefaultColumnConfig = (type: KPIValueTypes): ColumnConfig => {
  const config = {
    ...kpiValueTypeToColumnConfig[type],
  };

  return config;
};

export interface ValueInputTableConfig {
  text: string;
  columns: InputColumn[];
  items: InputItem[];
}

type TableConfigBuilder = (
  kpiGroup: KPIGroupDisplay,
  control: Control,
  capabilities?: ControlCapability[] | null,
  surfaces?: ThreatSurface[] | null,
  levels?: ThreatLevel[] | null,
  getColumnConfig?: ((kpi: KPIDisplay, index: number) => ColumnConfig) | null,
  getItemText?: ((index: number) => string) | null
) => ValueInputTableConfig;

const buildConfigForSingleColumn: TableConfigBuilder = (
  kpiGroup,
  control,
  capabilities,
  surfaces,
  levels,
  getColumnConfig
) => {
  const column = getDefaultColumnConfig(kpiGroup.kpis[0].valueType);
  if (getColumnConfig) {
    Object.assign(column, getColumnConfig(kpiGroup.kpis[0], 0));
  }
  const defaultValueProps = {
    controlId: control.id,
  };
  const items = kpiGroup.kpis.map((kpi) => ({
    text: kpi.text,
    kpis: [kpi.getValueAt(0, defaultValueProps)],
  }));
  return {
    text: kpiGroup.text,
    columns: [column],
    items,
  };
};

const buildConfigForRepeatSurfaces: TableConfigBuilder = (
  kpiGroup,
  control,
  capabilities,
  surfaces,
  levels,
  getColumnConfig,
  getItemText
) => {
  const columns = kpiGroup.kpis.map((kpi, i) => {
    const column = getDefaultColumnConfig(kpi.valueType);
    if (getColumnConfig) {
      Object.assign(column, getColumnConfig(kpi, i));
    }
    return column;
  });
  const items = (surfaces || []).map((surface, i) => ({
    text: getItemText ? getItemText(i) : surface.name,
    kpis: kpiGroup.kpis.map((kpi) =>
      kpi.getValueBySurfaceId(surface.id, control.id, true)
    ) as KPIData<any>[],
  }));
  return {
    text: kpiGroup.text,
    columns,
    items,
  };
};

const buildConfigForRepeatCapability: TableConfigBuilder = (
  kpiGroup,
  control,
  capabilities,
  surfaces,
  levels,
  getColumnConfig,
  getItemText
) => {
  const columns = kpiGroup.kpis.map((kpi, i) => {
    const column = getDefaultColumnConfig(kpi.valueType);
    if (getColumnConfig) {
      Object.assign(column, getColumnConfig(kpi, i));
    }
    return column;
  });
  const items = (capabilities || []).map((capability, i) => ({
    text: getItemText ? getItemText(i) : capability.name,
    kpis: kpiGroup.kpis.map((kpi: any) =>
      kpi.getValueByCapability(capability, control.id, true, surfaces)
    ) as KPIData<any>[],
  }));
  return {
    text: kpiGroup.text,
    columns,
    items,
  };
};

const buildConfigForRepeatLevels: TableConfigBuilder = (
  kpiGroup,
  control,
  capabilities,
  surfaces,
  levels,
  getColumnConfig
) => {
  const columns = kpiGroup.kpis.map((kpi, i) => {
    const column = getDefaultColumnConfig(kpi.valueType);
    if (getColumnConfig) {
      Object.assign(column, getColumnConfig(kpi, i));
    }
    return column;
  });
  columns.splice(0, 0, { text: 'Threat Level' }, { text: 'Capability' });

  const items = (levels || []).map((level) => {
    const levelId = level?.id || 1;
    const capability = capabilities?.filter(
      (ele) => ele.levelId === level.id
    )[0];

    let text = '';
    if (capability && capability.name) {
      text = capability.name;
    }

    return {
      level: level,
      text: text,
      kpis: kpiGroup.kpis.map((kpi: any) => {
        const result = kpi.getValueByLevelId(levelId, control.id, true);

        result.kpiValue = {
          ...result.kpiValue,
          levelId,
        };
        return result;
      }) as KPIData<any>[],
    };
  });
  return {
    text: kpiGroup.text,
    columns,
    items,
  };
};

const buildConfigForRepeatKPIs: TableConfigBuilder = (
  kpiGroup,
  control,
  capabilities,
  surfaces,
  levels,
  getColumnConfig
) => {
  const subGroups = kpiGroup?.kpiGroup.subGroups || [];
  const columns: InputColumn[] = subGroups.map((subGroup) => {
    const column = getDefaultColumnConfig(KPIValueTypes.Percentage);

    if (getColumnConfig) {
      Object.assign(column, { text: subGroup.name });
    }

    return column;
  });

  const kpis = kpiGroup?.kpis || [];
  const groupedKPIs = kpis.reduce((result: any, next) => {
    if (result[next.kpi.displayOrder]) {
      result[next.kpi.displayOrder] = result[next.kpi.displayOrder].concat(
        next
      );
    } else {
      result[next.kpi.displayOrder] = [next];
    }
    return result;
  }, {});
  const items: InputItem[] = Object.values(groupedKPIs).map(
    (displayKPIs: any) => ({
      text: displayKPIs[0].name,
      kpis: displayKPIs.map((kpi: any) => kpi.getKpiValue(control.id, true)),
    })
  );

  return {
    text: kpiGroup.kpiGroup.name,
    columns,
    items,
  };
};

const tableConfigBuilders = new Map([
  [KPIGroupHints.Default, buildConfigForSingleColumn],
  [KPIGroupHints.HasRepeatSubgroups, buildConfigForRepeatKPIs],
  [KPIGroupHints.RepeatKPIsForThreatSurface, buildConfigForRepeatSurfaces],
  [KPIGroupHints.RepeatKPIsForThreatLevel, buildConfigForRepeatLevels],
  [KPIGroupHints.RepeatKPIsForCapability, buildConfigForRepeatCapability],
]);

export const buildValueInputTableConfig: TableConfigBuilder = (
  kpiGroup,
  ...rest
) => {
  const builder = tableConfigBuilders.get(kpiGroup.hint);
  if (!builder) {
    throw new Error('Invalid KPI group hint');
  }
  return builder(kpiGroup, ...rest);
};

export const tableContainerStyles = {
  borderBottom: `1px solid ${MuiGrey[400]}`,
};

export const tableContainerWithMarginStyles = {
  margin: '1rem 0',
  borderBottom: `1px solid ${MuiGrey[400]}`,
};

export const headerStyles = { backgroundColor: MuiGrey[400] };
export const subHeaderStyles = { backgroundColor: MuiGrey[300] };

export type colStylesType = {
  minWidth?: string;
  maxWidth?: string;
  width?: string;
};

export const firstColWidth = 164;
export const lastColWidth = 121;

export const getColWidth = (numberOfThreatSurfaces: number) => {
  if (!numberOfThreatSurfaces) {
    return '117px';
  }

  return `${(1184 - firstColWidth - lastColWidth) / numberOfThreatSurfaces}px`;
};

export const firstColStyles: colStylesType = {
  minWidth: `${firstColWidth}px`,
  maxWidth: `${firstColWidth}px`,
};
export const lastColStyles = {
  minWidth: `${lastColWidth}px`,
  maxWidth: `${lastColWidth}px`,
};
