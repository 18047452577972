import { connect } from 'react-redux';
import {
  Switch,
  Route,
  Redirect,
  useRouteMatch,
  useHistory,
} from 'react-router-dom';
import {
  TagType,
  TagHints,
  KPIGroup as KPIGroupType,
  KPIGroupHints,
  Control,
} from 'app/services/api';
import { fetchTags, tagsState } from 'app/common/actions/tags';
import { recursivelyFind } from 'app/utils/helpers';
import { updateAppSelection, updateAppSelectionType } from 'app/common/actions';
import { fetchControls, controlsState } from 'app/common/actions/controls';
import Loader from 'app/common/Loader';
import Container from '@material-ui/core/Container';
import NavBreadcrumbs, { getBreadcrumbs } from 'app/common/NavBreadcrumbs';
import NavKPIGroups from 'app/kpi/components/NavKPIGroups';
import { fetchKpiGroups, kpiGroupsState } from 'app/common/actions/kpi-groups';
import PATHS from 'app/utils/paths';
import { addHistoryListener } from 'app/common/NavHistory';

import ElementsSelection from 'app/common/NavElementsSelection';
import ControlsSelection from 'app/common/NavControlsSelection';
import SecurityCoverage from 'app/kpi/SecurityCoverage';
import Management from 'app/kpi/Management';
import Resources from 'app/kpi/Resources';
import Integration from 'app/kpi/Integration';
import Investment from 'app/kpi/Investment';

type reduxStateType = {
  tags: tagsState;
  app: {
    tag: TagType;
    control: Control;
  };
  kpiGroups: kpiGroupsState;
  controls: controlsState;
};

type KpiUpdateTypes = {
  selectedTag: TagType;
  getTags: () => void;
  tags: tagsState;
  updateApp: (selection: updateAppSelectionType) => void;
  kpiGroups: kpiGroupsState;
  getKpiGroups: () => void;
  selectedControl: Control;
  controls: controlsState;
  getControls: (forceFetch: boolean, tagKey?: string, include?: string) => void;
};

const Noop = () => <div />;

const getNavKpiGroups = (
  kpiGroups: KPIGroupType[],
  selectedTag: TagType,
  selectedControl: Control,
  controlsPayload: Control[]
) => {
  let mappedGroups: KPIGroupType[] = [];
  if (selectedControl) {
    mappedGroups = mappedGroups.concat(kpiGroups);
  }

  if (!selectedControl && selectedTag.hint === TagHints.ControlContainer) {
    mappedGroups.splice(0, 0, {
      id: 11,
      key: PATHS.CONTROLS,
      name: 'Controls',
      parentId: null,
      hint: KPIGroupHints.Default,
      path: PATHS.CONTROLS.replace('/', ''),
      weight: 0,
      displayOrder: 0,
    });
  }

  if (!selectedControl && selectedTag.subTags) {
    mappedGroups.splice(0, 0, {
      id: 10,
      key: PATHS.ELEMENTS,
      name: 'Elements',
      parentId: null,
      hint: KPIGroupHints.Default,
      path: PATHS.ELEMENTS.replace('/', ''),
      weight: 0,
      displayOrder: 0,
    });
  }

  return mappedGroups;
};

const getRedirectUrl = (selectedTag: TagType, controlId: string) => {
  if (!controlId && selectedTag.subTags) {
    return PATHS.ELEMENTS;
  }

  if (!controlId && selectedTag.hint) {
    return PATHS.CONTROLS;
  }

  return PATHS.SECURITY_COVERAGE;
};

const KpiUpdate = ({
  selectedTag,
  getTags,
  tags,
  updateApp,
  kpiGroups,
  getKpiGroups,
  selectedControl,
  controls,
  getControls,
}: KpiUpdateTypes): JSX.Element => {
  const {
    url,
    params: { tagId, controlId },
  }: {
    url: string;
    params: {
      tagId: string;
      controlId: string;
    };
  } = useRouteMatch();

  const history = useHistory();
  React.useEffect(() => {
    return addHistoryListener(history, 'kpi', {
      selectedTag,
      selectedControl,
      tags,
      controls,
      updateApp,
    });
  }, [history, updateApp, tags, controls, selectedTag, selectedControl]);

  React.useEffect(() => {
    // fetch tags & setup selectedTag
    if (!tags.isLoading && !('payload' in tags)) {
      getTags();
    }

    if (
      !tags.isLoading &&
      Array.isArray(tags.payload) &&
      ((tagId && !selectedTag) ||
        (tagId && selectedTag && Number(tagId) !== selectedTag.id))
    ) {
      const existingTag = recursivelyFind('tags', Number(tagId), tags.payload);
      updateApp({ tag: existingTag });
    }

    // fetch kpi groups
    if (!kpiGroups.isLoading && !('payload' in kpiGroups)) {
      getKpiGroups();
    }

    // fetch controls & setup selectedControl
    if (selectedTag && !controls.isLoading && !('payload' in controls)) {
      const forceFetch = true;
      const tagKey = selectedTag.key;
      const include = 'tags,subTags';
      getControls(forceFetch, tagKey, include);
    } else if (
      !selectedControl &&
      !controls.isLoading &&
      Array.isArray(controls.payload)
    ) {
      const [currentControl] = (controls.payload as Control[]).filter(
        (control: Control) => control.id === Number(controlId)
      );

      updateApp({ control: currentControl });
    }
  });

  if (tags.isLoading || kpiGroups.isLoading) {
    return <Loader />;
  }

  if (!tags.payload || !selectedTag || !kpiGroups.payload) {
    return <div />;
  }

  const baseUrl = PATHS.KPI;

  return (
    <Container maxWidth={false}>
      <NavBreadcrumbs
        breadcrumbs={getBreadcrumbs({
          baseUrl,
          selectedTag,
          updateApp,
          tags,
          history,
          controlId,
          selectedControl,
        })}
      />

      <NavKPIGroups
        kpiGroups={getNavKpiGroups(
          kpiGroups.payload as KPIGroupType[],
          selectedTag,
          selectedControl,
          controls.payload as Control[]
        )}
      />

      <Container maxWidth={false}>
        <Switch>
          <Route
            path={`${url}${PATHS.ELEMENTS}`}
            component={ElementsSelection}
          />
          <Route
            exact
            path={`${url}${PATHS.CONTROLS}`}
            component={ControlsSelection}
          />
          <Route
            path={`${url}${PATHS.SECURITY_COVERAGE}`}
            component={selectedControl ? SecurityCoverage : Noop}
          />
          <Route
            path={`${url}${PATHS.MANAGEMENT}`}
            component={selectedControl ? Management : Noop}
          />
          <Route
            path={`${url}${PATHS.RESOURCES}`}
            component={selectedControl ? Resources : Noop}
          />
          <Route
            path={`${url}${PATHS.INTEGRATION}`}
            component={selectedControl ? Integration : Noop}
          />
          <Route
            path={`${url}${PATHS.INVESTMENT}`}
            component={selectedControl ? Investment : Noop}
          />

          <Redirect to={`${url}${getRedirectUrl(selectedTag, controlId)}`} />
        </Switch>
      </Container>
    </Container>
  );
};

const mapState = ({
  kpiGroups,
  tags,
  app: { tag, control },
  controls,
}: reduxStateType) => ({
  selectedTag: tag,
  selectedControl: control,
  tags,
  kpiGroups,
  controls,
});

const mapDispatch = {
  getTags: fetchTags,
  updateApp: updateAppSelection,
  getKpiGroups: fetchKpiGroups,
  getControls: fetchControls,
};

export default connect(mapState, mapDispatch)(KpiUpdate);
