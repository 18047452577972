import React from 'react';
import {
  KPIGroup as KpiGroupType,
  getKPIsAndValues,
  KPI,
} from 'app/services/api';
import Loader from 'app/common/Loader';
import BarGraph, {
  green,
  orange,
  dollarFormatting,
  getHighLowColours,
  findMaxProductivityValue,
} from 'app/common/BarGraph';
import Section from 'app/common/Section';
import StripedTable from 'app/common/StripedTable';

import { kpiHeaderTitle } from 'app/kpi/components/tables';
import { getBudgetAverage, getSecOpsProductivity } from 'app/utils/graph-math';
import { kpiGroupPromiseCreator, findSubGroupByKey } from 'app/utils/helpers';

import { commonDashboardTypes, threatLevelTypes } from './index';
import BudgetProductivityBySurface from './BudgetProductivityByThreatSurface';
import BudgetProductivityByResource from './BudgetProductivityByResource';
import BydgetProductivityByThreatLevels from './BudgetProductivityByThreatLevel';

type BudgetProductivityTypes = commonDashboardTypes &
  threatLevelTypes & {
    kpiGroups: KpiGroupType[];
    kpiGroup: KpiGroupType;
  };

const BudgetProductivity = ({
  kpiGroups,
  kpiGroup,
  getThreatSurfaces,
  getThreatLevels,
  threatSurfaces,
  threatLevels,
  controlId,
}: BudgetProductivityTypes): JSX.Element => {
  const currentGroup = findSubGroupByKey(
    kpiGroup,
    'budget-allocation-to-surfaces'
  );

  const securityKpiGroup = kpiGroups.filter(
    (grp: KpiGroupType) => grp.path === 'security-coverage'
  )[0];

  const totalBudgetGroup = findSubGroupByKey(kpiGroup, 'total-budget');
  const budgetAllocGroup = findSubGroupByKey(kpiGroup, 'budget-allocation');
  const [loading, setLoading] = React.useState<boolean>(true);
  const [budgetSplit, setBudgetSplit] = React.useState<KPI[]>([]);
  const [totalBudget, setTotalBudget] = React.useState<KPI[] | null>(null);
  const [currentBudget, setCurrentBudget] = React.useState<KPI | null>(null);
  const [management, setManagement] = React.useState<KPI[][] | null>(null);
  const [resources, setResources] = React.useState<KPI[][] | null>(null);
  const [integration, setIntegration] = React.useState<KPI[][] | null>(null);

  React.useEffect(() => {
    if (
      !kpiGroup ||
      !controlId ||
      !currentGroup ||
      !totalBudgetGroup ||
      !securityKpiGroup
    ) {
      return;
    }

    getThreatSurfaces();
    getThreatLevels();

    (async () => {
      const totalBudgetData: KPI[] = await getKPIsAndValues({
        controlId,
        groupKey: totalBudgetGroup.key,
      });
      setTotalBudget(totalBudgetData);

      const budgetSplitData: KPI[] = await getKPIsAndValues({
        groupId: securityKpiGroup.id,
        controlId,
      });

      setBudgetSplit(
        budgetSplitData.filter((item) => item.key.includes('budget-split'))
      );

      const currentBudgetData: KPI[] = await getKPIsAndValues({
        controlId,
        groupKey: currentGroup.key,
      });
      setCurrentBudget(currentBudgetData[0]);

      const managementData = await kpiGroupPromiseCreator(
        'management',
        kpiGroups,
        controlId
      );
      setManagement(managementData);
      const resourcesData = await kpiGroupPromiseCreator(
        'resources',
        kpiGroups,
        controlId
      );
      setResources(resourcesData);
      const integrationData = await kpiGroupPromiseCreator(
        'integration',
        kpiGroups,
        controlId
      );
      setIntegration(integrationData);

      setLoading(false);
    })();
  }, []);

  if (loading) {
    return (
      <div style={{ minHeight: '500px' }}>
        <Loader />
      </div>
    );
  }

  if (!totalBudget || !currentBudget) {
    return <div>No Budgets found</div>;
  }

  const secOpsProductivity = getSecOpsProductivity(
    management,
    resources,
    integration,
    kpiGroups
  );
  const budgetAverage = getBudgetAverage(totalBudget);
  const highProductivity = Math.round(
    budgetAverage * (secOpsProductivity / 100)
  );
  const lowProductivity = Math.round(budgetAverage - highProductivity);
  const barData = [
    {
      id: 1,
      label: 'Budget Productivity',
      highProductivity,
      highProductivityColour: green,
      lowProductivity,
      lowProductivityColour: orange,
    },
  ];
  const dataKeys = ['highProductivity', 'lowProductivity'];

  return (
    <>
      <Section>
        <StripedTable>
          <StripedTable.Head>
            <StripedTable.Heading colSpan={2}>
              Budget Productivity
            </StripedTable.Heading>
            <StripedTable.SubHeading colSpan={2}>
              {kpiHeaderTitle}
            </StripedTable.SubHeading>
          </StripedTable.Head>

          <StripedTable.Body>
            <StripedTable.Row>
              <StripedTable.GraphLabel style={{ width: '15%' }} isLast>
                Budget Productivity
              </StripedTable.GraphLabel>
              <StripedTable.GraphCell isLast>
                <BarGraph
                  data={barData}
                  keys={dataKeys}
                  colors={getHighLowColours}
                  axisBottom={{ format: dollarFormatting }}
                  labelFormat={dollarFormatting}
                  maxValue={findMaxProductivityValue(barData)}
                />
              </StripedTable.GraphCell>
            </StripedTable.Row>
          </StripedTable.Body>
        </StripedTable>
      </Section>

      <BudgetProductivityBySurface
        getThreatSurfaces={getThreatSurfaces}
        threatSurfaces={threatSurfaces}
        secOpsProductivity={secOpsProductivity}
        totalBudget={totalBudget}
        currentBudget={currentBudget}
        controlId={controlId}
      />
      <BydgetProductivityByThreatLevels
        budgetSplit={budgetSplit}
        budgetAverage={budgetAverage}
        getThreatLevels={getThreatLevels}
        getThreatSurfaces={getThreatSurfaces}
        threatSurfaces={threatSurfaces}
        threatLevels={threatLevels}
        secOpsProductivity={secOpsProductivity}
        totalBudget={totalBudget}
        currentBudget={currentBudget}
        controlId={controlId}
      />
      <BudgetProductivityByResource
        secOpsProductivity={secOpsProductivity}
        budgetAllocGroup={budgetAllocGroup}
        controlId={controlId}
        totalBudget={totalBudget}
      />
    </>
  );
};

export default BudgetProductivity;
