import { KPIValue, KPIValueTypes, saveKPIValue } from 'app/services/api';

type KPIDataValue = null | string | number;

export class KPIData<T extends KPIValueTypes> {
  readonly type: T;
  readonly kpiValue: KPIValue;

  constructor(type: T, kpiValue: KPIValue) {
    this.type = type;
    this.kpiValue = kpiValue;
  }

  get key() {
    return this.kpiValue.kpi.key;
  }

  get text() {
    return this.kpiValue.kpi.name;
  }

  get value(): KPIDataValue {
    return this.kpiValue.value;
  }
  set value(data) {
    this.setValue(data);
  }

  get displayOrder() {
    return this.kpiValue.kpi.displayOrder;
  }

  getValue() {
    return this.kpiValue.value;
  }

  setValue(data: KPIDataValue): void {
    this.kpiValue.value = data;
    saveKPIValue(this.kpiValue);
  }
}

export class KPIText extends KPIData<KPIValueTypes.Text> {
  constructor(kpiValue: KPIValue) {
    super(KPIValueTypes.Text, kpiValue);
  }
}

export class KPIDecimal extends KPIData<KPIValueTypes.Decimal> {
  constructor(kpiValue: KPIValue) {
    super(KPIValueTypes.Decimal, kpiValue);
  }
}

export class KPIInteger extends KPIData<KPIValueTypes.Integer> {
  constructor(kpiValue: KPIValue) {
    super(KPIValueTypes.Integer, kpiValue);
  }
}

export class KPICurrency extends KPIData<KPIValueTypes.Currency> {
  constructor(kpiValue: KPIValue) {
    super(KPIValueTypes.Currency, kpiValue);
  }
}

export class KPIPercentage extends KPIData<KPIValueTypes.Percentage> {
  constructor(kpiValue: KPIValue) {
    super(KPIValueTypes.Percentage, kpiValue);
  }
}

export type LikertValue = null | 0 | 1 | 2 | 3 | 4 | 5;

export class KPILikert extends KPIData<KPIValueTypes.Likert> {
  constructor(kpiValue: KPIValue) {
    super(KPIValueTypes.Likert, kpiValue);
    this.kpiValue.value = parseInt(this.kpiValue.value as string);
  }
}

export type KPINumber =
  | KPIDecimal
  | KPIInteger
  | KPICurrency
  | KPIPercentage
  | KPILikert;

const constructors = {
  [KPIValueTypes.Text]: KPIText,
  [KPIValueTypes.Decimal]: KPIDecimal,
  [KPIValueTypes.Integer]: KPIInteger,
  [KPIValueTypes.Currency]: KPICurrency,
  [KPIValueTypes.Percentage]: KPIPercentage,
  [KPIValueTypes.Likert]: KPILikert,
};

export const buildKPIData = (kpiValue: KPIValue): KPIData<any> =>
  new constructors[kpiValue.kpi.valueType.key](kpiValue);
