import { threatLevelState } from 'app/common/actions/threat-levels';
import { threatSurfaceState } from 'app/common/actions/threat-surfaces';
import { TagType, ThreatLevel, ThreatSurface } from 'app/services/api';
import { getLevelHighProductivityBudget, getLevelLowProductivityBudget } from 'app/utils/graph-math';
import { InvestmentDataType } from '.';
import BarGraph, { green, orange, dollarFormatting, getHighLowColours, findMaxProductivityValue } from 'app/common/BarGraph';
import Section from 'app/common/Section';
import StripedTable from 'app/common/StripedTable';

const featureName = 'dashboard/tag-dashboards/investment/budget-productivity-by-level';

type BudgetProductivityByLevelType = {
  investmentData: InvestmentDataType[];
  selectedTag: TagType;
  threatLevels: threatLevelState;
  threatSurfaces: threatSurfaceState;
};

const BudgetProductivityByLevel = ({
  selectedTag,
  investmentData,
  threatSurfaces,
  threatLevels,
}: BudgetProductivityByLevelType): JSX.Element => {
  const tLevelPayload = threatLevels.payload as ThreatLevel[];
  const tSurfacePayload = threatSurfaces.payload as ThreatSurface[];

  if (!tLevelPayload || !tSurfacePayload) {
    return <div />;
  }

  const dataKeys = ['highProductivity', 'lowProductivity'];
  const barData = tLevelPayload.map(tLevel => {
    const { highProductivity, lowProductivity } = investmentData.reduce(
      (result, controlData, controlindex) => {
        const secOpsProductivity = controlData.secOpsProductivity;
        const controlBudgetSplit = controlData.budgetSplit[0];
        const controlTotalBudget = controlData.totalBudget;
        const controlCurrentBudget = controlData.currentBudget;

        const highProd = getLevelHighProductivityBudget(
          tSurfacePayload,
          tLevel,
          controlTotalBudget,
          controlCurrentBudget,
          controlBudgetSplit,
          secOpsProductivity,
        );
        const lowProd = getLevelLowProductivityBudget(
          tSurfacePayload,
          tLevel,
          controlTotalBudget,
          controlCurrentBudget,
          controlBudgetSplit,
          secOpsProductivity,
        );

        const isLast = controlindex === investmentData.length - 1;
        const highProdSum = result.highProductivity + highProd;
        const lowProdSum = result.lowProductivity + lowProd;

        return {
          highProductivity: isLast ? Math.round(highProdSum) : highProdSum,
          lowProductivity: isLast ? Math.round(lowProdSum) : lowProdSum,
        };
      },
      { highProductivity: 0, lowProductivity: 0 },
    );

    return {
      id: tLevel.id,
      label: `${tLevel.severity}. ${tLevel.name}`,
      highProductivity,
      highProductivityColour: green,
      lowProductivity,
      lowProductivityColour: orange,
    }
  });
  let bargraphHeight = tLevelPayload.length ? `${(tLevelPayload.length + 1) * 45}px` : '225px';

  if (Array.isArray(barData) && barData.length > 0) {
    bargraphHeight = `${(barData.length + 1) * 45}px`;
  }

  return (
    <Section>
      <StripedTable>
      <StripedTable.Head>
          <StripedTable.Heading colSpan={2}>{selectedTag.name} Budget Productivity by Threat Level</StripedTable.Heading>
          <StripedTable.SubHeading colSpan={2}>Threat Level</StripedTable.SubHeading>
        </StripedTable.Head>

        <StripedTable.Body>
          {barData.map((rowData, index) => {
            const isLast = index === barData.length - 1;

            return (
              <StripedTable.Row key={`${featureName}/${rowData.id}`}>
                <StripedTable.GraphLabel style={{ width: '15%' }} isLast={isLast}>{rowData.label}</StripedTable.GraphLabel>
                <StripedTable.GraphCell isLast={isLast}>
                  <BarGraph
                    data={[rowData]}
                    keys={dataKeys}
                    colors={getHighLowColours}
                    axisBottom={isLast ? { format: dollarFormatting } : null}
                    maxValue={findMaxProductivityValue(barData)}
                    labelFormat={dollarFormatting}
                  />
                </StripedTable.GraphCell>
              </StripedTable.Row>
            );
          })}
        </StripedTable.Body>
      </StripedTable>
    </Section>
  );
};

export default BudgetProductivityByLevel;
