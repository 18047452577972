import { threatSurfaceState } from 'app/common/actions/threat-surfaces';
import {
  KPIValue,
  KPI,
  KPIGroup as KpiGroupType,
  TagType,
  ThreatSurface,
} from 'app/services/api';
import {
  getSurfaceHighProductivityBudget,
  getSurfaceLowProductivityBudget,
} from 'app/utils/graph-math';
import { getCurrentUser } from 'app/services/auth';
import Paper from '@material-ui/core/Paper';
import {
  tableContainerWithMarginStyles,
  headerStyles,
  firstColStyles,
  lastColStyles,
  getColWidth,
} from 'app/kpi/components/tables';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import MuiLightBlue from '@material-ui/core/colors/lightBlue';
import { green, orange } from 'common/BarGraph';

import { ControlKpiGroupDataType } from '.';

const featureName =
  'dashboard/tag-dashboards/summary/current-budget-productivity';

type CurrentBudgetProductivityType = {
  selectedTag: TagType;
  threatSurfaces: threatSurfaceState;
  totalBudgets: KPI[][];
  currentBudgets: KPI[];
  controlKpiGroupData: ControlKpiGroupDataType[];
  kpiGroups: KpiGroupType[];
};

const CurrentBudgetProductivityMatrix = ({
  selectedTag,
  threatSurfaces,
  totalBudgets,
  currentBudgets,
  controlKpiGroupData,
  kpiGroups,
}: CurrentBudgetProductivityType): JSX.Element => {
  const currency = getCurrentUser()?.currency;
  const locale = currency?.locale;
  const numberOfThreatSurfaces = (threatSurfaces.payload as ThreatSurface[])
    .length;
  const colWidth = getColWidth(numberOfThreatSurfaces);
  let tableHeadings = [
    {
      display: `Key Performance Indicator (KPI)`,
      style: firstColStyles,
    },
  ];
  const tableData = ['High Productivity', 'Low Productivity'].map(
    (display: string, rowIndex: number) => {
      let rowData: any = [
        {
          display,
          style: {},
        },
      ];

      rowData = rowData.concat(
        ((threatSurfaces.payload as ThreatSurface[]) || []).map(
          (tSurface: ThreatSurface) => {
            tableHeadings = rowIndex
              ? tableHeadings
              : tableHeadings.concat({
                  display: tSurface.name,
                  style: { width: colWidth },
                });

            const surfaceProdValue = controlKpiGroupData.reduce(
              (result, kpiData: any, controlIndex: number) => {
                const surfaceBudgetAllocation = currentBudgets[
                  controlIndex
                ].kpiValue?.find(
                  (kpiValue: KPIValue) => kpiValue.surfaceId === tSurface.id
                );
                const helperFunction = rowIndex
                  ? getSurfaceLowProductivityBudget
                  : getSurfaceHighProductivityBudget;

                const controlProdValue = helperFunction(
                  totalBudgets[controlIndex],
                  surfaceBudgetAllocation,
                  kpiData.management,
                  kpiData.resources,
                  kpiData.integration,
                  kpiGroups
                );

                const isLastControl =
                  controlIndex === controlKpiGroupData.length - 1;
                return isLastControl
                  ? Math.round(result + controlProdValue)
                  : result + controlProdValue;
              },
              0
            );

            return {
              display: surfaceProdValue,
              style: rowIndex
                ? { backgroundColor: orange }
                : { backgroundColor: green },
            };
          }
        )
      );

      const rowSum = rowData
        .slice(1)
        .reduce((result: number, next: any) => result + next?.display, 0);
      rowData = rowData.concat({
        display: rowSum,
        style: {
          backgroundColor: MuiLightBlue[900],
          color: 'white',
        },
      });

      rowData = rowData.map((data: any) => ({
        ...data,
        display:
          data.display.constructor === String
            ? data.display
            : data.display.toLocaleString(locale, currency),
      }));

      return rowData;
    }
  );
  tableHeadings = tableHeadings.concat({
    display: 'Row Sum',
    style: lastColStyles,
  });

  return (
    <Paper elevation={3} style={{ padding: '1.5rem', marginBottom: '2rem' }}>
      <div style={tableContainerWithMarginStyles}>
        <Table size="small">
          <TableHead>
            <TableRow style={headerStyles}>
              <TableCell
                colSpan={
                  ((threatSurfaces.payload as ThreatSurface[]) || []).length + 2
                }
              >
                <Typography variant="h6">{`${selectedTag.name} Current Budget Productivity`}</Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              {tableHeadings.map((heading: any, idx: number) => (
                <TableCell
                  key={`${featureName}/thead/${heading.display}`}
                  style={heading.style}
                  align={idx === 0 ? 'left' : 'center'}
                >
                  {heading.display}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {tableData.map((rowData: any, rowIndex: number) => (
              <TableRow key={`${featureName}/tbody/row/${rowIndex}`}>
                {rowData.map((cellData: any, cellIndex: number) => (
                  <TableCell
                    key={`${featureName}/tbody/row/${rowIndex}/cell/${cellIndex}`}
                    align={cellIndex === 0 ? 'left' : 'center'}
                    style={cellData.style}
                  >
                    {cellData.display}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </Paper>
  );
};

export default CurrentBudgetProductivityMatrix;
