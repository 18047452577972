import React, { useEffect, useState } from 'react';
import {
  KPIGroup as KpiGroupType,
  getKPIsAndValues,
  KPI,
} from 'app/services/api';
import { kpiHeaderTitle } from 'app/kpi/components/tables';
import Loader from 'app/common/Loader';
import BarGraph, {
  blue,
  green,
  getHighLowColours,
  customTooltip,
  getMaxCurrentTargetValue,
} from 'app/common/BarGraph';
import StripedTable from 'app/common/StripedTable';
import Section from 'app/common/Section';

const featureName =
  'dashboard/control-dashboards/investment/time-allocation-graph';

type TimeAllocationType = {
  kpiGroup: KpiGroupType;
  controlId: number;
};

const extractKpiValue = (kpi: KPI) => {
  if (!kpi || !kpi?.kpiValue || !kpi?.kpiValue.length) {
    return 0;
  }

  return Number(kpi.kpiValue[0].value);
};

const formatBarData = (currentKpis: KPI[], targetKpis: KPI[]) =>
  currentKpis.map((currentKpi: KPI, index: number) => {
    const currentValue = extractKpiValue(currentKpi);
    const targetValue = extractKpiValue(targetKpis[index]);

    const currentIsBigger = currentValue > targetValue;

    const data = {
      id: currentKpi.id,
      label: currentKpi.name,
      bar1: currentIsBigger ? targetValue : currentValue,
      bar1Colour: currentIsBigger ? green : blue,
      bar2: currentIsBigger ? currentValue : targetValue,
      bar2Colour: currentIsBigger ? blue : green,
      bar1BelongsTo: currentIsBigger ? 'Target' : 'Current',
      bar2BelongsTo: currentIsBigger ? 'Current' : 'Target',
    };

    return data;
  });

const TimeAllocation = ({
  kpiGroup,
  controlId,
}: TimeAllocationType): JSX.Element => {
  const currentGroup = (kpiGroup?.subGroups || []).find((group: KpiGroupType) =>
    group.key.includes('time-allocation')
  );
  const [loading, setLoading] = useState<boolean>(true);
  const [timeAllocData, setTimeAllocData] = useState<any | null>(null);

  useEffect(() => {
    if (!kpiGroup || !controlId || !currentGroup) {
      return;
    }

    (async () => {
      const data: KPI[][] = await Promise.all(
        (currentGroup?.subGroups || []).map((group) =>
          getKPIsAndValues({ controlId, groupKey: group.key })
        )
      );

      setTimeAllocData(data);

      setLoading(false);
    })();
  }, []);

  if (loading) {
    return <Loader />;
  }

  const barData = formatBarData(timeAllocData[0], timeAllocData[1]);
  const dataKeys = ['bar1', 'bar2'];

  return (
    <Section>
      <StripedTable>
        <StripedTable.Head>
          <StripedTable.Heading colSpan={2}>
            Time Allocation
          </StripedTable.Heading>
          <StripedTable.SubHeading colSpan={2}>
            {kpiHeaderTitle}
          </StripedTable.SubHeading>
        </StripedTable.Head>

        <StripedTable.Body>
          {barData.map((rowData, index) => {
            const isLast = index === barData.length - 1;

            return (
              <StripedTable.Row key={`${featureName}/${rowData.id}`}>
                <StripedTable.GraphLabel
                  style={{ width: '20%' }}
                  isLast={isLast}
                >
                  {rowData.label}
                </StripedTable.GraphLabel>
                <StripedTable.GraphCell isLast={isLast}>
                  <BarGraph
                    data={[rowData]}
                    keys={dataKeys}
                    colors={getHighLowColours}
                    tooltip={customTooltip}
                    axisBottom={isLast ? {} : null}
                    groupMode="grouped"
                    maxValue={getMaxCurrentTargetValue(barData)}
                  />
                </StripedTable.GraphCell>
              </StripedTable.Row>
            );
          })}
        </StripedTable.Body>
      </StripedTable>
    </Section>
  );
};

export default TimeAllocation;
