import { connect } from 'react-redux';
import {
  KPIGroup as KpiGroupType,
  KPI,
  Control,
  TagType,
} from 'app/services/api';
import { useRouteMatch } from 'react-router-dom';
import Loader from 'app/common/Loader';
import { controlsState } from 'app/common/actions/controls';
import { kpiGroupPromiseCreator } from 'app/utils/helpers';

import KPIGroupSummary from './KPIGroupSummary';
import KPISubGroupSummary from './KPISubGroupSummary';
import KPISummary from './KPISummary';

type mapStateType = {
  kpiGroups: {
    payload: KpiGroupType[];
  };
  app: {
    tag: TagType;
    control: Control;
  };
  controls: controlsState;
};

type Hint1KpiGroupType = {
  kpiGroups: KpiGroupType[];
  title: string;
  subGroupTitle: string;
  selectedTag: TagType;
  controls: controlsState;
};

const Hint1KpiGroup = ({
  title,
  subGroupTitle,
  kpiGroups,
  selectedTag,
  controls,
}: Hint1KpiGroupType) => {
  const { url } = useRouteMatch();
  const groupName = url.split('/').pop() as string;
  const kpiGroup = kpiGroups.find(
    (grp: KpiGroupType) => grp.path === groupName
  );
  const subGroups: KpiGroupType[] =
    kpiGroup && kpiGroup.subGroups ? kpiGroup.subGroups : [];
  const controlsPayload = controls.payload as Control[];
  const [loading, setLoading] = React.useState<boolean>(true);
  const [controlKpiValues, setControlKpiValues] = React.useState<
    KPI[][][] | null
  >(null);

  React.useEffect(() => {
    if (!kpiGroup) {
      return;
    }

    if (
      !controls.isLoading &&
      Array.isArray(controlsPayload) &&
      !controlKpiValues
    ) {
      setTimeout(() => {
        (async () => {
          const data: KPI[][][] = await Promise.all(
            controlsPayload.map((control) =>
              kpiGroupPromiseCreator(groupName, kpiGroups, control.id)
            )
          );

          setControlKpiValues(data);
          setLoading(false);
        })();
      }, 0);
    }
  });

  if (loading || controls.isLoading) {
    return <Loader />;
  }

  if (
    controlsPayload &&
    Array.isArray(controlsPayload) &&
    !controlsPayload.length
  ) {
    return <div />;
  }

  if (
    !kpiGroup ||
    !subGroups ||
    (Array.isArray(controlKpiValues) && !controlKpiValues.length)
  ) {
    return <div>No KPIs found</div>;
  }

  return (
    <div>
      <KPIGroupSummary
        title={title}
        kpiGroup={kpiGroup as KpiGroupType}
        controlKpiValues={controlKpiValues as KPI[][][]}
        selectedTag={selectedTag}
      />

      <KPISubGroupSummary
        title={subGroupTitle}
        subGroups={subGroups}
        controlKpiValues={controlKpiValues as KPI[][][]}
        selectedTag={selectedTag}
      />

      <KPISummary
        subGroups={subGroups}
        controlKpiValues={controlKpiValues as KPI[][][]}
        selectedTag={selectedTag}
      />
    </div>
  );
};

const mapState = ({
  kpiGroups: { payload },
  app: { tag },
  controls,
}: mapStateType) => ({
  kpiGroups: payload,
  selectedTag: tag,
  controls,
});

export default connect(mapState)(Hint1KpiGroup);
