import React from 'react';
import { KPIGroup, KPI, TagType } from 'app/services/api';
import { kpiHeaderTitle } from 'app/kpi/components/tables';
import BarGraph, { getBarColorsForValue } from 'app/common/BarGraph';
import Section from 'app/common/Section';
import StripedTable from 'app/common/StripedTable';
import { arrayHasValues } from 'app/utils/helpers';
import { truncate } from 'lodash';

const featureName = 'dashboard/tag-dashboards/common-components/kpi-summary';

type KPISummaryType = {
  subGroups: KPIGroup[];
  controlKpiValues: KPI[][][];
  selectedTag: TagType;
};

const KPISummary = ({
  subGroups,
  controlKpiValues,
  selectedTag,
}: KPISummaryType): JSX.Element => {
  const getBarData = (subGroupIndex: number) =>
    controlKpiValues.reduce(
      (kpiSum: any, nextCtrlKpiValues: any, controlIndex: number) => {
        const subGroupKpis = nextCtrlKpiValues[subGroupIndex];

        return subGroupKpis.map((nextKpi: any, kpiIndex: number) => {
          const nextKpiValue = arrayHasValues(nextKpi.kpiValue)
            ? Number(nextKpi.kpiValue[0].value)
            : 0;
          const currentKpi = kpiSum[kpiIndex]
            ? Number(kpiSum[kpiIndex].value)
            : 0;

          let value = nextKpiValue + currentKpi;

          if (controlIndex === controlKpiValues.length - 1) {
            value = (value * 20) / controlKpiValues.length;
          }

          const barColors = getBarColorsForValue(value);

          return {
            ...nextKpi,
            label: truncate(nextKpi.name, {
              length: 50,
              separator: /,? +/,
            }),
            value: Math.round(value),
            colors: barColors.background,
            labelTextColor: barColors.textColor,
          };
        });
      },
      []
    );

  return (
    <>
      {subGroups.map((subGroup: KPIGroup, subGrpIdx: number) => (
        <Section key={`${featureName}-${subGroup.key}`} padding>
          <StripedTable>
            <StripedTable.Head>
              <StripedTable.Heading colSpan={2}>
                {selectedTag.name} {subGroup.name}
              </StripedTable.Heading>
              <StripedTable.SubHeading colSpan={2}>
                {kpiHeaderTitle}
              </StripedTable.SubHeading>
            </StripedTable.Head>

            <StripedTable.Body>
              {getBarData(subGrpIdx).map(
                (barData: any, kpiIndex: number, self: any) => {
                  const isLast = kpiIndex === self.length - 1;

                  return (
                    <StripedTable.Row
                      key={`${featureName}-${subGroup.key}-${barData.key}-${kpiIndex}`}
                    >
                      <StripedTable.GraphLabel
                        style={{ width: '20%' }}
                        isLast={isLast}
                      >
                        {barData.name}
                      </StripedTable.GraphLabel>
                      <StripedTable.GraphCell isLast={isLast}>
                        <BarGraph
                          data={[barData]}
                          colors={barData.colors}
                          labelTextColor={barData.labelTextColor}
                          axisBottom={isLast ? {} : null}
                        />
                      </StripedTable.GraphCell>
                    </StripedTable.Row>
                  );
                }
              )}
            </StripedTable.Body>
          </StripedTable>
        </Section>
      ))}
    </>
  );
};

export default KPISummary;
