import {
  KPIGroup as KpiGroupType,
  getKPIsAndValues,
  KPI,
  ThreatSurface as ThreatSurfaceType,
} from 'app/services/api';
import Loader from 'app/common/Loader';
import BarGraph, {
  blue,
  green,
  customTooltip,
  getHighLowColours,
  getMaxCurrentTargetValue,
} from 'app/common/BarGraph';
import StripedTable from 'app/common/StripedTable';
import Section from 'app/common/Section';

import { commonDashboardTypes } from './index';

const featureName =
  'dashboard/control-dashboards/investment/budget-allocation-graph';

type BudgetAllocationType = commonDashboardTypes & {
  kpiGroup: KpiGroupType;
};

const extractKpiValue = (surfaceId: number, kpi: KPI) => {
  if (!kpi || !kpi?.kpiValue || !kpi?.kpiValue.length) {
    return 0;
  }

  const kpiValue = kpi.kpiValue.find((kv) => kv.surfaceId === surfaceId);

  if (!kpiValue) {
    return 0;
  }

  return Number(kpiValue.value);
};

const formatBarData = (
  threatSurfaces: ThreatSurfaceType[],
  currentKpi: KPI,
  targetKpi: KPI
) => {
  if (Array.isArray(threatSurfaces)) {
    return threatSurfaces.map((tSurface: ThreatSurfaceType) => {
      const currentValue = extractKpiValue(tSurface.id, currentKpi);
      const targetValue = extractKpiValue(tSurface.id, targetKpi);

      const currentIsBigger = currentValue > targetValue;

      const data = {
        id: tSurface.id,
        label: tSurface.name,
        bar1: currentIsBigger ? targetValue : currentValue,
        bar1Colour: currentIsBigger ? green : blue,
        bar2: currentIsBigger ? currentValue : targetValue,
        bar2Colour: currentIsBigger ? blue : green,
        bar1BelongsTo: currentIsBigger ? 'Target' : 'Current',
        bar2BelongsTo: currentIsBigger ? 'Current' : 'Target',
      };

      return data;
    });
  }
};

const BudgetAllocation = ({
  kpiGroup,
  getThreatSurfaces,
  threatSurfaces,
  controlId,
}: BudgetAllocationType): JSX.Element => {
  const currentGroup = (kpiGroup?.subGroups || []).find(
    (group: KpiGroupType) =>
      group.key.includes('budget-allocation') && group.key.includes('surfaces')
  );
  const [loading, setLoading] = React.useState<boolean>(true);
  const [budgetAllocData, setBudgetAllocData] = React.useState<any | null>(
    null
  );

  React.useEffect(() => {
    if (!kpiGroup || !controlId || !currentGroup) {
      return;
    }

    getThreatSurfaces();

    (async () => {
      const data: KPI[] = await getKPIsAndValues({
        controlId,
        groupKey: currentGroup.key,
      });

      setBudgetAllocData(data);

      setLoading(false);
    })();
  }, []);

  if (loading || threatSurfaces.isLoading) {
    return <Loader />;
  }

  const barData = formatBarData(
    threatSurfaces.payload,
    budgetAllocData[0],
    budgetAllocData[1]
  );
  const dataKeys = ['bar1', 'bar2'];

  return (
    <Section>
      <StripedTable>
        <StripedTable.Head>
          <StripedTable.Heading colSpan={2}>
            Budget Allocation
          </StripedTable.Heading>
          <StripedTable.SubHeading colSpan={2}>
            Threat Surface
          </StripedTable.SubHeading>
        </StripedTable.Head>

        <StripedTable.Body>
          {barData?.map((rowData, index) => {
            const isLast = index === barData.length - 1;

            return (
              <StripedTable.Row key={`${featureName}/${rowData.id}`}>
                <StripedTable.GraphLabel
                  style={{ width: '15%' }}
                  isLast={isLast}
                >
                  {rowData.label}
                </StripedTable.GraphLabel>
                <StripedTable.GraphCell isLast={isLast}>
                  <BarGraph
                    data={[rowData]}
                    keys={dataKeys}
                    colors={getHighLowColours}
                    tooltip={customTooltip}
                    axisBottom={isLast ? {} : null}
                    groupMode="grouped"
                    maxValue={getMaxCurrentTargetValue(barData)}
                  />
                </StripedTable.GraphCell>
              </StripedTable.Row>
            );
          })}
        </StripedTable.Body>
      </StripedTable>
    </Section>
  );
};

export default BudgetAllocation;
