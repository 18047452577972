import SummaryDashboard from 'app/dashboard/ControlDashboards/SummaryDashboard';
import SecurityDashboard from 'app/dashboard/ControlDashboards/SecurityDashboard';
import ManagementDashboard from 'app/dashboard/ControlDashboards/ManagementDashboard';
import ResourcesDashboard from 'app/dashboard/ControlDashboards/ResourcesDashboard';
import IntegrationDashboard from 'app/dashboard/ControlDashboards/IntegrationDashboard';
import InvestmentDashboard from 'app/dashboard/ControlDashboards/InvestmentDashboard';

export default {
  Summary: SummaryDashboard,
  Security: SecurityDashboard,
  Management: ManagementDashboard,
  Resources: ResourcesDashboard,
  Integration: IntegrationDashboard,
  Investment: InvestmentDashboard,
};
