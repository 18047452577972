import React, { FunctionComponent } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import {
  AppBar,
  Box,
  Toolbar,
  Avatar,
  Divider,
  IconButton,
  Popover,
  Button,
  MenuItem,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import HelpOutlineRounded from '@material-ui/icons/HelpOutlineRounded';
import { TagType, Control } from 'app/services/api';
import PATHS from 'app/utils/paths';
import NavButton from 'app/common/NavButton';
import logo from 'assets/images/logo.png';
import {
  UserDataType,
  oauthChangePassword,
  oauthLogout,
} from 'app/services/auth';
import { displayHelp } from 'app/services/help';

interface NavBarProps {
  currentUser: UserDataType;
  selectedTag: TagType;
  selectedControl: Control;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    toolbarRoot: {
      height: 80,
    },
    logo: {
      height: 45,
      display: 'inline-block',
      marginRight: theme.spacing(8),
    },
    navButtonLink: {
      textDecoration: 'none',
    },
    navButtonLabel: {
      fontSize: theme.typography.pxToRem(16),
    },
    userContent: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
    },
  })
);

const NavBar: FunctionComponent<NavBarProps> = ({
  currentUser,
  selectedTag,
  selectedControl,
}: NavBarProps) => {
  const classes = useStyles();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);

  const getDestination = (baseUrl: string) => {
    let destination = baseUrl.slice();

    if (selectedTag) {
      destination += `${PATHS.GROUPS}/${selectedTag.id}`;
    }

    if (selectedControl) {
      destination += `${PATHS.CONTROLS}/${selectedControl.id}`;
    }

    return destination;
  };

  if (!currentUser) {
    return null;
  }

  const openPopover = () =>
    setAnchorEl(document.getElementById('nav-bar/right-menu-box'));

  const closePopover = () => setAnchorEl(null);

  const onClickChangePassword = () => {
    closePopover();
    oauthChangePassword();
  };

  const onClickHelpButton = async () => {
    const el = document.getElementById('help-hook');
    if (el) {
      displayHelp('urn:help:context::resource:index.html', el);
    }
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" color="inherit">
        <Toolbar classes={{ root: classes.toolbarRoot }}>
          <Box
            width={1}
            height={1}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box display="flex" height={1} alignItems="center">
              <Link to="/">
                <img src={logo} className={classes.logo} alt="pharos logo" />
              </Link>
              <Box height={1}>
                <Link to={PATHS.WORKBENCH} className={classes.navButtonLink}>
                  <NavButton
                    classes={{ label: classes.navButtonLabel }}
                    active={/^\/workbench/.test(location.pathname)}
                  >
                    Workbench
                  </NavButton>
                </Link>
                <Link
                  to={getDestination(PATHS.KPI)}
                  className={classes.navButtonLink}
                >
                  <NavButton
                    classes={{ label: classes.navButtonLabel }}
                    active={/^\/kpi/.test(location.pathname)}
                  >
                    KPI Update
                  </NavButton>
                </Link>
                <Link
                  to={getDestination(PATHS.DASHBOARD)}
                  className={classes.navButtonLink}
                >
                  <NavButton
                    classes={{ label: classes.navButtonLabel }}
                    active={/^\/dashboard/.test(location.pathname)}
                  >
                    Dashboard
                  </NavButton>
                </Link>
              </Box>
            </Box>
            <Box display="flex" alignItems="center" id="nav-bar/right-menu-box">
              <Box mx={1}>
                <IconButton onClick={onClickHelpButton}>
                  <HelpOutlineRounded />
                </IconButton>
              </Box>

              <Divider orientation="vertical" flexItem />

              <div className={classes.userContent} onClick={openPopover}>
                <Box
                  mx={1}
                  component="span"
                  fontWeight="600"
                  color="text.secondary"
                >
                  {currentUser.name}
                </Box>
              </div>
            </Box>

            <Popover
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={closePopover}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <div style={{ padding: '.75rem' }}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: '0 .5rem .25rem 0',
                  }}
                >
                  <Avatar
                    style={{ marginRight: '.5rem' }}
                    src={currentUser.userInfo?.picture}
                    alt={currentUser.name}
                  />
                  {currentUser.name}
                  <br />
                  {currentUser.company.name}
                </div>

                <hr />

                <MenuItem
                  disableGutters={true}
                  style={{ width: '100%' }}
                  onClick={onClickChangePassword}
                >
                  Change password...
                </MenuItem>

                <hr />

                <Button style={{ width: '100%' }} onClick={oauthLogout}>
                  Sign out
                </Button>
              </div>
            </Popover>
          </Box>
        </Toolbar>
      </AppBar>
      <div id="help-hook"></div>
    </div>
  );
};

type mapStateType = {
  app: {
    tag: TagType;
    control: Control;
  };
};

const mapState = ({ app: { tag, control } }: mapStateType) => ({
  selectedTag: tag,
  selectedControl: control,
});

export default connect(mapState)(NavBar);
