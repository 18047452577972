import {
  getThreatSurfaces,
  ThreatSurface as ThreatSurfaceType,
} from 'app/services/api';

const featureName = 'common/actions';

export type threatSurfaceState = {
  isLoading: boolean;
  hasError: Error | null;
  payload: Promise<ThreatSurfaceType[]> | ThreatSurfaceType[] | null;
};

interface appStore {
  threatSurfaces: threatSurfaceState;
}

type dispatchType = {
  type: string;
  payload: Promise<ThreatSurfaceType[]> | ThreatSurfaceType[] | null;
};

export const actionTypes = {
  fetchThreatSurfaces: `${featureName}/FETCH_THREAT_SURFACES`,
};

export const fetchThreatSurfaces = (forceFetch = false) => (
  dispatch: React.Dispatch<dispatchType>,
  getState: () => appStore
): void => {
  const { payload: existingPayload } = getState().threatSurfaces;

  const hasThreatSurfaces =
    existingPayload && Array.isArray(existingPayload) && existingPayload.length;

  dispatch({
    type: actionTypes.fetchThreatSurfaces,
    payload:
      !forceFetch && hasThreatSurfaces ? existingPayload : getThreatSurfaces(),
  });
};
