import { KPI } from 'app/services/api';
import Loader from 'app/common/Loader';
import BarGraph, {
  green,
  orange,
  dollarFormatting,
  getHighLowColours,
  findMaxProductivityValue,
} from 'app/common/BarGraph';
import { getSurfaceBudget } from 'app/utils/graph-math';
import Section from 'app/common/Section';
import StripedTable from 'app/common/StripedTable';
import { commonDashboardTypes } from './index';

const featureName =
  'dashboard/control-dashboards/investment/budget-productivity-by-surface';

type BudgetProductivityBySurfaceType = commonDashboardTypes & {
  secOpsProductivity: number;
  totalBudget: KPI[];
  currentBudget: KPI;
};

const BudgetProductivityBySurface = ({
  getThreatSurfaces,
  threatSurfaces,
  secOpsProductivity,
  totalBudget,
  currentBudget,
  controlId, // not used
}: BudgetProductivityBySurfaceType) => {
  React.useEffect(() => {
    getThreatSurfaces();
  }, []);

  if (threatSurfaces.isLoading) {
    return <Loader />;
  }

  const barData = threatSurfaces.payload.map((tSurface) => {
    const surfaceBudget = getSurfaceBudget(
      totalBudget,
      currentBudget?.kpiValue.find(
        (kpiValue: any) => kpiValue.surfaceId === tSurface.id
      )
    );

    const highProductivity = Math.round(
      surfaceBudget * (secOpsProductivity / 100)
    );
    const lowProductivity = Math.round(surfaceBudget - highProductivity);

    return {
      id: tSurface.id,
      label: tSurface.name,
      highProductivity,
      highProductivityColour: green,
      lowProductivity,
      lowProductivityColour: orange,
    };
  });
  const dataKeys = ['highProductivity', 'lowProductivity'];

  return (
    <Section>
      <StripedTable>
        <StripedTable.Head>
          <StripedTable.Heading colSpan={2}>
            Budget Productivity by Threat Surface
          </StripedTable.Heading>
          <StripedTable.SubHeading colSpan={2}>
            Threat Surface
          </StripedTable.SubHeading>
        </StripedTable.Head>

        <StripedTable.Body>
          {barData.map((rowData, index) => {
            const isLast = index === barData.length - 1;

            return (
              <StripedTable.Row key={`${featureName}/${rowData.id}`}>
                <StripedTable.GraphLabel
                  style={{ width: '15%' }}
                  isLast={isLast}
                >
                  {rowData.label}
                </StripedTable.GraphLabel>
                <StripedTable.GraphCell isLast={isLast}>
                  <BarGraph
                    data={[rowData]}
                    keys={dataKeys}
                    colors={getHighLowColours}
                    axisBottom={isLast ? { format: dollarFormatting } : null}
                    maxValue={findMaxProductivityValue(barData)}
                    labelFormat={dollarFormatting}
                  />
                </StripedTable.GraphCell>
              </StripedTable.Row>
            );
          })}
        </StripedTable.Body>
      </StripedTable>
    </Section>
  );
};

export default BudgetProductivityBySurface;
