import React, { FunctionComponent } from 'react';
import { Box, BoxProps, Paper, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
  Control,
  ControlCapability,
  ThreatLevel,
  ThreatSurface,
} from 'app/services/api';
import { KPIData } from 'app/kpi/types';
import { KPIValueInputTable } from 'app/kpi/components/tables/ValueInput';
import { KPIGroupDisplay } from 'app/kpi/types/KPIGroupDisplay';
import { buildValueInputTableConfig } from 'app/kpi/components/tables/index';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      marginBottom: theme.spacing(1),
      fontSize: 14,
      fontWeight: 400,
      textTransform: 'uppercase',
      color: theme.palette.text.secondary,
    },
    textField: {
      backgroundColor: '#fff',
      '& input': {
        textAlign: 'right',
      },
    },
  })
);

interface KPIThreatLevelTableProps extends BoxProps {
  index: number;
  control: Control;
  group: KPIGroupDisplay;
  capability: ControlCapability;
  level: ThreatLevel;
  onKPIChange: (kpi: KPIData<any>, value: string) => void;
  errors: KPIData<any>[];
  surfaces: ThreatSurface[];
}

export const KPIThreatLevelTable: FunctionComponent<KPIThreatLevelTableProps> = ({
  index,
  control,
  group,
  capability,
  level,
  onKPIChange,
  errors,
  surfaces,
  ...rest
}: KPIThreatLevelTableProps) => {
  const classes = useStyles();

  if (!capability) {
    return null;
  }

  const { columns, items } = buildValueInputTableConfig(
    group,
    control,
    [capability],
    surfaces,
    null,
    (kpi, i) => ({
      text: kpi.text,
      helpText: '',
    })
  );

  return (
    <Box {...rest}>
      <Typography variant="h6" component="h3" className={classes.header}>
        Level {index + 1} - {level.name}
      </Typography>
      <Box component={Paper}>
        <KPIValueInputTable
          text="Control Capability"
          columns={columns}
          items={items}
          onKPIChange={onKPIChange}
          errors={errors}
        />
      </Box>
    </Box>
  );
};

export default KPIThreatLevelTable;
