import { 
    KPI,
    KPIValue,
    ThreatSurface as ThreatSurfaceType,
    ThreatLevel as ThreatLevelType, 
} from 'app/services/api';
import Loader from 'common/Loader';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {
  tableContainerWithMarginStyles,
  headerStyles,
  getColWidth,
  firstColStyles,
  lastColStyles
} from 'app/kpi/components/tables';
import { getSurfaceBudget } from 'utils/graph-math';
import { threatLevelTypes, surfaceTypes } from './index';
import MuiLightBlue from '@material-ui/core/colors/lightBlue';
import { getCurrentUser } from 'app/services/auth';

type CurrentInvestmentType = threatLevelTypes & surfaceTypes & {
    controlId: number;
    secOpsProductivity: number;
    budgetSplit: KPI[];
    totalBudget: KPI[] | null;
    currentBudget: KPI | null;
  };

const CurrentInvestmentMatrix = ({
  budgetSplit,
  threatLevels,
  threatSurfaces,
  secOpsProductivity,
  currentBudget,
  controlId,
  totalBudget,
}: CurrentInvestmentType) => {
  if (threatLevels.isLoading || threatSurfaces.isLoading) {
    return <Loader />;
  }

  if (!totalBudget || !currentBudget) {
    return <div>No Budgets found</div>;
  }

  const locale = getCurrentUser()?.currency.locale;
  const currency = getCurrentUser()?.currency;

  const surfaceBudgets = threatSurfaces.payload.map((tSurface: ThreatSurfaceType) => {
    const surfaceBudget = getSurfaceBudget(
      totalBudget,
      currentBudget?.kpiValue.find(
        (kpiValue: KPIValue) => kpiValue.surfaceId === tSurface.id
      )
    );

    return {
      id: tSurface.id,
      label: tSurface.name,
      budget: surfaceBudget,
    };
  });

  // starting from threat level 1-6
  const data = threatLevels.payload.map((tLevel: ThreatLevelType) => {
    const rowData: any = [];
    // surfaces level 1 > 4
    const budgetSplitSurfaces = budgetSplit[0].kpiValue.filter(
      (kpi: KPIValue) => {
        if (kpi.levelId === tLevel.id) {
          return kpi;
        }
      }
    );

    threatSurfaces.payload.forEach(threatSurface => {
      const surface = surfaceBudgets.find((surface) => surface.id === threatSurface.id);
      const budgetSplitSurface = budgetSplitSurfaces.find(splitSurface => splitSurface.surfaceId === threatSurface.id);
      if (surface && budgetSplitSurface) {
        rowData.push(Math.round((surface?.budget * (Number(budgetSplitSurface.value) / 100))));
      } else {
        rowData.push(0);
      }
    });

    const rowSum = rowData.reduce((acc: number, curr: number) => {
      return acc + curr;
    }, 0);

    rowData.push(rowSum);

    rowData.splice(0, 0, `${tLevel.severity}. ${tLevel.name}`);
    return rowData;
  });

  const numberOfThreatSurfaces = threatSurfaces.payload.length;
  const colWidth = getColWidth(numberOfThreatSurfaces);

  return (
    <Paper elevation={3} style={{ padding: '1.5rem', marginBottom: '2rem' }}>
      <div style={tableContainerWithMarginStyles}>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow style={headerStyles}>
                <TableCell colSpan={threatSurfaces.payload.length + 2}>
                  <Typography variant="h6">Current Investment</Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={firstColStyles}>Threat Level</TableCell>
                {Array.isArray(threatSurfaces.payload) && threatSurfaces.payload.map((surface, idx) => {
                  return (<TableCell style={{ width: colWidth }} align="center" key={`${surface.name}-${idx}`}>{surface.name}</TableCell>);
                })
                }
                <TableCell style={lastColStyles} key='row-sum' align="center">Row Sum</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.isArray(data) && data.map((row) => (
                <TableRow key={row[0]}>
                  {row.map((col: any, idx: number) => {
                    let style = {};
                    if (idx === row.length - 1) {
                      style = {
                        backgroundColor: MuiLightBlue[900],
                        color: 'white'
                      };
                    } else if (idx === 0) {
                      style = {
                        whiteSpace: 'nowrap'
                      }
                    }

                    let displayValue = col;
                    if (typeof col === 'number') {
                      displayValue = col.toLocaleString(
                        locale,
                        currency
                      );
                    }

                    return (
                      <TableCell key={`${row[0]}-${idx}`} style={style} component="th" scope="row" align={idx > 0 ? "center" : "left"}>
                        {displayValue}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </Paper>
  );
};

export default CurrentInvestmentMatrix;
