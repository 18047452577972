import React from 'react';
import ReactDOM from 'react-dom';
import fetchJson from 'fetch.json';
import URLParse from 'url-parse';
import { getHelpUrl } from './api';

export interface HelpParams {
  context: string;
  resource: string;
}

export const getHelpURN = (params: string | HelpParams): string => {
  let urn: string;
  if (typeof params === 'string') {
    const str = URLParse(params, {}).pathname;
    const pos = str.lastIndexOf('/');
    if (pos === -1) {
      urn = `urn:help:context::resource:${str}`;
    } else {
      urn = `urn:help:context:${str.slice(0, pos)}:resource:${str.slice(
        pos + 1
      )}`;
    }
  } else {
    urn = `urn:help:context:${params.context}:resource:${params.resource}`;
  }
  return urn;
};

const getHelpPath = (helpUrn: string) => {
  const parts = helpUrn.split(':');
  if (
    parts.length == 6 &&
    parts[0] === 'urn' &&
    parts[1] === 'help' &&
    parts[2] === 'context' &&
    parts[4] === 'resource'
  ) {
    return `${parts[3]}/${parts[5]}`;
  }
};

export const getHelpFile = (urn: string): Promise<any> | undefined => {
  const helpPath = getHelpPath(urn);
  if (helpPath) {
    const helpUrl = getHelpUrl(helpPath);
    return httpRequest(helpUrl, 'GET', null, fetchJson.headers());
  }
};

export const displayHelp = async (helpUrn: string, el: Element) => {
  const helpFile = await getHelpFile(helpUrn);
  if (helpFile) {
    const hw = (
      <RenderInHelpWindow
        html={helpFile}
        onClose={() => {
          ReactDOM.unmountComponentAtNode(el);
        }}
      ></RenderInHelpWindow>
    );
    ReactDOM.render(hw, el);
  }
};

export const RenderInHelpWindow = (props: any) => {
  const [html, setHtml] = React.useState<Document | null>(null);
  const [container, setContainer] = React.useState<Element | null>(null);

  const newWindow = React.useRef<Window | null>(null);
  if (newWindow.current) {
    newWindow.current.focus();
  }

  React.useEffect(() => {
    // Parse and stash HTML
    setHtml(new DOMParser().parseFromString(props.html, 'text/html'));
    // Create container element on client-side
    setContainer(document.createElement('div'));
  }, [props.html]);

  React.useEffect(() => {
    // When container is ready
    if (html && container) {
      // Create window
      if (!newWindow.current) {
        newWindow.current = window.open(
          '',
          '',
          'width=600,height=400,left=200,top=200'
        );
      }
      if (newWindow.current) {
        // Copy HTML
        newWindow.current.document.head.innerHTML = html.head.innerHTML;
        newWindow.current.document.body.innerHTML = html.body.innerHTML;
        // Append container
        newWindow.current.document.body.appendChild(container);
      }
      // Save reference to window for cleanup
      const curWindow = newWindow.current;
      const timer = setInterval(() => {
        if (curWindow?.closed) {
          clearInterval(timer);
          if (props.onClose) {
            props.onClose();
          }
        }
      }, 1000);
      return () => {
        curWindow?.close();
      };
    }
  }, [html, container]);

  return html && container && ReactDOM.createPortal(props.children, container);
};

export const httpRequest = (
  url: string,
  method: string,
  payload: string | null,
  headers: string | null
): Promise<any> => {
  const config: RequestInit = {
    method,
    headers: {
      'Content-Type': 'text/html',
    },
  };
  if (method.toLowerCase() === 'post' && payload && payload.length > 0) {
    // Caution: JSON.stringify excludes keys/values when the value is null
    config.body = JSON.stringify(payload);
  }
  if (
    headers &&
    typeof headers === 'object' &&
    Object.keys(headers).length > 0
  ) {
    config.headers = headers;
  }
  return fetch(url, config)
    .then((response) => {
      if (response.ok) {
        let data;
        const contentType = response.headers.get('Content-Type') || '';
        if (contentType.startsWith('application/json')) {
          data = response.json();
        } else if (contentType.startsWith('text/html')) {
          data = response.text();
        } else {
          data = response;
        }
        return data;
      }
      return Promise.reject(response);
    })
    .catch((reason) => {
      return Promise.reject(reason);
    });
};

export default httpRequest;
