import { TagType } from 'app/services/api';
import BarGraph, { getBarColorsForValue } from 'app/common/BarGraph';
import { kpiHeaderTitle } from 'app/kpi/components/tables';
import Section from 'app/common/Section';
import StripedTable from 'app/common/StripedTable';

import { ControlKpiGroupDataType } from './index';

const featureName = 'dashboard/tag-dashboards/summary/control-productivity';

type ControlProductivityType = {
  selectedTag: TagType;
  controlKpiGroupData: ControlKpiGroupDataType[];
};

const ControlProductivity = ({
  controlKpiGroupData,
  selectedTag,
}: ControlProductivityType): JSX.Element => {
  const barData = controlKpiGroupData.map(data => ({
    id: data.control.id,
    label: data.control.name,
    value: Math.round(data.secOpsProductivity),
    barColors: getBarColorsForValue(data.secOpsProductivity),
  }));

  return (
    <Section>
      <StripedTable>
        <StripedTable.Head>
          <StripedTable.Heading colSpan={2}>{selectedTag.name} Control Productivity</StripedTable.Heading>
          <StripedTable.SubHeading colSpan={2} style={{ width: '20%' }}>{kpiHeaderTitle}</StripedTable.SubHeading>
        </StripedTable.Head>

        <StripedTable.Body>
          {barData.map((rowData, index) => {
            const isLast = index === barData.length - 1;

            return (
              <StripedTable.Row key={`${featureName}/${rowData.id}`}>
                <StripedTable.GraphLabel isLast={isLast} style={{ width: '20%' }}>{rowData.label}</StripedTable.GraphLabel>
                <StripedTable.GraphCell isLast={isLast}>
                  <BarGraph
                    data={[rowData]}
                    colors={rowData.barColors.background}
                    labelTextColor={rowData.barColors.textColor}
                    axisBottom={isLast ? {} : null}
                  />
                </StripedTable.GraphCell>
              </StripedTable.Row>
            );
          })}
        </StripedTable.Body>
      </StripedTable>
    </Section>
  );
};

export default ControlProductivity;
