const HOME_PATH = '/';
const SIGNIN_PATH = '/signin';
const DASHBOARD_PATH = '/dashboard';
const KPI_PATH = '/kpi';
const WORKBENCH_PATH = '/workbench';
const AUTHENTICATED_PATH = '/authenticated';
const GROUPS_PATH = '/groups';
const ELEMENTS_PATH = '/elements';
const CONTROLS_PATH = '/controls';
const SUMMARY_PATH = '/summary';
const SECURITY_COVERAGE_PATH = '/security-coverage';
const MANAGEMENT_PATH = '/management';
const RESOURCES_PATH = '/resources';
const INTEGRATION_PATH = '/integration';
const INVESTMENT_PATH = '/investment';

// paths for application apis
const TAGS_PATH = '/tags';
const TAG_PATH = '/tag';
const KPIS_PATH = '/kpis';
const KPI_VALUES_PATH = '/kpi-values';
const KPI_CONTEXT_PATH = '/kpi-context';
const KPI_METADATA_PATH = '/kpi-metadata';
const CAPABILITIES_PATH = '/capabilities';
const THREAT_LEVELS_PATH = '/threat-levels';
const THREAT_SURFACES_PATH = '/threat-surfaces';
const KPI_GROUPS_PATH = '/kpi-groups';
const CONTROL_PATH = '/control';
const TOKEN_PATH = '/token';
const COMPANIES_PATH = '/companies';
const COMPANY_PATH = '/company';
const USER_PATH = '/user';
const LOGIN_PATH = '/login';
const LOGOUT_PATH = '/logout';
const PASSWORD_PATH = '/password';

export default {
  HOME: HOME_PATH,
  SIGNIN: SIGNIN_PATH,
  DASHBOARD: DASHBOARD_PATH,
  KPI: KPI_PATH,
  WORKBENCH: WORKBENCH_PATH,
  AUTHENTICATED: AUTHENTICATED_PATH,
  GROUPS: GROUPS_PATH,
  ELEMENTS: ELEMENTS_PATH,
  CONTROLS: CONTROLS_PATH,
  SUMMARY: SUMMARY_PATH,
  SECURITY_COVERAGE: SECURITY_COVERAGE_PATH,
  MANAGEMENT: MANAGEMENT_PATH,
  RESOURCES: RESOURCES_PATH,
  INTEGRATION: INTEGRATION_PATH,
  INVESTMENT: INVESTMENT_PATH,

  API: {
    TAGS: TAGS_PATH,
    TAG: TAG_PATH,
    KPIS: KPIS_PATH,
    KPI: KPI_PATH,
    KPI_VALUES: KPI_VALUES_PATH,
    KPI_CONTEXT: KPI_CONTEXT_PATH,
    KPI_METADATA: KPI_METADATA_PATH,
    CAPABILITIES: CAPABILITIES_PATH,
    THREAT_LEVELS: THREAT_LEVELS_PATH,
    THREAT_SURFACES: THREAT_SURFACES_PATH,
    KPI_GROUPS: KPI_GROUPS_PATH,
    CONTROLS: CONTROLS_PATH,
    CONTROL: CONTROL_PATH,
    TOKEN: TOKEN_PATH,
    COMPANIES: COMPANIES_PATH,
    COMPANY: COMPANY_PATH,
    USER: USER_PATH,
    LOGIN: LOGIN_PATH,
    LOGOUT: LOGOUT_PATH,
    PASSWORD: PASSWORD_PATH,
  },
};
