import { threatSurfaceState } from 'app/common/actions/threat-surfaces';
import { threatLevelState } from 'app/common/actions/threat-levels';
import { KPI, TagType, ThreatLevel, ThreatSurface } from 'app/services/api';
import {
  getLevelEstimatedPeopleQuantity,
  getSurfaceTargetBudget,
} from 'app/utils/graph-math';
import MuiLightBlue from '@material-ui/core/colors/lightBlue';
import {
  tableContainerWithMarginStyles,
  headerStyles,
  getColWidth,
  firstColStyles,
  lastColStyles,
} from 'app/kpi/components/tables';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import { ControlKpiGroupDataType } from '.';

const featureName = 'dashboard/tag-dashboards/summary/people-skill-matrix';

type PeopleSkillMatrixType = {
  threatSurfaces: threatSurfaceState;
  threatLevels: threatLevelState;
  selectedTag: TagType;
  budgetEstimators: KPI[][];
  targetBudgets: KPI[];
  controlKpiGroupData: ControlKpiGroupDataType[];
};

const PeopleSkillMatrix = ({
  budgetEstimators,
  targetBudgets,
  threatSurfaces,
  threatLevels,
  selectedTag,
  controlKpiGroupData,
}: PeopleSkillMatrixType): JSX.Element => {
  let tableHeadings = [
    {
      display: 'Threat Level',
      style: firstColStyles,
    },
  ];

  const numberOfThreatSurfaces = (threatSurfaces.payload as ThreatSurface[])
    .length;
  const colWidth = getColWidth(numberOfThreatSurfaces);

  const tableData = ((threatLevels.payload as ThreatLevel[]) || []).map(
    (tLevel: ThreatLevel, tLevelIndex: number) => {
      let rowData: any = [`${tLevel.severity}. ${tLevel.name}`];

      rowData = rowData.concat(
        ((threatSurfaces.payload as ThreatSurface[]) || []).map(
          (tSurface: ThreatSurface) => {
            tableHeadings = !tLevelIndex
              ? tableHeadings.concat({
                  display: tSurface.name,
                  style: { width: colWidth },
                })
              : tableHeadings;

            const peopleSum = controlKpiGroupData.reduce(
              (results, control, index) => {
                const levelEstimatedPeopleQuantity = getLevelEstimatedPeopleQuantity(
                  tLevel,
                  budgetEstimators[index]
                );
                const surfaceTargetBudget = getSurfaceTargetBudget(
                  tSurface,
                  targetBudgets[index]
                );

                return (
                  results +
                  (levelEstimatedPeopleQuantity * surfaceTargetBudget) / 100
                );
              },
              0
            );

            return peopleSum;
          }
        )
      );

      const rowSum = rowData
        .slice(1)
        .reduce((prev: number, next: number) => prev + next, 0);
      rowData = rowData.concat(rowSum);

      rowData = rowData.map((data: string | number, index: number) => {
        let style = {};
        let display = data;

        if (data.constructor === Number) {
          display = data.toFixed(2);
        }

        if (index === rowData.length - 1) {
          style = {
            backgroundColor: MuiLightBlue[900],
            color: 'white',
          };
        }

        return { display, style };
      });

      return rowData;
    }
  );
  tableHeadings = tableHeadings.concat({
    display: 'Row Sum',
    style: lastColStyles,
  });

  return (
    <Paper
      elevation={3}
      style={{ padding: '1.5rem', marginTop: '2rem', marginBottom: '2rem' }}
    >
      <div style={tableContainerWithMarginStyles}>
        <Table size="small">
          <TableHead>
            <TableRow style={headerStyles}>
              <TableCell
                colSpan={
                  ((threatSurfaces.payload as ThreatSurface[]) || []).length + 2
                }
              >
                <Typography variant="h6">{`${selectedTag.name} Estimated People/Skills Requirement for Complete Coverage`}</Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              {tableHeadings.map((heading, idx) => (
                <TableCell
                  align={idx ? 'center' : 'left'}
                  key={`${featureName}/thead/${heading.display}`}
                  style={heading.style}
                >
                  {heading.display}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {tableData.map((rowData, rowIndex) => (
              <TableRow key={`${featureName}/tbody/row/${rowIndex}`}>
                {rowData.map((cellData: any, cellIndex: number) => (
                  <TableCell
                    key={`${featureName}/tbody/row/${rowIndex}/cell/${cellIndex}`}
                    style={cellData.style}
                    align={cellIndex ? 'center' : 'left'}
                  >
                    {cellData.display}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </Paper>
  );
};

export default PeopleSkillMatrix;
