import React, { FunctionComponent } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import { Provider } from 'react-redux';
import PATHS from 'app/utils/paths';

import NavBar from 'app/common/NavBar';
import SignIn from 'app/login/SignIn';
import Authenticated from 'app/login/Authenticated';
import Dashboard from 'app/dashboard';
import Workbench from 'app/workbench/Workbench';
import { UserDataType, isAuthenticated, withAuth } from 'app/services/auth';
import { PublicRoute, PrivateRoute } from 'app/utils/routes';
import { theme } from 'app/theme';
import NewKPI from 'app/kpi';

import store from './storeConfig';
interface AppProps {
  loggedIn: boolean;
  currentUser: UserDataType;
}

const App: FunctionComponent<AppProps> = ({
  loggedIn,
  currentUser,
}: AppProps) => {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <CssBaseline />

        <Router>
          {loggedIn && <NavBar currentUser={currentUser} />}
          <Switch>
            <PublicRoute path={PATHS.SIGNIN}>
              <SignIn />
            </PublicRoute>
            <PrivateRoute path={PATHS.DASHBOARD}>
              <Dashboard />
            </PrivateRoute>

            <PrivateRoute path={PATHS.KPI}>
              <NewKPI />
            </PrivateRoute>
            <PrivateRoute path={PATHS.WORKBENCH}>
              <Workbench />
            </PrivateRoute>
            <Route exact path={PATHS.AUTHENTICATED}>
              <Authenticated />
            </Route>
            <Route exact path={PATHS.HOME}>
              <Redirect
                to={isAuthenticated() ? PATHS.DASHBOARD : PATHS.SIGNIN}
              />
            </Route>
          </Switch>
        </Router>
      </ThemeProvider>
    </Provider>
  );
};

export default withAuth(App);
