import BarGraph, { green, orange, dollarFormatting, getHighLowColours, findMaxProductivityValue } from 'app/common/BarGraph';
import { InvestmentDataType } from './index';
import { getBudgetAverage } from 'app/utils/graph-math';
import { kpiHeaderTitle } from 'app/kpi/components/tables';
import { TagType } from 'app/services/api';
import Section from 'app/common/Section';
import StripedTable from 'app/common/StripedTable';

type BudgetProductivityType = {
  investmentData: InvestmentDataType[];
  selectedTag: TagType;
};

const BudgetProductivity = ({
  investmentData,
  selectedTag,
}: BudgetProductivityType) => {
  const barData = investmentData.reduce(
    (result, next, index) => {
      const newResult: any = result.slice();
      const nextBudgetAverage = getBudgetAverage(next.totalBudget);
      const nextHighProductivity = nextBudgetAverage * (next.secOpsProductivity / 100);
      const nextLowProductivity = nextBudgetAverage - nextHighProductivity;

      newResult[0] = {
        id: 1,
        label: 'Budget Productivity',
        highProductivity: !newResult[0] ? nextHighProductivity : newResult[0].highProductivity + nextHighProductivity,
        highProductivityColour: green,
        lowProductivity: !newResult[0] ? nextLowProductivity : newResult[0].lowProductivity + nextLowProductivity,
        lowProductivityColour: orange,
      };

      if (index === investmentData.length - 1) {
        newResult[0].highProductivity = Math.round(newResult[0].highProductivity);
        newResult[0].lowProductivity = Math.round(newResult[0].lowProductivity);
      }

      return newResult;
    },
    []
  );
  const dataKeys = ['highProductivity', 'lowProductivity'];

  return (
    <Section>
      <StripedTable>
        <StripedTable.Head>
          <StripedTable.Heading colSpan={2}>{selectedTag.name} Budget Productivity</StripedTable.Heading>
          <StripedTable.SubHeading colSpan={2}>{kpiHeaderTitle}</StripedTable.SubHeading>
        </StripedTable.Head>

        <StripedTable.Body>
          <StripedTable.Row>
            <StripedTable.GraphLabel style={{ width: '15%' }} isLast>Budget Productivity</StripedTable.GraphLabel>
            <StripedTable.GraphCell isLast>
              <BarGraph
                data={barData}
                keys={dataKeys}
                colors={getHighLowColours}
                axisBottom={{ format: dollarFormatting }}
                labelFormat={dollarFormatting}
                maxValue={findMaxProductivityValue(barData)}
              />
            </StripedTable.GraphCell>
          </StripedTable.Row>
        </StripedTable.Body>
      </StripedTable>
    </Section>
  );
};

export default BudgetProductivity;
