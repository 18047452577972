import { KPIGroup as KpiGroupType, KPI } from 'app/services/api';
import Loader from 'common/Loader';
import {
  tableContainerWithMarginStyles,
  headerStyles,
  getColWidth,
  firstColStyles,
  lastColStyles
} from 'app/kpi/components/tables';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { threatLevelTypes, surfaceTypes } from './index';
import { GroupKeys } from 'app/kpi/types/KPIGroupDisplay';
import MuiLightBlue from '@material-ui/core/colors/lightBlue';

type PeopleSkillMatrixType = threatLevelTypes & surfaceTypes & {
  budgetEstimator: KPI[] | null;
  kpiGroups: KpiGroupType[];
  targetBudget: KPI | null;
  controlId: number;
};

const PeopleSkillMatrix = ({
    budgetEstimator,
    kpiGroups,
    controlId,
    targetBudget,
    threatSurfaces,
    threatLevels,
}: PeopleSkillMatrixType) => {

  if (!Array.isArray(threatLevels.payload) || !budgetEstimator) {
    return (
      <div style={{ minHeight: '500px' }}>
        <Loader />
      </div>
    );
  }

  if (!targetBudget) {
    return (<div></div>);
  }

  let budgetEstimatorPeopleData: KPI[] = [];

  if (Array.isArray(budgetEstimator)) {
      budgetEstimatorPeopleData = budgetEstimator.filter(ele => ele.key === GroupKeys.BudgetEstimatorPeople);
  }

  // Starting from the first threat level
  const data = Array.isArray(threatLevels.payload) && threatLevels.payload.map((tLevel) => {
    const rowData: any = [];

    const estimatedPeopleLevel = budgetEstimatorPeopleData?.map(kpiKey => {
      let budget;
      if (kpiKey.kpiValue.length > 0) {
        budget = kpiKey.kpiValue.filter(kpi => kpi.levelId === tLevel.id);
      }
     
      if (!budget || budget.length === 0) {
        return 0;
      }

      return Number(budget[0].value);
    });

    threatSurfaces.payload.forEach(surface => {
      const targetSurfaceBudget = targetBudget.kpiValue.filter(kpi => kpi.surfaceId === surface.id)[0];
      let surfaceTargetBudget = 0;
      if (targetSurfaceBudget) {
        surfaceTargetBudget = Number(targetSurfaceBudget.value);
      }

      rowData.push(estimatedPeopleLevel[0] * surfaceTargetBudget / 100);
    });

    const sum = rowData.reduce((acc: number, curr: number) => {
      return acc + curr;
    }, 0);

    rowData.push(sum);

    // Insert threat level label to the beginning of the array
    rowData.splice(0, 0, `${tLevel.severity}. ${tLevel.name}`);
    return rowData;
  });

  const numberOfThreatSurfaces = threatSurfaces.payload.length;
  const colWidth = getColWidth(numberOfThreatSurfaces);

  return (
    <Paper elevation={3} style={{ padding: '1.5rem', marginTop: '2rem', marginBottom: '2rem' }}>
      <div style={tableContainerWithMarginStyles}>
        <Table size="small">
          <TableHead>
            <TableRow style={headerStyles}>
                <TableCell colSpan={threatSurfaces.payload.length + 2}>
                  <Typography variant="h6">Estimated People/Skills Requirement for Complete Coverage</Typography>
                </TableCell>
              </TableRow>
            <TableRow key="header">
              <TableCell key='threat-preparedness' style={firstColStyles}>Threat Level</TableCell>
              {Array.isArray(threatSurfaces.payload) && threatSurfaces.payload.map((surface, idx) => {
                return (<TableCell style={{ width: colWidth }} align="center" key={`${surface.name}-${idx}`}>{surface.name}</TableCell>);
              })
              }
              <TableCell style={lastColStyles} key='sum' align="center">Row Sum</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.isArray(data) && data.map((row) => (
              <TableRow key={row[0]}>
                {row.map((col: any, idx: number) => {
                  let style = {};
                  if (idx === row.length - 1) {
                    style = {
                      backgroundColor: MuiLightBlue[900],
                      color: 'white'
                    };
                  }
                  let displayValue = col;
                  if (typeof col === 'number') {
                    displayValue = col.toFixed(2);
                  }

                  return (
                    <TableCell key={`${row[0]}-${idx}`} style={style} component="th" scope="row" align={idx > 0 ? "center" : "left"}>
                      {displayValue}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </Paper>
  );
};

export default PeopleSkillMatrix;
