import { connect } from 'react-redux';
import React from 'react';
import {
  Switch,
  Route,
  Redirect,
  useRouteMatch,
  useHistory,
} from 'react-router-dom';
import {
  TagType,
  TagHints,
  KPIGroup as KPIGroupType,
  KPIGroupHints,
  Control,
} from 'app/services/api';
import { fetchTags, tagsState } from 'app/common/actions/tags';
import { recursivelyFind } from 'app/utils/helpers';
import { updateAppSelection, updateAppSelectionType } from 'app/common/actions';
import Loader from 'app/common/Loader';
import Container from '@material-ui/core/Container';
import NavBreadcrumbs, { getBreadcrumbs } from 'app/common/NavBreadcrumbs';
import NavKPIGroups from 'app/kpi/components/NavKPIGroups';
import { fetchKpiGroups, kpiGroupsState } from 'app/common/actions/kpi-groups';
import PATHS from 'app/utils/paths';
import { fetchControls, controlsState } from 'app/common/actions/controls';
import isEmpty from 'lodash/isEmpty';
import { addHistoryListener } from 'app/common/NavHistory';

import ElementsSelection from 'app/common/NavElementsSelection';
import ControlsSelection from 'app/common/NavControlsSelection';
import ControlDashboards from 'app/dashboard/ControlDashboards';
import TagDashboards from 'app/dashboard/TagDashboards';
import isEqual from 'lodash/isEqual';

type reduxStateType = {
  tags: tagsState;
  app: {
    tag: TagType;
    control: Control;
  };
  kpiGroups: kpiGroupsState;
  controls: controlsState;
};

type GroupDashboardType = {
  selectedTag: TagType;
  getTags: () => void;
  tags: tagsState;
  updateApp: (selection: updateAppSelectionType) => void;
  kpiGroups: kpiGroupsState;
  getKpiGroups: () => void;
  selectedControl: Control;
  getControls: (forceFetch: boolean, tagKey?: string, include?: string) => void;
  controls: controlsState;
};

const getNavKpiGroups = (
  kpiGroups: KPIGroupType[],
  selectedTag: TagType,
  selectedControl: Control,
  controlsPayload: Control[]
) => {
  let mappedGroups: KPIGroupType[] = [];

  if (
    selectedTag &&
    selectedTag.hint === TagHints.ControlContainer &&
    !isEmpty(controlsPayload)
  ) {
    mappedGroups = mappedGroups
      .concat({
        id: 12,
        key: PATHS.SUMMARY,
        name: 'Summary',
        parentId: null,
        hint: KPIGroupHints.Default,
        path: PATHS.SUMMARY.replace('/', ''),
        weight: 0,
        displayOrder: 0,
      })
      .concat(kpiGroups);
  }

  if (!selectedControl && selectedTag.hint === TagHints.ControlContainer) {
    mappedGroups.splice(0, 0, {
      id: 11,
      key: PATHS.CONTROLS,
      name: 'Controls',
      parentId: null,
      hint: KPIGroupHints.Default,
      path: PATHS.CONTROLS.replace('/', ''),
      weight: 0,
      displayOrder: 0,
    });
  }

  if (!selectedControl && selectedTag.subTags) {
    mappedGroups.splice(0, 0, {
      id: 10,
      key: PATHS.ELEMENTS,
      name: 'Elements',
      parentId: null,
      hint: KPIGroupHints.Default,
      path: PATHS.ELEMENTS.replace('/', ''),
      weight: 0,
      displayOrder: 0,
    });
  }

  return mappedGroups;
};

const getRedirectUrl = (
  selectedTag: TagType,
  controlId: string | undefined
) => {
  if (!controlId && selectedTag.subTags) {
    return PATHS.ELEMENTS;
  }

  if (!controlId && selectedTag.hint) {
    return PATHS.CONTROLS;
  }

  return PATHS.SUMMARY;
};

const GroupDashboard = ({
  selectedTag,
  getTags,
  tags,
  updateApp,
  kpiGroups,
  getKpiGroups,
  selectedControl,
  getControls,
  controls,
}: GroupDashboardType): JSX.Element => {
  const {
    url,
    params: { tagId, controlId },
  }: {
    url: string;
    params: { tagId?: string; controlId?: string };
  } = useRouteMatch();

  const history = useHistory();
  React.useEffect(() => {
    return addHistoryListener(history, 'dashboard', {
      selectedTag,
      selectedControl,
      tags,
      controls,
      updateApp,
    });
  }, [history, selectedTag, selectedControl, tags, controls, updateApp]);

  React.useEffect(() => {
    if (!tags.isLoading && !('payload' in tags)) {
      getTags();
    }

    if (
      !tags.isLoading &&
      Array.isArray(tags.payload) &&
      ((tagId && !selectedTag) ||
        (tagId && selectedTag && Number(tagId) !== selectedTag.id))
    ) {
      const existingTag = recursivelyFind('tags', Number(tagId), tags.payload);
      updateApp({ tag: existingTag });
    }

    if (!kpiGroups.isLoading && !('payload' in kpiGroups)) {
      getKpiGroups();
    }

    if (
      selectedTag &&
      (!tags.isLoading || !kpiGroups.isLoading || !controls.isLoading) &&
      !('payload' in controls)
    ) {
      const forceFetch = true;
      const tagKey = selectedTag.key;
      let include;
      if (selectedTag.hint == TagHints.ControlContainer) {
        include = 'tags,subTags';
      }
      getControls(forceFetch, tagKey, include);
    } else if (
      controlId &&
      !selectedControl &&
      !controls.isLoading &&
      Array.isArray(controls.payload)
    ) {
      const [currentControl] = (controls.payload as Control[]).filter(
        (control: Control) => control.id === Number(controlId)
      );

      updateApp({ control: currentControl });
    }
  });

  if (tags.isLoading || kpiGroups.isLoading || controls.isLoading) {
    return <Loader />;
  }

  if (!tags.payload || !selectedTag || !kpiGroups.payload) {
    return <div />;
  }

  const baseUrl = PATHS.DASHBOARD;
  const dashboards = selectedControl ? ControlDashboards : TagDashboards;

  return (
    <Container maxWidth={false}>
      <NavBreadcrumbs
        breadcrumbs={getBreadcrumbs({
          baseUrl,
          selectedTag,
          updateApp,
          tags,
          history,
          controlId,
          selectedControl,
        })}
      />

      <NavKPIGroups
        kpiGroups={getNavKpiGroups(
          kpiGroups.payload as KPIGroupType[],
          selectedTag,
          selectedControl,
          controls.payload as Control[]
        )}
      />

      <Container maxWidth={false}>
        <Switch>
          <Route
            path={`${url}${PATHS.ELEMENTS}`}
            component={ElementsSelection}
          />
          <Route
            exact
            path={`${url}${PATHS.CONTROLS}`}
            component={ControlsSelection}
          />

          <Route
            path={`${url}${PATHS.SUMMARY}`}
            component={dashboards.Summary}
          />
          <Route
            path={`${url}${PATHS.SECURITY_COVERAGE}`}
            component={dashboards.Security}
          />
          <Route
            path={`${url}${PATHS.MANAGEMENT}`}
            component={dashboards.Management}
          />
          <Route
            path={`${url}${PATHS.RESOURCES}`}
            component={dashboards.Resources}
          />
          <Route
            path={`${url}${PATHS.INTEGRATION}`}
            component={dashboards.Integration}
          />
          <Route
            path={`${url}${PATHS.INVESTMENT}`}
            component={dashboards.Investment}
          />

          <Redirect to={`${url}${getRedirectUrl(selectedTag, controlId)}`} />
        </Switch>
      </Container>
    </Container>
  );
};

const mapState = ({
  kpiGroups,
  tags,
  app: { tag, control },
  controls,
}: reduxStateType) => ({
  selectedTag: tag,
  selectedControl: control,
  tags,
  kpiGroups,
  controls,
});

const mapDispatch = {
  getTags: fetchTags,
  updateApp: updateAppSelection,
  getKpiGroups: fetchKpiGroups,
  getControls: fetchControls,
};

export default connect(
  mapState,
  mapDispatch
)(React.memo(GroupDashboard, (props, nextProps) => isEqual(props, nextProps)));
