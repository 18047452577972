import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import MuiGrey from '@material-ui/core/colors/grey';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { fetchTags, tagsState } from 'app/common/actions/tags';
import { updateAppSelection, updateAppSelectionType } from 'app/common/actions';
import { TagType } from 'app/services/api';
import Loader from 'app/common/Loader';

const featureName = 'common/tags-selection';

type TagsSelectionType = {
  getTags: () => void;
  tags: tagsState;
  updateApp: (selection: updateAppSelectionType) => void;
  app: updateAppSelectionType;
};

const TagsSelectionStyles = makeStyles(() =>
  createStyles({
    card: {
      width: '100%',
      padding: '1rem',
      textAlign: 'center',
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',

      '&:not(:last-of-type)': {
        marginBottom: '1rem',
      },
    },
    selectable: {
      cursor: 'pointer',
    },
    fullHeight: {
      height: '100%',
    },
    activeCard: {
      background: MuiGrey[300],
    },
    gridContainer: {
      padding: '1rem',
      minWidth: '998px',
    },
  })
);

const TagsSelection = ({
  getTags,
  tags,
  updateApp,
  app,
}: TagsSelectionType) => {
  const styles = TagsSelectionStyles();
  const history = useHistory();
  const { url } = useRouteMatch();

  React.useEffect(() => {
    getTags();
  }, []);

  if (tags.isLoading) {
    return <Loader />;
  }

  const tagsPayload = tags.payload as TagType[];

  if (!tagsPayload) {
    return <div />;
  }

  const handleTagSelect = (event: any, selectedTag: TagType): void => {
    event.preventDefault();
    updateApp({ tag: selectedTag, control: null });
    history.push(`${url}/${selectedTag.id}`);
  };

  return (
    <>
      <Grid container spacing={2} className={styles.gridContainer}>
        {tagsPayload.map((tag: TagType) => (
          <Grid
            item
            style={{ width: `calc(100% / ${tagsPayload.length})` }}
            key={`${featureName}-parent-tag-header-${tag.id}`}
            onClick={(event): void => handleTagSelect(event, tag)}
          >
            <Card
              className={`${styles.card} ${styles.selectable} ${styles.fullHeight}`}
            >
              <Typography variant="h6">{tag.name}</Typography>
            </Card>
          </Grid>
        ))}

        <hr style={{ margin: '1rem', width: '100%' }} />

        {tagsPayload.map((tag: TagType) => (
          <Grid
            item
            style={{ width: `calc(100% / ${tagsPayload.length})` }}
            key={`${featureName}-parent-tag-column-${tag.id}`}
          >
            {tag.subTags.map((subTag: TagType) => (
              <Card
                className={`${styles.card} ${styles.selectable} ${
                  app.tag === subTag ? styles.activeCard : ''
                }`}
                key={`${featureName}-sub-tag-${subTag.id}`}
                onClick={(event): void => handleTagSelect(event, subTag)}
              >
                <Typography variant="h6">{subTag.name}</Typography>
              </Card>
            ))}
          </Grid>
        ))}
      </Grid>
    </>
  );
};

const mapState = ({
  app,
  tags,
}: {
  app: updateAppSelectionType;
  tags: tagsState;
}) => ({
  tags,
  app,
});

const mapDispatch = {
  getTags: fetchTags,
  updateApp: updateAppSelection,
};

export default connect(mapState, mapDispatch)(TagsSelection);
