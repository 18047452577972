import { connect } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import {
  KPIGroup as KpiGroupType,
  ThreatSurface as ThreatSurfaceType,
  ThreatLevel as ThreatLevelType,
  Control,
} from 'app/services/api';
import { fetchThreatSurfaces } from 'common/actions/threat-surfaces';
import { fetchThreatLevels } from 'app/common/actions/threat-levels';

import TimeAllocation from './TimeAllocation';
import BudgetAllocation from './BudgetAllocation';
import BudgetProductivity from './BudgetProductivity';

interface threatSurfacesState {
  isLoading: boolean;
  hasError: Error;
  payload: ThreatSurfaceType[];
}

interface threatLevelState {
  isLoading: boolean;
  hasError: Error;
  payload: ThreatLevelType[];
}

type stateType = {
  kpiGroups: { payload: KpiGroupType[] };
  threatSurfaces: threatSurfacesState;
  threatLevels: threatLevelState;
  app: { control: Control };
};

interface surfaceTypes {
  getThreatSurfaces: () => void;
  threatSurfaces: threatSurfacesState;
}

export interface threatLevelTypes {
  getThreatLevels: () => void;
  threatLevels: threatLevelState;
}

export interface commonDashboardTypes extends surfaceTypes {
  controlId: number;
}

type InvestmentDashboardType = surfaceTypes &
  threatLevelTypes & {
    kpiGroups: KpiGroupType[];
    selectedControl: Control;
  };

const InvestmentDashboard = ({
  kpiGroups,
  getThreatSurfaces,
  getThreatLevels,
  threatSurfaces,
  threatLevels,
  selectedControl,
}: InvestmentDashboardType): JSX.Element => {
  const { url } = useRouteMatch();
  const controlId = selectedControl.id;
  const groupPath = url.split('/').pop();
  const kpiGroup = kpiGroups.find(
    (group: KpiGroupType) => group.path === groupPath
  );

  React.useEffect(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }, []);

  if (!kpiGroup) {
    return <div>Invalid KPI Group</div>;
  }

  return (
    <div>
      <BudgetProductivity
        kpiGroup={kpiGroup}
        kpiGroups={kpiGroups}
        getThreatSurfaces={getThreatSurfaces}
        getThreatLevels={getThreatLevels}
        threatSurfaces={threatSurfaces}
        threatLevels={threatLevels}
        controlId={controlId}
      />
      <BudgetAllocation
        kpiGroup={kpiGroup}
        getThreatSurfaces={getThreatSurfaces}
        threatSurfaces={threatSurfaces}
        controlId={controlId}
      />
      <TimeAllocation kpiGroup={kpiGroup} controlId={controlId} />
    </div>
  );
};

const mapState = ({
  app: { control },
  kpiGroups: { payload },
  threatSurfaces,
  threatLevels,
}: stateType) => ({
  kpiGroups: payload,
  threatLevels,
  threatSurfaces,
  selectedControl: control,
});

const mapDispatch = {
  getThreatLevels: fetchThreatLevels,
  getThreatSurfaces: fetchThreatSurfaces,
};

export default connect(mapState, mapDispatch)(InvestmentDashboard);
