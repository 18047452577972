import { TagType } from 'app/services/api';
import { getBudgetAverage } from 'app/utils/graph-math';
import BarGraph, { green, orange, dollarFormatting, findMaxProductivityValue, getHighLowColours } from 'app/common/BarGraph';
import Section from 'app/common/Section';
import StripedTable from 'app/common/StripedTable';

import { InvestmentDataType } from '.';

const featureName = 'dashboard/tag-dashboards/investment/budget-productivity-by-control';

type BudgetProductivityByControlType = {
  selectedTag: TagType;
  investmentData: InvestmentDataType[];
};

const BudgetProductivityByControl = ({
  selectedTag,
  investmentData,
}: BudgetProductivityByControlType) => {
  const barData = investmentData.map(controlData => {
    const budgetAverage = getBudgetAverage(controlData.totalBudget);
    const highProd = Math.round(budgetAverage * (controlData.secOpsProductivity / 100));
    const lowProd = Math.round(budgetAverage - highProd);

    return {
      id: controlData.control.id,
      label: controlData.control.name,
      highProductivity: highProd,
      highProductivityColour: green,
      lowProductivity: lowProd,
      lowProductivityColour: orange,
    }
  });
  const dataKeys = ['highProductivity', 'lowProductivity'];
  const controls = investmentData.map(data => data.control);
  let bargraphHeight = controls.length ? `${(controls.length + 1) * 45}px` : '225px';

  if (Array.isArray(barData) && barData.length > 0) {
    bargraphHeight = `${(barData.length + 1) * 45}px`;
  }

  return (
    <Section>
      <StripedTable>
        <StripedTable.Head>
          <StripedTable.Heading colSpan={2}>{selectedTag.name} Budget Productivity by Control</StripedTable.Heading>
          <StripedTable.SubHeading colSpan={2}>Control</StripedTable.SubHeading>
        </StripedTable.Head>

        <StripedTable.Body>
          {barData.map((rowData, index) => {
            const isLast = index === barData.length - 1;

            return (
              <StripedTable.Row key={`${featureName}/tbody/row/${rowData.id}`}>
                <StripedTable.GraphLabel style={{ width: '15%' }} isLast={isLast}>{rowData.label}</StripedTable.GraphLabel>
                <StripedTable.GraphCell isLast={isLast}>
                  <BarGraph
                    data={[rowData]}
                    keys={dataKeys}
                    colors={getHighLowColours}
                    axisBottom={isLast ? { format: dollarFormatting } : null}
                    labelFormat={dollarFormatting}
                    maxValue={findMaxProductivityValue(barData)}
                  />
                </StripedTable.GraphCell>
              </StripedTable.Row>
            );
          })}
        </StripedTable.Body>
      </StripedTable>
    </Section>
  );
};

export default BudgetProductivityByControl;
