import {
  Control,
  KPI,
  KPIGroup as KpiGroupType,
  KPIValue,
  getKPIValues,
  getKPIsAndValues,
} from 'services/api';

export const isPromise = (input: any): boolean =>
  Boolean(input) &&
  (typeof input === 'object' || typeof input === 'function') &&
  Boolean(input.then) &&
  typeof input.then === 'function';

export const arrayHasValues = (input: any[]): boolean =>
  input && Array.isArray(input) && input.length ? true : false;

export const filterKpiValues = (
  kpiValues: KPIValue[],
  controlId: number,
  groupId?: number
): KPIValue[] => {
  let values = kpiValues.filter(
    (value: KPIValue) =>
      value.controlId == controlId &&
      (groupId === undefined || value.kpi.groupId === groupId)
  );
  if (values.length === 0) {
  }
  return values;
};

export const extractKpisFromValues = (kpiValues: KPIValue[]): KPI[] => {
  const kpis: KPI[] = [];
  kpiValues.forEach((value: KPIValue) => {
    if (value.kpi) {
      const found = kpis.find((kpi: KPI) => kpi.id === value.kpi?.id);
      if (!found) {
        const kpi: KPI = Object.assign({}, value.kpi);
        if (value.kpi.valueType !== undefined) {
          kpi.valueType = value.kpi.valueType;
        }
        kpi.kpiValue = [];
        kpis.push(kpi);
      }
    }
  });
  return kpis.sort((a: KPI, b: KPI) => a?.id - b?.id);
};

export const invertKpiValues = (kpiValues: KPIValue[]): KPI[] => {
  const kpis: KPI[] = [];
  kpiValues.forEach((value: KPIValue) => {
    if (value.kpi) {
      const found = kpis.find((kpi: KPI) => kpi.id === value.kpi?.id);
      if (found) {
        if (value.id) {
          found.kpiValue.push(value);
        }
      } else {
        const kpi: KPI = Object.assign({}, value.kpi);
        if (value.kpi.valueType !== undefined) {
          kpi.valueType = value.kpi.valueType;
        }
        kpi.kpiValue = [];
        if (value.id) {
          kpi.kpiValue.push(value);
        }
        kpis.push(kpi);
      }
    }
  });
  return kpis.sort((a: KPI, b: KPI) => a?.id - b?.id);
};

export const findMissingKpis = (needle: KPI[], haystack: KPI[]): KPI[] => {
  const missing: KPI[] = [];
  if (needle.length < haystack.length) {
    haystack.forEach((straw: KPI) => {
      if (needle.findIndex((kpi: KPI) => kpi.id === straw.id) === -1) {
        missing.push(straw);
      }
    });
  }
  return missing;
};

export const getKPIsAndValuesForGroup = (
  controls: Control[],
  groupKey: string
): Promise<KPI[][]> => {
  return getKPIValues({
    groupKey,
    include: 'kpis,kpis.valueType,nullvalues,unique',
  }).then((kpiValues: KPIValue[]) => {
    let kpis: KPI[] = extractKpisFromValues(kpiValues);

    let result: KPI[][] = controls.map((control: Control) => {
      let controlKpis = invertKpiValues(
        kpiValues.filter((value: KPIValue) => value.controlId == control.id)
      );
      let missing = findMissingKpis(controlKpis, kpis);
      if (missing.length > 0) {
        return controlKpis
          .concat(missing)
          .sort((a: KPI, b: KPI) => a?.id - b?.id);
      }
      return controlKpis;
    });
    return result;
  });
};

export const getKPIsAndValuesForSubgroups = (
  controls: Control[],
  kpiGroups: KpiGroupType[],
  groupName: string
): Promise<KPI[][][]> => {
  const kpiGroup = kpiGroups.find((group: KpiGroupType) =>
    group.key.includes(groupName)
  );
  const subGroups = kpiGroup?.subGroups;
  if (!kpiGroup || !subGroups) {
    throw new Error(`Missing subgroups for KPI group ${groupName}`);
  }

  return getKPIValues({
    groupKey: kpiGroup.key,
    include: 'kpis,kpis.valueType,kpis.subGroups,nullvalues,unique',
  }).then((kpiValues: KPIValue[]) => {
    let kpis: KPI[] = extractKpisFromValues(kpiValues);

    let result: KPI[][][] = controls.map((control: Control) =>
      subGroups.map((subGroup: KpiGroupType) => {
        let values = kpiValues.filter(
          (value: KPIValue) =>
            value.id === undefined ||
            (value.kpi.groupId === subGroup.id && value.controlId == control.id)
        );
        let groupKpis = invertKpiValues(values);
        let missing = findMissingKpis(groupKpis, kpis);
        if (missing.length > 0) {
          return groupKpis
            .concat(missing)
            .sort((a: KPI, b: KPI) => a?.id - b?.id);
        }
        return groupKpis;
      })
    );
    return result;
  });
};

export const kpiGroupPromiseCreator = (
  groupName: string,
  kpiGroups: KpiGroupType[],
  controlId?: number | undefined
) => {
  const kpiGroup = kpiGroups.find((group: KpiGroupType) =>
    group.key.includes(groupName)
  );

  if (!kpiGroup) {
    return [];
  }

  return Promise.all(
    (kpiGroup.subGroups || []).map((subGroup: KpiGroupType) =>
      getKPIsAndValues({ groupId: subGroup.id, controlId })
    )
  );
};

export const findSubGroupByKey = (kpiGroup: KpiGroupType, key: string) =>
  (kpiGroup?.subGroups || []).find((group: KpiGroupType) =>
    group.key.includes(key)
  );

export const recursivelyFind = (
  itemName: string,
  id: number,
  inputArray: any
) => {
  for (const i in inputArray) {
    const item = inputArray[i];

    if (item.id === id) {
      return item;
    }

    const subItemName = `sub${itemName
      .charAt(0)
      .toUpperCase()}${itemName.substring(1)}`;
    const subItemArray = item[subItemName];
    if (subItemArray) {
      const parent: any = recursivelyFind(itemName, id, subItemArray);

      if (parent) {
        return parent;
      }
    }
  }
};

export const flattenNestedArrayByName = (itemName: string, inputArray: any) => {
  let foundItems: any = [];

  if (!inputArray || (Array.isArray(inputArray) && !inputArray.length)) {
    return foundItems;
  }

  for (const i in inputArray) {
    const item = inputArray[i];

    if (item) {
      foundItems = foundItems.concat(item);

      const subItemName = `sub${itemName
        .charAt(0)
        .toUpperCase()}${itemName.substring(1)}`;
      const subItemArray = item[subItemName];

      if (subItemArray) {
        foundItems = foundItems.concat(
          flattenNestedArrayByName(itemName, subItemArray)
        );
      }
    }
  }

  return foundItems;
};
