import BarGraph, { green, orange, dollarFormatting, getHighLowColours, findMaxProductivityValue } from 'app/common/BarGraph';
import { TagType, ThreatSurface } from 'app/services/api';
import { threatSurfaceState } from 'app/common/actions/threat-surfaces';
import { getSurfaceBudget } from 'app/utils/graph-math';
import Section from 'app/common/Section';
import StripedTable from 'app/common/StripedTable';

import { InvestmentDataType } from '.';

const featureName = 'dashboard/tag-dashboards/investment/budget-productivity-by-threat-surface';

type BudgetProductivityBySurfaceType = {
  investmentData: InvestmentDataType[];
  selectedTag: TagType;
  threatSurfaces: threatSurfaceState;
};

const BudgetProductivityBySurface = ({
  investmentData,
  selectedTag,
  threatSurfaces,
}: BudgetProductivityBySurfaceType): JSX.Element => {
  const dataKeys = ['highProductivity', 'lowProductivity'];
  const tSurfacePayload = threatSurfaces.payload as ThreatSurface[];

  if (!tSurfacePayload) {
    return <div />;
  }

  const barData = tSurfacePayload.map((tSurface) => {
    const { highProductivity, lowProductivity } = investmentData.reduce(
      (result, next, index) => {
        const newResult = { ...result };
        const nextSurfaceBudget = getSurfaceBudget(
          next.totalBudget,
          next.currentBudget?.kpiValue.find(
            (kpiValue: any) => kpiValue.surfaceId === tSurface.id
          )
        );

        const nextHighProd = nextSurfaceBudget * (next.secOpsProductivity / 100);

        const nextLowProd = nextSurfaceBudget - nextHighProd;

        newResult.highProductivity = newResult.highProductivity + nextHighProd;
        newResult.lowProductivity = newResult.lowProductivity + nextLowProd;

        if (index === investmentData.length - 1) {
          newResult.highProductivity = Math.round(newResult.highProductivity);
          newResult.lowProductivity = Math.round(newResult.lowProductivity);
        }

        return newResult;
      },
      { highProductivity: 0, lowProductivity: 0 },
    );

    return {
      id: tSurface.id,
      label: tSurface.name,
      highProductivity,
      highProductivityColour: green,
      lowProductivity,
      lowProductivityColour: orange,
    };
  });
  let bargraphHeight = tSurfacePayload.length ? `${(tSurfacePayload.length + 1) * 45}px` : '225px';

  if (Array.isArray(barData) && barData.length > 0) {
    bargraphHeight = `${(barData.length + 1) * 45}px`;
  }

  return (
    <Section>
      <StripedTable>
        <StripedTable.Head>
          <StripedTable.Heading colSpan={2}>{selectedTag.name} Budget Productivity by Threat Surface</StripedTable.Heading>
          <StripedTable.SubHeading colSpan={2}>Threat Surface</StripedTable.SubHeading>
        </StripedTable.Head>

        <StripedTable.Body>
          {barData.map((rowData, index) => {
            const isLast = index === barData.length - 1;

            return (
              <StripedTable.Row key={`${featureName}/${rowData.id}`}>
                <StripedTable.GraphLabel style={{ width: '15%' }} isLast={isLast}>{rowData.label}</StripedTable.GraphLabel>
                <StripedTable.GraphCell isLast={isLast}>
                  <BarGraph
                    data={[rowData]}
                    keys={dataKeys}
                    colors={getHighLowColours}
                    axisBottom={isLast ? { format: dollarFormatting } : null}
                    maxValue={findMaxProductivityValue(barData)}
                    labelFormat={dollarFormatting}
                  />
                </StripedTable.GraphCell>
              </StripedTable.Row>
            );
          })}
        </StripedTable.Body>
      </StripedTable>
    </Section>
  );
};

export default BudgetProductivityBySurface;
