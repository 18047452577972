import React from 'react';
import { KPIGroup, KPI, TagType } from 'app/services/api';
import { kpiHeaderTitle } from 'app/kpi/components/tables';
import BarGraph, { getBarColorsForValue } from 'app/common/BarGraph';
import Section from 'app/common/Section';
import StripedTable from 'app/common/StripedTable';
import { getKpiGroupProductivity } from 'app/utils/graph-math';

type KPIGroupSummaryType = {
  title: string;
  kpiGroup: KPIGroup;
  controlKpiValues: KPI[][][];
  selectedTag: TagType;
};

const KPIGroupSummary = ({
  title,
  kpiGroup,
  controlKpiValues,
  selectedTag,
}: KPIGroupSummaryType): JSX.Element => {
  const barData = [
    {
      id: kpiGroup.name,
      label: kpiGroup.name,
      value: controlKpiValues.reduce((productivity, nextKpiValues, index) => {
        const nextProductivity =
          productivity + getKpiGroupProductivity(nextKpiValues, kpiGroup);

        if (index === controlKpiValues.length - 1) {
          return Math.round(nextProductivity / controlKpiValues.length);
        }

        return nextProductivity;
      }, 0),
    },
  ];

  let barColors: any;
  if (Array.isArray(barData) && barData.length > 0) {
    barColors = getBarColorsForValue(barData[0].value);
  }

  return (
    <Section>
      <StripedTable>
        <StripedTable.Head>
          <StripedTable.Heading colSpan={2}>
            {selectedTag.name} {title}
          </StripedTable.Heading>
          <StripedTable.SubHeading colSpan={2}>
            {kpiHeaderTitle}
          </StripedTable.SubHeading>
        </StripedTable.Head>

        <StripedTable.Body>
          <StripedTable.Row>
            <StripedTable.GraphLabel isLast>
              {kpiGroup.name}
            </StripedTable.GraphLabel>
            <StripedTable.GraphCell isLast>
              <BarGraph
                data={barData}
                colors={barColors.background}
                labelTextColor={barColors.textColor}
              />
            </StripedTable.GraphCell>
          </StripedTable.Row>
        </StripedTable.Body>
      </StripedTable>
    </Section>
  );
};

export default KPIGroupSummary;
